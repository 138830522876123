import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Checkbox, Stack } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { NumberControlInput } from '../../../components/forms/NumberControlInput';
import { SelectInput } from '../../../components/forms/SelectInput';
import { TextareaInput } from '../../../components/forms/TextareaInput';
import { TextInput } from '../../../components/forms/TextInput';
import { appColors, PlanGoalEnum } from '../../../config/constants';
import { FeatureFlags, useFeatureFlag } from '../../../store/featureFlag';
import { useAppSelector } from '../../../store/hooks';
import { selectEditScenario } from '../../../store/scenarioSlice';
const PLAN_DURATION_KEY = 'planDurationYrs';
const PLAN_NEED_KEY = 'planningNeed';
const PLAN_START_KEY = 'startYear';
const PLAN_NAME_KEY = 'name';
const PLAN_DESCRIPTION_KEY = 'description';
export const SCENARIO_NAME_ERROR_MSG = 'A scenario already exists with that name. Please enter a different name.';
export const ScenarioDetailsPage = ({ register, watch, setHasErrors, setValue, stepOverride, scenarios, scenarioCurrent, setFirstYear, setAmountOfYears, applyEscalation, setApplyEscalation, }) => {
    const currentYear = new Date().getUTCFullYear();
    const maxYears = 30;
    const edit = useAppSelector(selectEditScenario);
    const escalationFactorFlag = useFeatureFlag(FeatureFlags.ESCALATION_FACTOR);
    const planYearsRegister = register(PLAN_DURATION_KEY, {
        required: true,
        max: 30,
        onBlur: (e) => {
            if (e.target.value > 30) {
                setValue(PLAN_DURATION_KEY, maxYears);
            }
        },
    });
    const [nameValidation, setNameValidation] = useState();
    const validateScenarioName = (event) => {
        const value = event.target.value;
        let scenarioFound;
        if (edit && scenarioCurrent) {
            scenarioFound = scenarios
                .filter((scenario) => scenario.name !== scenarioCurrent.name)
                .find((scenario) => scenario.name === value);
        }
        else {
            scenarioFound = scenarios.find((scenario) => scenario.name === value);
        }
        if (scenarioFound) {
            setNameValidation(SCENARIO_NAME_ERROR_MSG);
        }
        else {
            setNameValidation(undefined);
        }
    };
    const planGoalRegister = register(PLAN_NEED_KEY, { required: true });
    const planStartRegister = register(PLAN_START_KEY, { required: true });
    const planNameRegister = register(PLAN_NAME_KEY, {
        onChange: validateScenarioName,
    });
    const planDescriptionRegister = register(PLAN_DESCRIPTION_KEY);
    const planYears = watch(planYearsRegister.name);
    const planGoal = watch(planGoalRegister.name);
    const planStart = watch(planStartRegister.name);
    const planName = watch(planNameRegister.name);
    const planDescription = watch(planDescriptionRegister.name);
    useEffect(() => {
        if (!planYears || !planGoal || !planStart || nameValidation) {
            setHasErrors(true);
        }
        else {
            setHasErrors(false);
        }
    });
    useEffect(() => {
        const planGoal = watch(planGoalRegister.name);
        if (planGoal === PlanGoalEnum.NEEDS) {
            // TODO: remove harded coded page value?
            stepOverride.setNextStep(3);
        }
        else if (planGoal === PlanGoalEnum.BUDGET) {
            stepOverride.setNextStep(undefined);
        }
    });
    useEffect(() => {
        if (scenarioCurrent && edit) {
            setValue(PLAN_NAME_KEY, planName ? planName : scenarioCurrent.name);
            setValue(PLAN_DESCRIPTION_KEY, planDescription ? planDescription : scenarioCurrent.description);
            setValue(PLAN_DURATION_KEY, planYears ? planYears : scenarioCurrent.planDurationYrs);
            setValue(PLAN_START_KEY, planStart ? planStart : scenarioCurrent.startYear);
            setValue(PLAN_NEED_KEY, planGoal ? planGoal : scenarioCurrent.planningNeed);
        }
    }, [scenarioCurrent]);
    const generateYearOptions = (currentYear, yearsToShow) => {
        const options = [];
        for (let i = 0; i < yearsToShow; i++) {
            const year = currentYear + i;
            options.push(_jsx("option", Object.assign({ value: year.toString() }, { children: year }), year));
        }
        return options;
    };
    useEffect(() => {
        setAmountOfYears(+planYears);
        setFirstYear(+planStart);
    }, [planYears, planStart]);
    return (_jsxs(Stack, Object.assign({ gap: 3 }, { children: [_jsx(TextInput, { label: 'Name the scenario', placeholder: 'Type here', register: planNameRegister, inputType: 'text', errorMsg: nameValidation }), useFeatureFlag(FeatureFlags.SCENARIO_DESCRIPTION) && (_jsx(TextareaInput, { label: 'Description', register: planDescriptionRegister, required: false, placeholder: 'Type here' })), _jsx(NumberControlInput, { label: 'How long are you planning for?', placeholder: 'Enter number of years', register: planYearsRegister, required: true, disabled: edit }), _jsx(SelectInput, Object.assign({ label: 'First year of planning?', register: planStartRegister, defaultValue: undefined, required: true, disabled: edit }, { children: _jsx(_Fragment, { children: generateYearOptions(currentYear, maxYears) }) })), _jsx(SelectInput, Object.assign({ label: 'In my planning...', register: planGoalRegister, defaultValue: undefined, required: true }, { children: _jsxs(_Fragment, { children: [_jsx("option", Object.assign({ value: PlanGoalEnum.NEEDS }, { children: "I know my needs, I have to determine my budget" })), _jsx("option", Object.assign({ value: PlanGoalEnum.BUDGET }, { children: "I know my budget, I have to determine my needs" }))] }) })), escalationFactorFlag && (_jsx(Checkbox, Object.assign({ isChecked: applyEscalation, onChange: () => setApplyEscalation(!applyEscalation), iconColor: appColors.SEC_ORANGE, fontSize: 14, color: appColors.PRIM_BLUE, fontWeight: 300 }, { children: "Apply escalation factor" })))] })));
};
