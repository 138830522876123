import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Grid, GridItem } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { addProjectToScenario, removeProjectFromScenario, } from '../../../services/api/scenarioAPI';
import { useAppDispatch } from '../../../store/hooks';
import { fetchScenario } from '../../../store/scenarioSlice';
import { ProjectTable } from './ProjectTable';
import { ScenariosArea } from './ScenariosArea';
import { WidgetsContainer } from './WidgetsContainer';
export const ScenarioPage = ({ scenarioCurrent, projects, fundingTypes, escalationFactor, }) => {
    const dispatch = useAppDispatch();
    const [clearScenProjData, setClearScenProjData] = useState(false);
    const [updateScenProjData, setUpdateScenProjData] = useState(false);
    const [movedProjectIdentity, setMovedProjectIdentity] = useState();
    const [year, setYear] = useState();
    const [availableProjects, setAvailableProjects] = useState([]);
    useEffect(() => {
        setClearScenProjData(true);
    }, [scenarioCurrent]);
    useEffect(() => {
        if (clearScenProjData) {
            setClearScenProjData(false);
        }
    }, [clearScenProjData]);
    useEffect(() => {
        const projectsNotInScenario = projects.filter((p) => {
            var _a;
            return !((_a = scenarioCurrent.assignedProjects) !== null && _a !== void 0 ? _a : []).some((projectInScenario) => { var _a; return ((_a = projectInScenario.project) === null || _a === void 0 ? void 0 : _a.projectIdentity) === p.identity; });
        });
        setAvailableProjects(projectsNotInScenario);
    }, [projects, scenarioCurrent.assignedProjects]);
    useEffect(() => {
        if (updateScenProjData) {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            movedProjectIdentity && setLanesData(movedProjectIdentity, year);
            setUpdateScenProjData(false);
        }
    }, [updateScenProjData, movedProjectIdentity, year]);
    const setLanesData = (projectIdentity, year) => {
        if (year) {
            addProjectToScenario(scenarioCurrent.id, projectIdentity, {
                year: year,
            }).then((scenario) => {
                dispatch(fetchScenario(scenario.id));
            });
        }
        else {
            removeProjectFromScenario(scenarioCurrent.id, projectIdentity).then(() => dispatch(fetchScenario(scenarioCurrent.id)));
        }
    };
    const onProjectDropped = (projectIdentity, year) => {
        setMovedProjectIdentity(projectIdentity);
        setYear(year);
        setUpdateScenProjData(true);
    };
    return (_jsx(_Fragment, { children: _jsxs(Grid, Object.assign({ templateColumns: 'repeat(5, 1fr)', templateRows: 'repeat(2, 38vh)', gap: 4 }, { children: [_jsx(GridItem, Object.assign({ colSpan: 5, rowSpan: 1 }, { children: _jsx(Box
                    // try to add in layerStyle={layerStyles.widgetsPageContainer} later
                    , Object.assign({ 
                        // try to add in layerStyle={layerStyles.widgetsPageContainer} later
                        borderRadius: '2px', border: '1px solid #C0C2C1;', h: '100%', display: 'block', p: '10px' }, { children: _jsx(WidgetsContainer, { scenarioCurrent: scenarioCurrent, fundingTypes: fundingTypes, escalationFactor: escalationFactor }) })) })), _jsx(GridItem, Object.assign({ colSpan: 1, rowSpan: 1 }, { children: _jsx(Box, Object.assign({ borderRadius: '2px', border: '1px solid #C0C2C1;', h: '100%', display: 'block', p: '10px' }, { children: _jsx(ProjectTable, { projects: availableProjects, onProjectDropped: (projectIdentity, year) => {
                                onProjectDropped(projectIdentity, year);
                            } }) })) })), _jsx(GridItem, Object.assign({ colSpan: 4, rowSpan: 1 }, { children: _jsx(Box, Object.assign({ borderRadius: '2px', border: '1px solid #C0C2C1;', h: '100%', display: 'block', p: '10px' }, { children: _jsx(ScenariosArea, { scenarioCurrent: scenarioCurrent, onProjectDropped: (projectIdentity, year) => {
                                onProjectDropped(projectIdentity, year);
                            }, fundingTypes: fundingTypes, escalationFactor: escalationFactor }) })) }))] })) }));
};
