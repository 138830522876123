export var ProjectFieldEnum;
(function (ProjectFieldEnum) {
    ProjectFieldEnum["projType"] = "projType";
    ProjectFieldEnum["fundingType"] = "fundingTypeIdentity";
    ProjectFieldEnum["facility"] = "facility";
})(ProjectFieldEnum || (ProjectFieldEnum = {}));
export var Apps;
(function (Apps) {
    Apps["FCA"] = "FCA";
    Apps["REPORTS"] = "Reports";
    Apps["PLANNER"] = "Planner";
    Apps["PIQ"] = "PIQ";
})(Apps || (Apps = {}));
