import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Stack, Text } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useController } from 'react-hook-form';
import { CurrencyInput } from '../../../components/forms/CurrencyInput';
import { slgqColorSchemes } from '../../../config/theme';
import { parseCurrencyAsFloat } from '../../../services/api/scenarioAPI';
import { useAppSelector } from '../../../store/hooks';
import { selectEditScenario, selectSelectedScenario, } from '../../../store/scenarioSlice';
const PLAN_FUNDING_TYPE_BUDGETS_KEY = 'fundingTypeBudgets';
export const FundingTypeInputs = ({ watch, setHasErrors, control, fundingTypes, year, yearIndex, amountOfYears, }) => {
    var _a, _b;
    const scenarioCurrent = useAppSelector(selectSelectedScenario);
    const edit = useAppSelector(selectEditScenario);
    const [budgetTotal, setBudgetTotal] = useState(0);
    const [budgetTotalForYears, setBudgetTotalForYears] = useState(0);
    const [budgetTotalForFundingTypes, setBudgetTotalForFundingTypes] = useState([]);
    useEffect(() => {
        let budgetTotal = 0;
        const parseBudgetInputs = fundingTypes.map((fundingType, index) => {
            const budget = watch(`${PLAN_FUNDING_TYPE_BUDGETS_KEY}.${year && yearIndex ? yearIndex * fundingTypes.length + index : index}.budget`);
            if (budget) {
                budgetTotal += parseCurrencyAsFloat(budget.toString());
                return 'filled';
            }
            else {
                return 'empty';
            }
        });
        setBudgetTotal(budgetTotal);
        !year && setHasErrors(parseBudgetInputs.includes('empty'));
        if (year && amountOfYears) {
            let budgetTotalForYears = 0;
            for (let i = 0; i < amountOfYears * fundingTypes.length; i++) {
                const budget = watch(`${PLAN_FUNDING_TYPE_BUDGETS_KEY}.${i}.budget`);
                if (budget) {
                    budgetTotalForYears += parseCurrencyAsFloat(budget.toString());
                    setBudgetTotalForYears(budgetTotalForYears);
                }
            }
            const budgetTotalForFundingTypesTemp = [];
            if (!edit) {
                fundingTypes.forEach((fundingType, index) => {
                    budgetTotalForFundingTypesTemp.push(0);
                    let budgetTotalForFundingTypetemp = 0;
                    for (let i = 0; i < amountOfYears * fundingTypes.length; i++) {
                        if (fundingType.identity ===
                            watch(`${PLAN_FUNDING_TYPE_BUDGETS_KEY}.${i}.fundingTypeIdentity`)) {
                            const budget = watch(`${PLAN_FUNDING_TYPE_BUDGETS_KEY}.${i}.budget`);
                            if (budget) {
                                budgetTotalForFundingTypetemp += parseCurrencyAsFloat(budget.toString());
                                budgetTotalForFundingTypesTemp[index] =
                                    budgetTotalForFundingTypetemp;
                                setBudgetTotalForFundingTypes(budgetTotalForFundingTypesTemp);
                            }
                        }
                    }
                });
            }
            else {
                fundingTypes.forEach((fundingType, index) => {
                    var _a;
                    budgetTotalForFundingTypesTemp.push(0);
                    let budgetTotalForFundingTypetemp = 0;
                    (_a = scenarioCurrent === null || scenarioCurrent === void 0 ? void 0 : scenarioCurrent.fundingTypeBudgets) === null || _a === void 0 ? void 0 : _a.forEach((budget) => {
                        if (budget.fundingTypeIdentity === fundingType.identity &&
                            budget.budget) {
                            budgetTotalForFundingTypetemp += parseCurrencyAsFloat(budget.budget.toString());
                            budgetTotalForFundingTypesTemp[index] =
                                budgetTotalForFundingTypetemp;
                            setBudgetTotalForFundingTypes(budgetTotalForFundingTypesTemp);
                        }
                    });
                });
            }
        }
        //without the reducer the useEffect is not working in a smart way in order to detect changes in the watch array
        //we are specifically reacting to changes in any of the budgets inputs
    }, [
        (_b = (_a = watch(`${PLAN_FUNDING_TYPE_BUDGETS_KEY}`)) === null || _a === void 0 ? void 0 : _a.reduce((a, b) => (a += String(b.budget)), '')) !== null && _b !== void 0 ? _b : [],
    ]);
    const formatBudgetTotal = (total) => {
        return total.toLocaleString('en-US');
    };
    const createInputs = () => fundingTypes.map((fundingType, index) => {
        var _a, _b;
        // set fundingTypeName in form controller, should never change
        let fundingTypeBudget;
        if (edit && scenarioCurrent && scenarioCurrent.fundingTypeBudgets) {
            if (year) {
                fundingTypeBudget = scenarioCurrent.fundingTypeBudgets.find((element) => element.fundingTypeIdentity === fundingType.identity &&
                    element.year === year);
            }
            else {
                fundingTypeBudget = scenarioCurrent.fundingTypeBudgets.find((element) => element.fundingTypeIdentity === fundingType.identity);
            }
        }
        useController({
            name: `${PLAN_FUNDING_TYPE_BUDGETS_KEY}.${year && yearIndex ? yearIndex * fundingTypes.length + index : index}.fundingTypeIdentity`,
            control,
            rules: { required: true },
            defaultValue: fundingType.identity,
        });
        useController({
            name: `${PLAN_FUNDING_TYPE_BUDGETS_KEY}.${year && yearIndex ? yearIndex * fundingTypes.length + index : index}.id`,
            control,
            rules: { required: true },
            defaultValue: (_a = fundingTypeBudget === null || fundingTypeBudget === void 0 ? void 0 : fundingTypeBudget.id) !== null && _a !== void 0 ? _a : '',
        });
        if (year) {
            useController({
                name: `${PLAN_FUNDING_TYPE_BUDGETS_KEY}.${year && yearIndex ? yearIndex * fundingTypes.length + index : index}.year`,
                control,
                rules: { required: true },
                defaultValue: year,
            });
        }
        return (_jsx(CurrencyInput, { label: fundingType.name, controller: useController({
                name: `${PLAN_FUNDING_TYPE_BUDGETS_KEY}.${year && yearIndex
                    ? yearIndex * fundingTypes.length + index
                    : index}.budget`,
                control,
                rules: { required: true },
                defaultValue: year
                    ? (fundingTypeBudget === null || fundingTypeBudget === void 0 ? void 0 : fundingTypeBudget.year) === year
                        ? fundingTypeBudget === null || fundingTypeBudget === void 0 ? void 0 : fundingTypeBudget.budget
                        : undefined
                    : (_b = fundingTypeBudget === null || fundingTypeBudget === void 0 ? void 0 : fundingTypeBudget.budget) !== null && _b !== void 0 ? _b : undefined,
            }), placeholder: 'Type here...', required: year ? false : true, isBudgetPerYear: year ? true : false, budgetTotalForFundingType: budgetTotalForFundingTypes[index] }, `${PLAN_FUNDING_TYPE_BUDGETS_KEY}.${year && yearIndex ? yearIndex * fundingTypes.length + index : index}`));
    });
    return (_jsxs(Stack, Object.assign({ w: '100%', gap: 3 }, { children: [createInputs(), _jsxs(Text, Object.assign({ colorScheme: slgqColorSchemes.primBlue }, { children: ["My budget ", year && `in ${year}`, " is", ' ', _jsx("b", { children: formatBudgetTotal(budgetTotal) }), " USD"] })), year && (_jsxs(Text, Object.assign({ colorScheme: slgqColorSchemes.primBlue }, { children: ["My total budget is", ' ', _jsx("b", { children: edit && (scenarioCurrent === null || scenarioCurrent === void 0 ? void 0 : scenarioCurrent.totalBudget)
                            ? formatBudgetTotal(scenarioCurrent.totalBudget)
                            : formatBudgetTotal(budgetTotalForYears) }), ' ', "USD"] })))] })));
};
