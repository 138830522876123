export var SystemRole;
(function (SystemRole) {
    SystemRole["SUPER_ADMIN"] = "SuperAdministrator";
    SystemRole["ORG_APPROVER"] = "OrganizationApprover";
    SystemRole["USER"] = "User";
})(SystemRole || (SystemRole = {}));
export var OrganizationRole;
(function (OrganizationRole) {
    OrganizationRole["LOCAL_ADMIN"] = "LocalAdministrator";
    OrganizationRole["CLIENT_CONCIERGE"] = "ClientConcierge";
    OrganizationRole["MEMBER"] = "Member";
    OrganizationRole["VISITOR"] = "Visitor";
})(OrganizationRole || (OrganizationRole = {}));
export var ScenarioRole;
(function (ScenarioRole) {
    ScenarioRole["MEMBER"] = "Member";
    ScenarioRole["VISITOR"] = "Visitor";
})(ScenarioRole || (ScenarioRole = {}));
