var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useToast } from '@chakra-ui/react';
import { useDependencies } from '@frontend/domain/contexts/Dependencies/DependenciesContext';
import { usePlannerSettingsContext } from '@frontend/domain/contexts/Settings/PlannerSettingsContext';
import { useProjectsService } from '@frontend/domain/services/ProjectService';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { sortByField } from '../../utils/sortingAndFiltering';
import { ProjectsContext } from './ProjectsContext';
import { convertIProjectDetailsToIProjectSummary, convertProjectCreateFormToIConfigureProject, } from './ProjectsConversions';
export function ProjectsProvider({ children, org }) {
    const toast = useToast();
    const location = useLocation();
    const { platformApi } = useDependencies();
    const { project } = useProjectsService({ platformApi });
    const { orgPrioritizationAlgorithms } = usePlannerSettingsContext();
    const [projectList, setProjectList] = useState([]);
    const [newProjectAdded, setNewProjectAdded] = useState(false);
    useEffect(() => {
        if (org) {
            fetchProjects(org.identity);
        }
    }, [orgPrioritizationAlgorithms]);
    useEffect(() => {
        // needs to be set to false so the table sort reverts back to the default when changing pages
        setNewProjectAdded(false);
    }, [location]);
    const fetchProjects = (orgId) => __awaiter(this, void 0, void 0, function* () {
        project.fetchList(orgId).then((response) => {
            if (response) {
                setProjectList(response || []);
            }
        });
    });
    const triggerRefetch = () => __awaiter(this, void 0, void 0, function* () {
        if (org) {
            yield fetchProjects(org === null || org === void 0 ? void 0 : org.identity);
        }
    });
    const addNewProject = (newProject) => __awaiter(this, void 0, void 0, function* () {
        if (org === null || org === void 0 ? void 0 : org.identity) {
            // Needs to be set to false so setting it to true on add triggers the sort by createdDate and the auto-opening of the project details
            toggleNewProjectAdded(false);
            project
                .upsert(convertProjectCreateFormToIConfigureProject(newProject))
                .then((response) => {
                // This updates global project list
                // TODO: update to reflect state management changes upon migration
                const convertedProject = convertIProjectDetailsToIProjectSummary(response);
                const updatedProjectList = [convertedProject, ...projectList];
                updatedProjectList.sort(sortByField((project) => project.createdOn.getTime()));
                setProjectList(updatedProjectList);
                toggleNewProjectAdded(true);
            })
                .catch((err) => {
                console.error(err);
            });
        }
        else {
            toast({
                title: 'Error retrieving updated project list',
                status: 'error',
                description: 'Could not retrieve project list without org identity, please refresh page to get an updated project list',
            });
        }
    });
    const updateProjectInList = (project) => {
        const projectIndex = projectList.findIndex((projectSummary) => projectSummary.identity === project.identity);
        const updatedSummary = convertIProjectDetailsToIProjectSummary(project);
        projectList.splice(projectIndex, 1, updatedSummary);
        setProjectList([...projectList]);
    };
    const toggleNewProjectAdded = (value) => {
        setNewProjectAdded(value);
    };
    const deleteProject = (projectId) => __awaiter(this, void 0, void 0, function* () {
        if (org === null || org === void 0 ? void 0 : org.identity) {
            project
                .delete(projectId)
                .then(() => {
                const updatedProjectList = projectList.filter((project) => project.identity !== projectId);
                setProjectList(updatedProjectList);
            })
                .catch((err) => {
                console.error(err);
            });
        }
        else {
            toast({
                title: 'Error retrieving updated project list',
                status: 'error',
                description: 'Could not retrieve project list, please refresh page to get an updated project list',
            });
        }
    });
    return (_jsx(ProjectsContext.Provider, Object.assign({ value: {
            projectList,
            addNewProject,
            updateProjectInList,
            toggleNewProjectAdded,
            newProjectAdded,
            deleteProject,
            triggerProjectsRefetch: triggerRefetch,
        } }, { children: children })));
}
