var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { usePlatformResponseWrapper } from '../hooks/responseWrappers/usePlatformResponseWrapper';
import { convertSpaceSummaryResponseToISpaceSummary } from '../models/Space/SpaceConversions';
export const useSpaceService = ({ platformApi }) => {
    const { handleResponse: handlePlatformResponse } = usePlatformResponseWrapper();
    const fetchSpaceList = (facilityId, floorPlanId, assetClassTypes) => __awaiter(void 0, void 0, void 0, function* () {
        return handlePlatformResponse(platformApi.space.getApiV1SpaceList(facilityId, floorPlanId, assetClassTypes !== null && assetClassTypes !== void 0 ? assetClassTypes : undefined), {
            error: { label: 'Error occured retrieving Space List' },
            success: { enabled: false },
        }).then((res) => res.map((r) => convertSpaceSummaryResponseToISpaceSummary(r)));
    });
    return {
        space: {
            fetchList: fetchSpaceList,
        },
    };
};
