var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { usePlatformResponseWrapper } from '../hooks/responseWrappers/usePlatformResponseWrapper';
import { convertPlanAssetDetailResponseToPlanAssetDetail } from '../models/Plan/PlanConversions';
export const usePlanService = ({ platformApi }) => {
    const { handleResponse: handlePlatformResponse } = usePlatformResponseWrapper();
    const fetchAssets = (orgIdentity) => __awaiter(void 0, void 0, void 0, function* () {
        return handlePlatformResponse(platformApi.plan.getApiV1PlanGroup(orgIdentity), {
            error: { label: 'Error occurred retrieving Plan Assets' },
            success: { enabled: false },
        })
            .then((res) => convertPlanAssetDetailResponseToPlanAssetDetail(res))
            .catch(() => undefined);
    });
    return {
        plan: {
            fetchAssets,
        },
    };
};
