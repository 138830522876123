import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Button, Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, Text, } from '@chakra-ui/react';
import { FaSort, FaSortDown, FaSortUp } from 'react-icons/fa';
import { appColors, FiltersSorters, opacityHex } from '../../config/constants';
import { variants } from '../../config/theme';
const ProjectSortingModalTable = ({ table, isOpen, onClose, }) => {
    return (_jsx(Modal, Object.assign({ isOpen: isOpen, onClose: onClose }, { children: _jsxs(ModalContent, Object.assign({ borderRadius: '15px', boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.25)' }, { children: [_jsx(ModalHeader, { children: "Sort" }), _jsx(ModalCloseButton, {}), _jsx(ModalBody, { children: table.getAllColumns().map((column) => {
                        var _a;
                        return (_jsx(_Fragment, { children: column.id === FiltersSorters.PRIORITY && (_jsx(Box, Object.assign({ onClick: column.getToggleSortingHandler(), cursor: 'pointer', bg: column.getIsSorted() !== false
                                    ? `${appColors.SEC_ORANGE}${opacityHex.ten}`
                                    : '', p: 2 }, { children: _jsxs(Flex, Object.assign({ alignItems: 'center' }, { children: [(_a = {
                                            asc: _jsx(FaSortDown, {}),
                                            desc: _jsx(FaSortUp, {}),
                                        }[column.getIsSorted()]) !== null && _a !== void 0 ? _a : null, column.getIsSorted() === false && _jsx(FaSort, {}), _jsx(Text, Object.assign({ ml: '13px', fontSize: '16px' }, { children: column.id }))] })) }), column.id)) }));
                    }) }), _jsx(ModalFooter, { children: _jsx(Button, Object.assign({ variant: variants.blueBtn, fontSize: '14px', fontWeight: '700', onClick: () => {
                            table.resetSorting();
                            onClose();
                        } }, { children: "Clear sort" })) })] })) })));
};
export default ProjectSortingModalTable;
