import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { AddIcon } from '@chakra-ui/icons';
import { Button, ButtonGroup, Flex, Heading, HStack, InputGroup, InputLeftAddon, Select, useDisclosure, } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { appColors } from '../../config/constants';
import { ExternalPaths, Paths } from '../../config/paths';
import { useProjectsContext } from '../../contexts/Projects/ProjectsContext';
import { AddProjectModal } from '../../pages/projects/AddProjectModal/AddProjectModal';
import { ScenarioModal } from '../../pages/scenarios/ScenarioModal';
import { SESSION_SCENARIO_ID_KEY } from '../../pages/scenarios/ScenarioPlanner';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { fetchOrgApps, selectSelectedOrg, setOrgApps, setSelectedOrg, } from '../../store/orgSlice';
import { selectCreateProjectData } from '../../store/projectSlice';
import { fetchOrgScenarios, setCompare, setSelectedScenario, } from '../../store/scenarioSlice';
import { projectCanBeEditedBy, scenarioCanBeAddedBy, scenarioCanBeEditedBy, selectAccess, selectOrgRole, selectScenarioRole, } from '../../store/userSlice';
export const PageHeader = ({ title, userOrgs, projectCriteria, orgFundingTypes, orgProjectTypes, }) => {
    const params = useParams();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const location = useLocation();
    const projectDisclosure = useDisclosure();
    const scenarioDisclosure = useDisclosure();
    const currOrg = useAppSelector(selectSelectedOrg);
    const orgRole = useAppSelector(selectOrgRole);
    const scenarioRole = useAppSelector(selectScenarioRole);
    const { addNewProject } = useProjectsContext();
    const sites = useAppSelector(selectCreateProjectData);
    const cpAccess = useAppSelector(selectAccess);
    const canEditProject = projectCanBeEditedBy(orgRole);
    const canEditScenario = scenarioCanBeEditedBy(scenarioRole);
    const canAddProject = orgFundingTypes &&
        orgFundingTypes.length > 0 &&
        projectCriteria.length > 0 &&
        orgProjectTypes &&
        orgProjectTypes.length > 0;
    const canAddScenario = orgFundingTypes &&
        orgFundingTypes.length > 0 &&
        scenarioCanBeAddedBy(orgRole);
    const onAddNew = (type) => {
        if (type === SCENERIO_BUTTON_TYPE) {
            location.pathname.includes(Paths.SCENARIO_PLANNER)
                ? scenarioDisclosure.onOpen()
                : navigate(ExternalPaths.getMyOrgScenariosUrl(currOrg === null || currOrg === void 0 ? void 0 : currOrg.id), {
                    state: { showModal: true },
                });
        }
        else if (type === PROJECT_BUTTON_TYPE) {
            location.pathname.includes(Paths.PROJECTS)
                ? projectDisclosure.onOpen()
                : navigate(ExternalPaths.getMyOrgProjectsUrl(currOrg === null || currOrg === void 0 ? void 0 : currOrg.id), {
                    state: { showModal: true },
                });
        }
    };
    const showModalForPage = (pathname) => {
        if (pathname.includes(Paths.PROJECTS)) {
            projectDisclosure.onOpen();
        }
        else if (pathname.includes(Paths.SCENARIO_PLANNER)) {
            scenarioDisclosure.onOpen();
        }
    };
    const getPageUrl = (orgId) => location.pathname.includes(ExternalPaths.getMyProjectsUrl())
        ? ExternalPaths.getMyOrgProjectsUrl(orgId)
        : location.pathname.includes(ExternalPaths.getMyScenariosUrl())
            ? ExternalPaths.getMyOrgScenariosUrl(orgId)
            : location.pathname.includes(ExternalPaths.getMyAssetsUrl())
                ? ExternalPaths.getMyOrgAssetsUrl(orgId)
                : location.pathname;
    const handleOrgChange = (e) => {
        resetOrgInfo();
        const orgId = e.target.value;
        const url = getPageUrl(orgId);
        navigate(url);
    };
    const resetOrgInfo = () => {
        sessionStorage.removeItem(SESSION_SCENARIO_ID_KEY);
        dispatch(setSelectedScenario(undefined));
        dispatch(setOrgApps(undefined));
        dispatch(setCompare(false));
    };
    const getOrganizationOptions = (orgs) => {
        return orgs.map((org, index) => (_jsx("option", Object.assign({ value: org.id }, { children: org.name }), index)));
    };
    useEffect(() => {
        if (location.state && location.state.showModal) {
            showModalForPage(location.pathname);
            // clear state once used to avoid state mutation issues on reload
            window.history.replaceState({}, document.title);
            location.state.showModal = false;
        }
    });
    const updateSelectedOrg = (oid) => {
        const orgToSelect = userOrgs === null || userOrgs === void 0 ? void 0 : userOrgs.find((org) => org.id === oid);
        if (orgToSelect) {
            dispatch(setSelectedOrg(orgToSelect));
            dispatch(fetchOrgApps(orgToSelect.id));
            dispatch(fetchOrgScenarios(orgToSelect.identity));
        }
    };
    useEffect(() => {
        if (userOrgs && userOrgs.length > 0) {
            if (params.oid && (!currOrg || params.oid !== currOrg.id)) {
                updateSelectedOrg(params.oid);
            }
            else if (currOrg === null || currOrg === void 0 ? void 0 : currOrg.id) {
                navigate(getPageUrl(currOrg.id));
            }
            else {
                navigate(getPageUrl(userOrgs[0].id));
            }
        }
    }, [userOrgs, currOrg, params.oid]);
    const hasOrgsToRenderDropdown = userOrgs && userOrgs.length > 0;
    return (_jsxs(_Fragment, { children: [_jsx(Flex, Object.assign({ as: 'header', minH: '50px', w: '100%', alignItems: 'center' }, { children: _jsxs(HStack, Object.assign({ borderBottom: '1px solid #E6E7E9', justifyContent: 'space-between', w: '100%', h: '100%', p: '5px 40px' }, { children: [_jsx(Heading, Object.assign({ as: 'h1', fontSize: 36, fontWeight: 400, color: appColors.TEXT_SEC_DARK_GRAY, whiteSpace: 'nowrap' }, { children: title })), hasOrgsToRenderDropdown && (_jsxs(Flex, Object.assign({ gap: 5, m: 5, alignItems: 'center' }, { children: [_jsxs(InputGroup, { children: [_jsx(InputLeftAddon, Object.assign({ borderLeftRadius: '8px', color: 'white', background: appColors.PRIM_BLUE }, { children: "Organization" })), _jsxs(Select, Object.assign({ "data-testid": 'org-select', minW: '239px', onChange: handleOrgChange, value: (currOrg === null || currOrg === void 0 ? void 0 : currOrg.id) || '', borderLeftRadius: 0 }, { children: [_jsx("option", Object.assign({ disabled: true, value: '' }, { children: "Select Organization" })), userOrgs && getOrganizationOptions(userOrgs)] }))] }), !cpAccess || (!canEditProject && !canAddScenario) ? (_jsx(_Fragment, {})) : (_jsxs(ButtonGroup, Object.assign({ variant: 'outline', colorScheme: 'primBlue', spacing: 5, alignItems: 'center', isDisabled: !currOrg }, { children: [canEditProject && (_jsx(Button, Object.assign({ "aria-label": 'add new project', leftIcon: _jsx(AddIcon, {}), w: '50%', h: '40px', onClick: () => {
                                                onAddNew(PROJECT_BUTTON_TYPE);
                                            }, isDisabled: !canAddProject }, { children: "New Project" }))), canEditScenario && (_jsx(Button, Object.assign({ "aria-label": 'add new scenario', leftIcon: _jsx(AddIcon, {}), w: '50%', h: '40px', onClick: () => {
                                                onAddNew(SCENERIO_BUTTON_TYPE);
                                                dispatch(setCompare(false));
                                            }, isDisabled: !canAddScenario }, { children: "New Scenario" })))] })))] })))] })) })), _jsx(AddProjectModal, { disclosure: projectDisclosure, sites: sites || [], fundingTypes: orgFundingTypes, projectTypes: orgProjectTypes, onCreate: addNewProject }), _jsx(ScenarioModal, { isOpen: scenarioDisclosure.isOpen, onClose: scenarioDisclosure.onClose, fundingTypes: orgFundingTypes })] }));
};
const PROJECT_BUTTON_TYPE = 'project';
const SCENERIO_BUTTON_TYPE = 'scenario';
