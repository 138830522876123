import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { Box, Stack, Tab, TabList, TabPanel, TabPanels, Tabs, VStack, } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useController } from 'react-hook-form';
import { CurrencyInput } from '../../../components/forms/CurrencyInput';
import { appColors, BudgetTypeEnum } from '../../../config/constants';
import { useAppSelector } from '../../../store/hooks';
import { selectEditScenario } from '../../../store/scenarioSlice';
import { FundingTypeInputs } from './FundingTypeInputs';
const PLAN_TOTAL_BUDGET_KEY = 'totalBudget';
export const BudgetPage = ({ register, watch, setHasErrors, control, planType, fundingTypes, scenarioCurrent, firstYear, amountOfYears, }) => {
    const edit = useAppSelector(selectEditScenario);
    const [years, setYears] = useState([]);
    const [startPoint, setStartPoint] = useState(0);
    const [endPoint, setEndPoint] = useState(9);
    const endReached = amountOfYears
        ? amountOfYears - 9 > 0
            ? amountOfYears - 9
            : 0
        : undefined;
    useEffect(() => {
        const yearsTemp = [];
        if (firstYear && amountOfYears) {
            for (let i = firstYear; i < firstYear + amountOfYears; i++) {
                yearsTemp.push(i);
            }
        }
        setYears(yearsTemp);
    }, [scenarioCurrent]);
    const controller = useController({
        name: PLAN_TOTAL_BUDGET_KEY,
        control,
        defaultValue: edit && scenarioCurrent ? scenarioCurrent.totalBudget.toString() : '',
        rules: { required: true },
    });
    useEffect(() => {
        if (planType === BudgetTypeEnum.TOTAL_BUDGET) {
            const planTotalBudget = watch(controller.field.name);
            if (!planTotalBudget) {
                setHasErrors(true);
            }
            else {
                setHasErrors(false);
            }
        }
    });
    const renderTotalBudgetInput = () => {
        return (_jsx(CurrencyInput, { label: 'Total Budget', controller: controller, placeholder: 'Type here...' }));
    };
    const showPrev = () => {
        setStartPoint(startPoint - 1);
        setEndPoint(endPoint - 1);
    };
    const showNext = () => {
        setStartPoint(startPoint + 1);
        setEndPoint(endPoint + 1);
    };
    const renderFundingTypeInputsByYear = (fundingTypes) => {
        return (_jsx(_Fragment, { children: _jsxs(Tabs, Object.assign({ w: '100%' }, { children: [_jsxs(TabList, { children: [startPoint > 0 ? (_jsx("button", Object.assign({ onClick: () => {
                                    showPrev();
                                }, style: { width: '10px', marginRight: '20px' } }, { children: _jsx(ChevronLeftIcon, {}) }))) : (_jsx(Box, { w: '30px' })), years.slice(startPoint, endPoint).map((year) => (_jsx(Tab, Object.assign({ _selected: {
                                    bg: '#E6E7E9',
                                    borderColor: appColors.SEC_LIGHT_GRAY,
                                } }, { children: year }), year))), startPoint !== endReached && (_jsx("button", Object.assign({ onClick: () => {
                                    showNext();
                                }, style: { width: '10px', marginLeft: '20px' } }, { children: _jsx(ChevronRightIcon, {}) })))] }), _jsx(TabPanels, { children: years.slice(startPoint, endPoint).map((year, i) => (_jsx(TabPanel, { children: _jsx(FundingTypeInputs, { yearIndex: years.indexOf(year), register: register, setHasErrors: setHasErrors, watch: watch, fundingTypes: fundingTypes ? fundingTypes : [], control: control, year: year, amountOfYears: amountOfYears }) }, year + i))) })] })) }));
    };
    return (_jsx(Stack, { children: _jsx(VStack, { children: planType === BudgetTypeEnum.TOTAL_BUDGET ? (renderTotalBudgetInput()) : planType === BudgetTypeEnum.FUNDING_TYPE ? (_jsx(FundingTypeInputs, { register: register, setHasErrors: setHasErrors, watch: watch, fundingTypes: fundingTypes ? fundingTypes : [], control: control })) : (renderFundingTypeInputsByYear(fundingTypes)) }) }));
};
