import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Center, Spinner } from '@chakra-ui/react';
import { usePlannerSettingsContext } from '@frontend/domain/contexts/Settings/PlannerSettingsContext';
import React, { useEffect } from 'react';
import { PageLayout } from '../../components/layouts/PageLayout';
import { appColors } from '../../config/constants';
import { useProjectsContext } from '../../contexts/Projects/ProjectsContext';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { selectOrgApps, selectSelectedOrg } from '../../store/orgSlice';
import { setProject } from '../../store/projectSlice';
import { fetchScenario, selectOrgScenarios, selectSelectedScenario, setSelectedScenario, setSelectedYearFocus, } from '../../store/scenarioSlice';
import { selectAccess } from '../../store/userSlice';
import { AccessDenied } from '../AccessDenied';
import { ScenarioInstructions } from './ScenarioInstructions';
import { ScenarioPage } from './scenarioPage/ScenarioPage';
import { ScenarioSelectHeader } from './ScenarioSelectHeader';
export const SESSION_SCENARIO_ID_KEY = 'scid';
export const ScenarioPlanner = () => {
    const { orgEscalationFactor, orgFundingTypes, orgProjectTypes, selectPrioritizationCriteriaForProject, } = usePlannerSettingsContext();
    const { projectList } = useProjectsContext();
    const dispatch = useAppDispatch();
    const currOrg = useAppSelector(selectSelectedOrg);
    const orgApps = useAppSelector(selectOrgApps);
    const selectedScenario = useAppSelector(selectSelectedScenario);
    const allScenarios = useAppSelector(selectOrgScenarios);
    const hasScenarios = allScenarios && allScenarios.length > 0;
    const cpAccess = useAppSelector(selectAccess);
    const prioritizationCriteria = selectPrioritizationCriteriaForProject();
    const canAddProject = orgFundingTypes &&
        orgFundingTypes.length > 0 &&
        prioritizationCriteria.length > 0 &&
        orgProjectTypes &&
        orgProjectTypes.length > 0;
    useEffect(() => {
        dispatch(setProject(undefined));
    }, []);
    useEffect(() => {
        const sessionScenarioId = sessionStorage.getItem(SESSION_SCENARIO_ID_KEY);
        if (sessionScenarioId && allScenarios && allScenarios.length > 0) {
            const scenario = allScenarios.find((scenario) => scenario.id === sessionScenarioId);
            if (scenario) {
                dispatch(fetchScenario(scenario.id));
            }
        }
    }, [allScenarios]);
    const onChangeScenario = (e) => {
        dispatch(setSelectedYearFocus(undefined));
        const scenarioId = e.target.value;
        if (scenarioId) {
            dispatch(fetchScenario(scenarioId));
            sessionStorage.setItem(SESSION_SCENARIO_ID_KEY, scenarioId);
        }
        else {
            dispatch(setSelectedScenario(undefined));
            sessionStorage.removeItem(SESSION_SCENARIO_ID_KEY);
        }
    };
    const viewScenario = () => {
        return (_jsxs(Box, Object.assign({ px: '40px' }, { children: [hasScenarios && (_jsx(ScenarioSelectHeader, { organization: currOrg, allScenarios: allScenarios, selectedScenario: selectedScenario, onChangeScenario: onChangeScenario, fundingTypes: orgFundingTypes, escalationFactor: orgEscalationFactor })), !selectedScenario ? (_jsx(ScenarioInstructions, { canAddProject: canAddProject })) : (_jsx(ScenarioPage, { scenarioCurrent: selectedScenario, projects: projectList, fundingTypes: orgFundingTypes, escalationFactor: orgEscalationFactor }))] })));
    };
    return (_jsx(PageLayout, Object.assign({ title: 'Scenario Planner' }, { children: !currOrg || !orgApps ? (_jsx(Center, Object.assign({ h: '85vh', w: '100%' }, { children: _jsx(Spinner, { color: appColors.PRIM_BLUE }) }))) : !cpAccess ? (_jsx(AccessDenied, {})) : (viewScenario()) })));
};
