import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Button, Flex, Heading, Stack, Table, TableContainer, Tbody, Text, useDisclosure, VStack, } from '@chakra-ui/react';
import { createColumnHelper, } from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import { useDrop } from 'react-dnd';
import { FaSort } from 'react-icons/fa';
import { MdFilterListAlt } from 'react-icons/md';
import { appColors, FiltersSorters } from '../../../config/constants';
import { FeatureFlags, useFeatureFlag } from '../../../store/featureFlag';
import { useAppSelector } from '../../../store/hooks';
import { selectSelectedScenario } from '../../../store/scenarioSlice';
import { uniqueObjects, useDataTable, } from '../../../utils/sortingAndFiltering';
import ProjectFilteringModalTable from '../../projects/ProjectFilteringModalTable';
import ProjectSortingModalTable from '../../projects/ProjectSortingModalTable';
import { ProjectTr } from './ProjectTr';
const columnHelper = createColumnHelper();
export const ProjectTable = ({ projects, onProjectDropped, }) => {
    const scenarioCurrent = useAppSelector(selectSelectedScenario);
    const [columnFilters, setColumnFilters] = useState([]);
    const [sorting, setSorting] = useState([]);
    const projectTableSortFilter = useFeatureFlag(FeatureFlags.PROJECT_LIST_SORT_FILTER_675);
    const { isOpen: isFilterModal, onOpen: onOpenFilterModal, onClose: onCloseFilterModal, } = useDisclosure();
    const { isOpen: isSortModal, onOpen: onOpenSortModal, onClose: onCloseSortModal, } = useDisclosure();
    const canEdit = scenarioCurrent && !scenarioCurrent.isLocked;
    const numericFilter = (row, columnId, filterValue) => {
        const rowValue = row.getValue(columnId);
        if (typeof filterValue === 'number') {
            return rowValue === filterValue;
        }
        else if (Array.isArray(filterValue)) {
            const [min, max] = filterValue;
            if (min && !max) {
                return Number(rowValue) >= min;
            }
            else if (!min && max) {
                return Number(rowValue) <= max;
            }
            else if (min && max) {
                return Number(rowValue) >= min && Number(rowValue) <= max;
            }
        }
        return true;
    };
    const filteredProjects = useMemo(() => projects.map((item) => {
        if (item.estimatedBudget === null) {
            return Object.assign(Object.assign({}, item), { project: Object.assign(Object.assign({}, item), { estBudget: 0 }) });
        }
        else {
            return item;
        }
    }), [projects]);
    const table = useDataTable({
        data: filteredProjects,
        state: {
            sorting,
            columnFilters,
        },
        onColumnFiltersChange: setColumnFilters,
        onSortingChange: setSorting,
        columns: [
            columnHelper.accessor('name', {
                id: FiltersSorters.PROJECT_NAME,
                cell: (info) => {
                    return _jsx(Text, { children: info.row.original.name });
                },
                header: FiltersSorters.PROJECT_NAME,
                footer: (info) => info.column.id,
            }),
            columnHelper.accessor('facilityIdentity', {
                id: FiltersSorters.FACILITY_NAME,
                cell: (info) => {
                    return (_jsx(Text, Object.assign({ display: 'none' }, { children: info.row.original.facilityIdentity })));
                },
                filterFn: numericFilter,
                header: FiltersSorters.FACILITY_NAME,
            }),
            columnHelper.accessor('estimatedBudget', {
                id: FiltersSorters.ESTIMATE_BUDGET,
                cell: (info) => {
                    return (_jsx(Text, Object.assign({ display: 'none' }, { children: info.row.original.estimatedBudget })));
                },
                filterFn: numericFilter,
                header: FiltersSorters.ESTIMATE_BUDGET,
            }),
            columnHelper.accessor('projectTypeName', {
                id: FiltersSorters.PROJECT_TYPE,
                cell: (info) => {
                    return (_jsx(Text, Object.assign({ display: 'none' }, { children: info.row.original.projectTypeName })));
                },
                filterFn: numericFilter,
                header: FiltersSorters.PROJECT_TYPE,
            }),
            columnHelper.accessor('priorityScore', {
                id: FiltersSorters.PRIORITY,
                cell: (info) => {
                    return (_jsx(Text, Object.assign({ display: 'none' }, { children: info.row.original.priorityScore })));
                },
                header: FiltersSorters.PRIORITY,
                invertSorting: true,
            }),
        ],
    });
    const [, drop] = useDrop(() => {
        return {
            accept: 'projectListItem',
            //item can be of types IProjectSummary or ScenarioProject, item can be drop from the project list into a year, or from a year into the project list or another year
            drop: (item) => {
                var _a;
                const projectIdentity = (_a = item.project.identity) !== null && _a !== void 0 ? _a : item.project.projectIdentity;
                onProjectDropped(projectIdentity);
            },
        };
    });
    const facilities = uniqueObjects(filteredProjects
        .filter((project) => project.facilityIdentity !== null)
        .sort((a, b) => a.facilityName.localeCompare(b.facilityName))
        .map((project) => ({
        identity: project.facilityIdentity,
        name: project.facilityName,
    })), (facilitySummary) => facilitySummary.identity);
    const projectTypes = uniqueObjects(filteredProjects
        .filter((project) => project.projectTypeName !== null)
        .sort((a, b) => a.projectTypeName.localeCompare(b.projectTypeName))
        .map((project) => project.projectTypeName), (projType) => projType);
    const handleReset = () => {
        if (sorting.length > 0 || columnFilters.length > 0) {
            table.resetColumnFilters();
            table.resetSorting();
        }
    };
    return (_jsxs(Stack, Object.assign({ h: '100%', minW: '17em' }, { children: [_jsxs(Flex, Object.assign({ justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap', marginBottom: projectTableSortFilter ? '25px' : '0px' }, { children: [_jsxs(Flex, { children: [_jsxs(Box, { children: [_jsx(Heading, Object.assign({ as: 'h3', variant: 'h3-v2', mr: 2 }, { children: "Project List" })), projectTableSortFilter && (_jsx(Text, Object.assign({ fontSize: '10px', display: 'block' }, { children: "(Select)" })))] }), !projectTableSortFilter && (_jsx(Text, Object.assign({ fontSize: '10px', display: 'block', mt: 2 }, { children: "(Select)" }))), projectTableSortFilter && !(scenarioCurrent === null || scenarioCurrent === void 0 ? void 0 : scenarioCurrent.isLocked) && (_jsx(Box, Object.assign({ cursor: 'pointer', mr: 2, mt: '5px', onClick: onOpenSortModal, "data-testid": 'sort-button' }, { children: _jsx(FaSort, { color: sorting.length > 0
                                        ? appColors.SEC_ORANGE
                                        : appColors.PRIM_BLUE }) })))] }), projectTableSortFilter && !(scenarioCurrent === null || scenarioCurrent === void 0 ? void 0 : scenarioCurrent.isLocked) && (_jsx(Flex, Object.assign({ alignItems: 'center' }, { children: _jsxs(Button, Object.assign({ size: 'sm', variant: 'outline', p: '15px', onClick: onOpenFilterModal, color: columnFilters.length > 0
                                ? appColors.SEC_ORANGE
                                : appColors.PRIM_BLUE, _hover: { bg: '' } }, { children: [_jsx(Box, Object.assign({ mr: '8px' }, { children: _jsx(MdFilterListAlt, { color: columnFilters.length > 0
                                            ? appColors.SEC_ORANGE
                                            : appColors.PRIM_BLUE }) })), "Filter"] })) })))] })), projectTableSortFilter && !(scenarioCurrent === null || scenarioCurrent === void 0 ? void 0 : scenarioCurrent.isLocked) && (_jsx(Flex, Object.assign({ cursor: 'pointer', justifyContent: 'flex-end', onClick: () => handleReset(), "data-testid": 'clear-button' }, { children: _jsx(Text, Object.assign({ fontSize: '10px', fontWeight: 500 }, { children: "Clear Filter / Sort" })) }))), _jsx(TableContainer, Object.assign({ h: '90%', overflowY: 'auto', position: 'relative' }, { children: _jsx(Table, Object.assign({ ref: (node) => (canEdit ? drop(node) : node), variant: 'border-none', borderWidth: '1px', borderColor: appColors.SEC_LIGHT_GRAY, bg: '#FBFBFB', boxSizing: 'border-box', p: '2px', borderRadius: '2px', height: '100%' }, { children: _jsx(Tbody, { children: table.getRowModel().rows.length > 0 ? (_jsx(VStack, { children: table.getRowModel().rows.map((row) => (_jsx(ProjectTr, { project: row.original }, row.original.identity))) })) : (_jsx(Text, Object.assign({ fontSize: '12px', fontWeight: 500, padding: '10px' }, { children: "No results found." }))) }) })) })), _jsx(ProjectFilteringModalTable, { table: table, columnFilters: columnFilters, isOpen: isFilterModal, onClose: onCloseFilterModal, facilities: facilities, projectTypes: projectTypes }), _jsx(ProjectSortingModalTable, { table: table, isOpen: isSortModal, onClose: onCloseSortModal })] })));
};
