import { jsxs as _jsxs } from "react/jsx-runtime";
import { Text } from '@chakra-ui/react';
import { calculateBudget } from '../../../config/CPHelperFunctions';
import { useAppSelector } from '../../../store/hooks';
import { selectSelectedApplyEscFactor, selectSelectedScenario, } from '../../../store/scenarioSlice';
export const EstimatedBudget = ({ scenarioProj, escalationFactor, }) => {
    const scenarioCurrent = useAppSelector(selectSelectedScenario);
    const applyEscFactor = useAppSelector(selectSelectedApplyEscFactor);
    const calculateEstimatedBudget = () => {
        if (scenarioProj && scenarioCurrent) {
            if (!applyEscFactor) {
                if (scenarioProj.project.estBudget) {
                    return scenarioProj.project.estBudget;
                }
            }
            else {
                return calculateBudget(scenarioProj, scenarioCurrent, escalationFactor);
            }
        }
        else {
            return 0;
        }
        return 0;
    };
    return (_jsxs(Text, Object.assign({ pb: '5px', "data-testid": 'estimated-budget', fontSize: '12px', color: (scenarioCurrent === null || scenarioCurrent === void 0 ? void 0 : scenarioCurrent.escalationFactor) &&
            scenarioProj &&
            scenarioProj.project.estBudget &&
            `$${new Intl.NumberFormat().format(scenarioProj.project.estBudget)}` !==
                `$${new Intl.NumberFormat().format(calculateBudget(scenarioProj, scenarioCurrent, escalationFactor))}`
            ? 'teal'
            : '' }, { children: ["EB: ", `$${new Intl.NumberFormat().format(calculateEstimatedBudget())}`] })));
};
