import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Stack, Text } from '@chakra-ui/react';
import { ProjectAssetCard } from '../assets/ProjectAssetCard';
import { ProjectAssetListHeader } from '../assets/ProjectAssetListHeader';
export const ProjectAssetsView = ({ assets, assignedAssets, showAgeAdjustedCondition, loadImages, canSelect = false, onSelect, actionText, }) => {
    const isSelected = (asset) => {
        var _a;
        return ((_a = !!(assignedAssets === null || assignedAssets === void 0 ? void 0 : assignedAssets.find((assignedAsset) => assignedAsset.identity === asset.identity))) !== null && _a !== void 0 ? _a : false);
    };
    return (_jsxs(Stack, Object.assign({ pb: '5px', w: '100%' }, { children: [assets.length > 0 && _jsx(ProjectAssetListHeader, {}), assets.length > 0 ? (assets.map((asset, i) => {
                return (_jsx(ProjectAssetCard, { asset: asset, loadImages: loadImages, showAgeAdjustedCondition: showAgeAdjustedCondition, canSelect: canSelect, onSelect: onSelect, isSelected: isSelected(asset) }, `${asset.identity} ${i}`));
            })) : (_jsx(Box, Object.assign({ height: '100%', alignItems: 'start', justifyContent: 'center', whiteSpace: 'pre-wrap', paddingTop: '10px', paddingLeft: '20px' }, { children: _jsx(Text, { children: actionText }) })))] })));
};
