var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import services from '..';
import { BudgetTypeEnum, PlanGoalEnum } from '../../config/constants';
import { calculateBudget, getPriorityInfo, } from '../../config/CPHelperFunctions';
import { ProjectFieldEnum, } from '../../objects/CPBaseInterfaces';
export function getScenario(scenarioId) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield services.scenarioService.getScenario(scenarioId);
    });
}
export function getOrgScenarios(orgIdentity) {
    return __awaiter(this, void 0, void 0, function* () {
        return services.scenarioService.getOrgScenarios(orgIdentity);
    });
}
export const groupBy = (arr, property, entity) => {
    return arr.reduce((obj, scProj) => {
        if (scProj.project[entity]) {
            if (!obj[scProj.project[entity][property]]) {
                obj[scProj.project[entity][property]] = [];
            }
            obj[scProj.project[entity][property]].push(scProj.project);
        }
        return obj;
    }, {});
};
export const groupByScProj = (scenarioProjects, keys) => {
    return scenarioProjects.reduce((reducerObject, scProj) => {
        const entityValue = scProj[keys.entityKey];
        if (entityValue && keys.entityKey === ProjectFieldEnum.fundingType) {
            if (!reducerObject[entityValue]) {
                reducerObject[entityValue] = [];
            }
            reducerObject[entityValue].push(scProj);
        }
        else {
            if (scProj.project[keys.entityKey]) {
                if (!reducerObject[scProj.project[keys.entityKey][keys.identityKey]]) {
                    reducerObject[scProj.project[keys.entityKey][keys.identityKey]] = [];
                }
                reducerObject[scProj.project[keys.entityKey][keys.identityKey]].push(scProj);
            }
        }
        return reducerObject;
    }, {});
};
export const condenseAndGroupByPriority = (arr, scenarioCurrent, escalationFactor) => {
    const groupedProjectsObj = arr.reduce((obj, scProj) => {
        var _a;
        const shallowScore = (_a = scProj.project.prioritizationScore) !== null && _a !== void 0 ? _a : 0;
        const code = getPriorityInfo(shallowScore).code;
        if (!obj[code]) {
            obj[code] = [];
        }
        if (escalationFactor && scenarioCurrent) {
            obj[code].push({
                id: scProj.project.id,
                name: scProj.project.name,
                isDeleted: scProj.project.isDeleted,
                projType: scProj.project.projType,
                fundingType: scProj.project.fundingType,
                facility: scProj.project.facility,
                estBudget: calculateBudget(scProj, scenarioCurrent, escalationFactor),
                projScope: scProj.project.projScope,
                assocAssetGroup: scProj.project.assocAssetGroup,
                files: scProj.project.files,
                prioritizationScore: scProj.project.prioritizationScore,
                priorities: scProj.project.priorities,
            });
        }
        else {
            obj[code].push(scProj.project);
        }
        return obj;
    }, {});
    const groupedProjects = [];
    Object.keys(groupedProjectsObj).forEach((key) => {
        const amount = groupedProjectsObj[key].reduce((accumulator, proj) => {
            if (proj.estBudget) {
                return accumulator + proj.estBudget;
            }
            else
                return accumulator + 0;
        }, 0);
        const obj = {
            id: key,
            name: key,
            budget: amount,
            projectNum: groupedProjectsObj[key].length,
        };
        groupedProjects.push(obj);
    });
    return groupedProjects;
};
export const calcTotals = (scenarioProjects, objKey, identity, args) => {
    const groupByKeys = {
        entityKey: objKey,
        identityKey: identity,
    };
    const groupedProjectsObj = groupByScProj(scenarioProjects, groupByKeys);
    const groupedProjects = [];
    Object.keys(groupedProjectsObj).forEach((key) => {
        const amount = groupedProjectsObj[key].reduce((accumulator, proj) => {
            if (proj.project.estBudget) {
                if (args &&
                    args.scenarioCurrent &&
                    args.scenarioCurrent.escalationFactor) {
                    return (accumulator +
                        +calculateBudget(proj, args.scenarioCurrent, args.escalationFactor).toFixed(2));
                }
                else {
                    return accumulator + proj.project.estBudget;
                }
            }
            else
                return accumulator + 0;
        }, 0);
        if (objKey === ProjectFieldEnum.fundingType) {
            const obj = {
                id: groupedProjectsObj[key][0].fundingTypeId,
                identity: groupedProjectsObj[key][0].fundingTypeIdentity,
                name: groupedProjectsObj[key][0].project.fundingType.name,
                budget: amount,
                fundingType: groupedProjectsObj[key][0].fundingType,
            };
            groupedProjects.push(obj);
        }
        else {
            const obj = {
                id: groupedProjectsObj[key][0].project[objKey].id,
                identity: groupedProjectsObj[key][0].project[objKey].identity,
                name: groupedProjectsObj[key][0].project[objKey].name,
                budget: amount,
                projectNum: groupedProjectsObj[key].length,
                squareFootage: groupedProjectsObj[key][0].project[objKey].squareFootage,
                fundingType: groupedProjectsObj[key][0].fundingType,
            };
            groupedProjects.push(obj);
        }
    });
    return groupedProjects;
};
export function createScenario(payload) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield services.scenarioService.createScenario(payload);
    });
}
export function updateScenario(payload) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield services.scenarioService.updateScenario(payload);
    });
}
export const createScenarioPayload = (data, orgIdentity, scenarioNumber, escalationFactor) => {
    const totalBudget = data.budgetType === BudgetTypeEnum.TOTAL_BUDGET
        ? parseCurrencyAsFloat(data.totalBudget || '').toFixed(2)
        : sumFundingTypeBudgets(data.fundingTypeBudgets || []);
    let budgetType = data.budgetType;
    if (data.planningNeed === PlanGoalEnum.NEEDS) {
        budgetType = BudgetTypeEnum.NEEDS;
    }
    return {
        name: data.name || `Scenario ${scenarioNumber}`,
        description: data.description,
        orgIdentity: orgIdentity,
        // TODO: need better way to validate year
        planDurationYrs: data.planDurationYrs > 30 ? 30 : data.planDurationYrs,
        startYear: data.startYear,
        planningNeed: data.planningNeed,
        budgetType: budgetType,
        totalBudget: totalBudget,
        planningFactors: data.planningFactors,
        fundingTypeBudgets: createFundingTypeBudgetPayload(data),
        escalationFactor: escalationFactor,
    };
};
export const updateScenarioPayload = (data, scid, scidentity, scenarioName, escalationFactor) => {
    const totalBudget = data.budgetType === BudgetTypeEnum.TOTAL_BUDGET
        ? parseCurrencyAsFloat(data.totalBudget || '').toFixed(2)
        : sumFundingTypeBudgets(data.fundingTypeBudgets || []);
    let budgetType = data.budgetType;
    if (data.planningNeed === PlanGoalEnum.NEEDS) {
        budgetType = BudgetTypeEnum.NEEDS;
    }
    return {
        name: data.name === ('' || undefined) ? scenarioName : data.name,
        description: data.description,
        scId: scid,
        scenarioIdentity: scidentity,
        planningNeed: data.planningNeed,
        budgetType: budgetType,
        totalBudget: totalBudget,
        planningFactors: data.planningFactors,
        fundingTypeBudgets: updateFundingTypeBudgetPayload(data),
        escalationFactor: escalationFactor,
    };
};
export function addProjectToScenario(scid, prIdentity, data) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield services.scenarioService.addProjectToScenario(scid, prIdentity, data);
    });
}
export function updateScenarioProjectFundingType(scid, prIdentity, projectToUpdate) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield services.scenarioService.updateScenarioProjectFundingType(scid, prIdentity, projectToUpdate);
    });
}
export function toggleScenarioLockStatus(scenario) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            if (!scenario.isLocked) {
                const lockedScenario = yield services.scenarioService.toggleScenarioLockStatus(scenario.scenarioIdentity, !scenario.isLocked);
                yield services.planService.analyzeScenario(scenario.scenarioIdentity);
                return lockedScenario;
            }
            else {
                const lockedScenario = yield services.scenarioService.toggleScenarioLockStatus(scenario.scenarioIdentity, !scenario.isLocked);
                yield services.planService.removeScenario(scenario.scenarioIdentity);
                return lockedScenario;
            }
        }
        catch (err) {
            throw new Error(`${err}`);
        }
    });
}
export function removeProjectFromScenario(scid, prIdentity) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield services.scenarioService.removeProjectFromScenario(scid, prIdentity);
    });
}
const createFundingTypeBudgetPayload = (data) => {
    const budgetsTemp = [];
    if (data.budgetType === BudgetTypeEnum.FUNDING_TYPE ||
        data.budgetType === BudgetTypeEnum.FUNDING_TYPE_PER_YEAR) {
        if (data.fundingTypeBudgets && data.fundingTypeBudgets.length > 0) {
            data.fundingTypeBudgets.forEach((budget) => {
                if (budget.budget) {
                    if (budget.year) {
                        budgetsTemp.push({
                            fundingTypeIdentity: budget.fundingTypeIdentity,
                            year: budget.year,
                            budget: +parseCurrencyAsFloat(budget.budget.toString() || '').toFixed(2),
                        });
                    }
                    else {
                        budgetsTemp.push({
                            fundingTypeIdentity: budget.fundingTypeIdentity,
                            budget: +parseCurrencyAsFloat(budget.budget.toString() || '').toFixed(2),
                        });
                    }
                }
            });
        }
    }
    return budgetsTemp;
};
const updateFundingTypeBudgetPayload = (data) => {
    const budgetsTemp = [];
    if (data.budgetType === BudgetTypeEnum.FUNDING_TYPE) {
        if (data.fundingTypeBudgets && data.fundingTypeBudgets.length > 0) {
            data.fundingTypeBudgets.forEach((budget) => {
                if (budget.budget)
                    budgetsTemp.push({
                        id: budget.id,
                        fundingTypeIdentity: budget.fundingTypeIdentity,
                        budget: +parseCurrencyAsFloat(budget.budget.toString() || '').toFixed(2),
                    });
            });
        }
    }
    return budgetsTemp;
};
export const parseCurrencyAsFloat = (value) => {
    const cleanValue = value.replace(/,/g, '');
    const intValue = parseFloat(cleanValue);
    return intValue;
};
export const sumFundingTypeBudgets = (fundingBudgetStrings) => {
    // TODO: look at decimal place innaccuracies 10.5 -> 10.49999999
    let total = 0;
    fundingBudgetStrings.forEach((budgetItem) => {
        if (budgetItem.budget)
            total += parseCurrencyAsFloat(budgetItem.budget.toString());
    });
    return total.toFixed(2);
};
export function addTaxImpact(taxImpact, scenarioIdentity) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield services.scenarioService.addTaxImpact(taxImpact, scenarioIdentity);
    });
}
