var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
const baseUrl = '/photos';
export default class PhotoService {
    constructor(api) {
        this.api = api;
    }
    getPhoto(phid) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.api.get(`${baseUrl}/${phid}`);
        });
    }
    // TODO: Move to generic service layer
    getPhotosByOrg(orgIdentity, assetIds) {
        return __awaiter(this, void 0, void 0, function* () {
            let relations = {};
            if (assetIds) {
                relations = Object.assign(Object.assign({}, relations), { 'relations[assetIdentities]': [...assetIds] });
            }
            const res = yield this.api.getWithQueryParams(`${baseUrl}/${orgIdentity}/organizationPhotos`, {}, relations);
            const consolidatedPhoto = res.map((resPhoto) => {
                const photo = {
                    id: resPhoto.id,
                    photoIdentity: resPhoto.photoIdentity,
                    name: resPhoto.name,
                    url: resPhoto.url,
                    favorite: resPhoto.favorite,
                    comments: resPhoto.comments,
                    tags: resPhoto.tags,
                    space: resPhoto.space && {
                        name: resPhoto.space.spaceId,
                    },
                    facility: resPhoto.facility && {
                        name: resPhoto.facility.name,
                    },
                };
                return photo;
            });
            return consolidatedPhoto;
        });
    }
}
