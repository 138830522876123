var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { SystemRole } from '../objects/UserData';
export default class UserService {
    constructor(api, platformApi) {
        this.api = api;
        this.platformApi = platformApi;
    }
    viewUser() {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.platformApi
                .get('/User/view')
                .then((platformUser) => this.getUserFromPlatformUser(platformUser));
        });
    }
    getUserFromPlatformUser(platformUser) {
        var _a;
        return {
            id: platformUser.userId,
            userIdentity: platformUser.userIdentity,
            email: platformUser.emailAddress,
            firstName: platformUser.firstName,
            lastName: platformUser.lastName,
            systemRole: (_a = platformUser.systemRole) !== null && _a !== void 0 ? _a : SystemRole.USER,
            apps: platformUser.apps,
            organizationRoles: platformUser.organizationRoles,
        };
    }
}
