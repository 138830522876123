import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { HStack, Input, Text } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useController, useWatch, } from 'react-hook-form';
import { environmentalLabelsAndTexts } from '../../../config/constants';
export const EnvironmentalInputs = ({ amountKey, numberOfYearsKey, control, environmentalSaving, text, trigger, }) => {
    const amount = useWatch({ name: amountKey, control });
    const numberOfYears = useWatch({ name: numberOfYearsKey, control });
    useEffect(() => {
        trigger();
    }, [amount, numberOfYears]);
    const isNumberString = (value) => {
        if (value === undefined || value === '') {
            return true;
        }
        const numberValue = parseInt(value.replace(/,/g, ''));
        return !isNaN(numberValue);
    };
    const validateEnviromentalSavingsSync = () => {
        if ((amount === '' || amount === undefined) &&
            (numberOfYears === '' || numberOfYears === undefined)) {
            return true;
        }
        if (amount !== '' &&
            amount !== undefined &&
            numberOfYears !== '' &&
            numberOfYears !== undefined) {
            return true;
        }
        return false;
    };
    let errorMsg;
    (function (errorMsg) {
        errorMsg["amountError"] = "Amount must be between -1,000,000 and 1,000,000";
        errorMsg["yearError"] = "Years must be between 0 and 100";
    })(errorMsg || (errorMsg = {}));
    const amountController = useController({
        name: amountKey,
        control,
        defaultValue: environmentalSaving === null || environmentalSaving === void 0 ? void 0 : environmentalSaving.amount,
        rules: {
            min: {
                value: -1000000,
                message: errorMsg.amountError,
            },
            max: {
                value: 1000000,
                message: errorMsg.amountError,
            },
            validate: {
                isNumber: (value) => {
                    return isNumberString(value) ? undefined : errorMsg.amountError;
                },
                isFilledOut: validateEnviromentalSavingsSync,
            },
        },
    });
    const numberOfYearsController = useController({
        name: numberOfYearsKey,
        control,
        defaultValue: environmentalSaving === null || environmentalSaving === void 0 ? void 0 : environmentalSaving.numberOfYears,
        rules: {
            min: { value: 0, message: errorMsg.yearError },
            max: { value: 100, message: errorMsg.yearError },
            validate: {
                isNumber: (value) => {
                    return isNumberString(value) ? undefined : errorMsg.yearError;
                },
                isFilledOut: validateEnviromentalSavingsSync,
            },
        },
    });
    const { field: { onChange: amountOnChange, value: amountValue }, } = amountController;
    const { field: { onChange: numberOfYearsOnChange, value: numberOfYearsValue }, } = numberOfYearsController;
    return (_jsxs(HStack, Object.assign({ alignItems: 'end' }, { children: [_jsx(Input, { w: '114px', type: 'number', value: amountValue, onChange: amountOnChange }), _jsx(Text, { children: text }), _jsx(Input, { w: '65px', type: 'number', value: numberOfYearsValue, onChange: numberOfYearsOnChange }), _jsx(Text, { children: environmentalLabelsAndTexts.years })] })));
};
