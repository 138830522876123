import { useFilter, } from '@frontend/design-system/components/Filter/Filter';
import FilterMap, { DefaultFilterType, } from '@frontend/design-system/components/Filter/FilterMap';
const blankFilter = {
    facilityName: null,
    assetCategory: null,
    assetType: null,
    currentCondition: null,
    projectedLifeRemaining: null,
    spaceName: null,
    projects: null,
};
const planAssetCurrentConditionFilter = (filterObj) => {
    return (obj) => {
        if (filterObj.currentCondition) {
            const filterItem = filterObj.currentCondition;
            if (filterItem.length > 0) {
                const valuesToFilterAgainst = filterObj.currentCondition.map((opt) => opt.value.toString());
                if (obj.currentCondition !== undefined) {
                    return valuesToFilterAgainst.includes(obj.currentCondition.toString());
                }
                else {
                    return valuesToFilterAgainst.includes('None');
                }
            }
        }
        return true;
    };
};
const hasAssociatedProjects = (filterObj) => {
    return (obj) => {
        if (filterObj.projects) {
            return obj.projects.length === 0;
        }
        else {
            return true;
        }
    };
};
export const usePlanAssetSummaryFilter = (assetSummaryList) => {
    const filterMap = new FilterMap();
    filterMap.set('facilityName', DefaultFilterType.MULTI_VALUE);
    filterMap.set('assetCategory', DefaultFilterType.MULTI_VALUE);
    filterMap.set('assetType', DefaultFilterType.MULTI_VALUE);
    filterMap.set('spaceName', DefaultFilterType.MULTI_VALUE);
    filterMap.set('projectedLifeRemaining', DefaultFilterType.RANGE);
    filterMap.setCustom('projects', hasAssociatedProjects);
    filterMap.setCustom('currentCondition', planAssetCurrentConditionFilter);
    return useFilter({
        itemsToFilter: assetSummaryList,
        filterMap,
        initialFilter: blankFilter,
    });
};
