var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useSpaceService } from '@frontend/domain/services/SpaceService';
import { useDependencies } from '@frontend/domain/contexts/Dependencies/DependenciesContext';
import { convertISpaceSummaryToIAssetWithLocation } from './AssetConversions';
import { AssetClassType } from '@frontend/domain/models/Assets/AssetsInterfaces';
import { useState } from 'react';
export const useAsset = () => {
    const { platformApi } = useDependencies();
    const { space } = useSpaceService({ platformApi });
    const [assetLocations, setAssetLocations] = useState([]);
    const fetchAssetsWithLocation = (facilityIdentity) => __awaiter(void 0, void 0, void 0, function* () {
        const spaceList = yield space.fetchList(facilityIdentity, undefined, [
            AssetClassType.FACILITY,
            AssetClassType.PINNED,
            AssetClassType.STANDARD,
        ]);
        setAssetLocations(convertISpaceSummaryToIAssetWithLocation(spaceList !== null && spaceList !== void 0 ? spaceList : []));
    });
    return {
        assetLocations,
        assets: { fetchAssetsWithLocation },
    };
};
