import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { Box, Button, Flex, Heading, List, ListItem, Text, } from '@chakra-ui/react';
import FileUpload from '@frontend/design-system/components/FileUpload/FileUpload';
import React from 'react';
import { BiSolidTrashAlt } from 'react-icons/bi';
import { MdUpload } from 'react-icons/md';
import { appColors } from '../../../config/constants';
import { slgqColorSchemes } from '../../../config/theme';
import { createFileWithUpdatedName, getFileExtension, toCleanFileName, } from '../../../utils/fileUtils';
export const AttachmentsTab = ({ projectFiles, editMode, register, errors, setError, clearErrors, addFilesToProject, removeFileFromProject, }) => {
    var _a;
    const validFileExtensions = '.pdf, .png, .jpeg, .jpg, .tif, .tiff, .docx, .pptx, .xlsx, .txt, .mp4, .mpg';
    const validFileSize = 50 * 1024 * 1024; // 50 MB
    const validFileNameSize = 255;
    const fileRegister = register('files', {
        onChange: (e) => {
            const files = e.target.files ? Array.from(e.target.files) : [];
            const invalidFiles = [];
            const validFiles = [];
            files.forEach((file) => {
                const fileName = toCleanFileName(file.name);
                const fileExtension = getFileExtension(fileName);
                const fileSize = file.size;
                if (!validFileExtensions.includes(fileExtension)) {
                    invalidFiles.push(file);
                    setError('files', {
                        message: `Allowed file types: ${validFileExtensions}`,
                    });
                }
                else if (fileSize > validFileSize) {
                    invalidFiles.push(file);
                    setError('files', {
                        message: `Max file size: ${validFileSize / 1024 / 1024} MB.`,
                    });
                }
                else if (fileName.length > validFileNameSize - 20) {
                    invalidFiles.push(file);
                    setError('files', {
                        message: 'The file name exceeds the maximum length of characters',
                    });
                }
                else {
                    const updatedFile = createFileWithUpdatedName(file, fileName);
                    validFiles.push(updatedFile);
                }
            });
            if (invalidFiles.length === 0) {
                clearErrors('files');
            }
            if (validFiles.length > 0) {
                addFilesToProject(validFiles);
            }
        },
    });
    const openAttachment = (url) => {
        const a = document.createElement('a');
        if (url) {
            a.href = url;
        }
        const pdfWindow = window.open();
        if (pdfWindow) {
            pdfWindow.location.href = a.href;
        }
    };
    return (_jsxs(Flex, Object.assign({ p: '24px', direction: 'column', gap: '20px' }, { children: [_jsxs(Flex, Object.assign({ justifyContent: 'space-between' }, { children: [_jsxs(Heading, Object.assign({ as: 'h3', variant: 'h3', color: appColors.PRIM_BLUE }, { children: ["Attached files (", (_a = projectFiles.length) !== null && _a !== void 0 ? _a : 0, ")"] })), editMode && (_jsx(Box, { children: _jsx(FileUpload, Object.assign({ accept: validFileExtensions, register: fileRegister, multiple: true }, { children: _jsx(Button, Object.assign({ leftIcon: _jsx(MdUpload, {}), variant: 'solid', colorScheme: slgqColorSchemes.primBlue }, { children: "Upload" })) })) }))] })), errors.files && errors.files.message && (_jsx(Text, Object.assign({ color: 'red', whiteSpace: 'pre-wrap' }, { children: errors.files.message }))), _jsx(List, Object.assign({ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: '10px 45px', alignItems: 'center' }, { children: projectFiles.map((file, i) => (_jsxs(ListItem, Object.assign({ display: 'flex', flexDirection: 'row', alignItems: 'center' }, { children: [_jsx(Text, Object.assign({ cursor: 'pointer', onClick: () => openAttachment(file.url) }, { children: file.name })), editMode && file.name && (_jsx(Button, Object.assign({ colorScheme: 'none', background: 'transparent', color: slgqColorSchemes.primBlue, height: 'fit-content', onClick: () => removeFileFromProject(file) }, { children: _jsx(BiSolidTrashAlt, {}) })))] }), `${i}-${file.name}`))) }))] })));
};
