var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { usePlatformResponseWrapper } from '../hooks/responseWrappers/usePlatformResponseWrapper';
import { convertProjectSummaryResponseToIProjectSummary, convertProjectToFormRequest, convertResponseToProject, } from '../models/Projects/ProjectConversions';
export const useProjectsService = ({ platformApi }) => {
    const { handleResponse: handlePlatformResponse } = usePlatformResponseWrapper();
    const fetchProjectList = (orgIdentity) => __awaiter(void 0, void 0, void 0, function* () {
        return handlePlatformResponse(platformApi.project.getApiV1ProjectList(orgIdentity), {
            error: { label: 'Error occured retrieving Project List' },
            success: { enabled: false },
        }).then((res) => res.map((r) => convertProjectSummaryResponseToIProjectSummary(r)));
    });
    const fetchProject = (projectIdentity) => __awaiter(void 0, void 0, void 0, function* () {
        return handlePlatformResponse(platformApi.project.getApiV1ProjectLoad(projectIdentity), {
            error: { label: 'Error occured retrieving Project Details' },
            success: { enabled: false },
        }).then((res) => {
            // TODO: not all fields are being mapped, only ones set on create
            return convertResponseToProject(res);
        });
    });
    const configureProject = (project) => __awaiter(void 0, void 0, void 0, function* () {
        return handlePlatformResponse(platformApi.project.postApiV1ProjectConfigure(convertProjectToFormRequest(project)), {
            error: { label: 'Error occured configuring Project' },
            success: {
                enabled: true,
                label: `Successfully saved Project: ${project.name}`,
            },
        }).then((res) => {
            return convertResponseToProject(res);
        });
    });
    const deleteProject = (projectIdentity) => __awaiter(void 0, void 0, void 0, function* () {
        return handlePlatformResponse(platformApi.project.deleteApiV1ProjectRemove(projectIdentity), {
            error: { label: 'Error occured deleting Project' },
            success: {
                enabled: true,
                label: 'Successfully deleted Project',
            },
        }).then(() => {
            return;
        });
    });
    return {
        project: {
            fetchList: fetchProjectList,
            fetchDetails: fetchProject,
            upsert: configureProject,
            delete: deleteProject,
        },
    };
};
