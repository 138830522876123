import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Box } from '@chakra-ui/react';
import { useController } from 'react-hook-form';
import { CurrencyInput } from '../../../components/forms/CurrencyInput';
import { appColors } from '../../../config/constants';
import { getCurrencyValue } from '../../../config/CPHelperFunctions';
const TOTAL_REINVESTMENT_KEY = 'totalReinvestment';
export const GeneralInformation = ({ control, taxImpact, }) => {
    const controller = useController({
        name: TOTAL_REINVESTMENT_KEY,
        control,
    });
    return (_jsx(_Fragment, { children: taxImpact ? (_jsx(_Fragment, { children: taxImpact.totalReinvestment && (_jsxs(_Fragment, { children: [_jsx(Box, Object.assign({ display: 'flex', alignItems: 'center', textColor: appColors.PRIM_BLUE, fontWeight: '400', fontSize: '14px', pl: '18px' }, { children: "Total value of reinvestment" })), _jsx(Box, Object.assign({ pl: '15px' }, { children: taxImpact.totalReinvestment &&
                            getCurrencyValue(taxImpact.totalReinvestment) }))] })) })) : (_jsx(CurrencyInput, { label: 'Total value of reinvestment', controller: controller, placeholder: 'Type here...' })) }));
};
