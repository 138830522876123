var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, ButtonGroup, FormControl, FormLabel, HStack, IconButton, Select, useDisclosure, } from '@chakra-ui/react';
import { Tooltip } from '@frontend/design-system/components/Tooltip/Tooltip';
import { useEffect, useState } from 'react';
import { MdLock, MdModeEditOutline } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { appColors } from '../../config/constants';
import { TaxIcon, UnlockIcon } from '../../config/icons';
import { variants } from '../../config/theme';
import { useProjectsContext } from '../../contexts/Projects/ProjectsContext';
import { OrganizationRole } from '../../objects/UserData';
import { getTaxImpact } from '../../services/api/taxImpactAPI';
import { useAppSelector } from '../../store/hooks';
import { useScenarioLock } from '../../store/scenarioLockHook';
import { setEditScenario, setSelectedScenario, } from '../../store/scenarioSlice';
import { scenarioCanBeEditedBy, selectOrgRole, selectScenarioRole, } from '../../store/userSlice';
import { UnlockScenarioWarningModal } from './lockScenario/UnlockScenarioWarningModal';
import { ScenarioModal } from './ScenarioModal';
import { TaxImpactModal } from './taxImpact/TaxImpactModal';
export const ScenarioSelectHeader = ({ allScenarios, selectedScenario, organization, onChangeScenario, fundingTypes, escalationFactor, }) => {
    const [taxImpact, setTaxImpact] = useState(undefined);
    const { triggerProjectsRefetch } = useProjectsContext();
    const orgRole = useAppSelector(selectOrgRole);
    const taxImpactDisclosure = useDisclosure();
    const scenarioDisclosure = useDisclosure();
    const scenarioRole = useAppSelector(selectScenarioRole);
    const handleLockScenario = useScenarioLock();
    const toggleLockScenario = () => __awaiter(void 0, void 0, void 0, function* () {
        if (selectedScenario) {
            yield handleLockScenario(selectedScenario).then(() => triggerProjectsRefetch());
        }
    });
    useEffect(() => {
        const fetchTaxImpact = () => __awaiter(void 0, void 0, void 0, function* () {
            if (selectedScenario) {
                const fetchedTaxImpact = yield getTaxImpact(selectedScenario.scenarioIdentity);
                setTaxImpact(fetchedTaxImpact);
            }
        });
        fetchTaxImpact();
    }, [selectedScenario]);
    const dispatch = useDispatch();
    const unlockScenarioWarningDisclosure = useDisclosure();
    const onClose = () => {
        scenarioDisclosure.onClose();
        dispatch(setEditScenario(false));
    };
    useEffect(() => {
        dispatch(setSelectedScenario(undefined));
    }, [allScenarios, organization]);
    const canEdit = scenarioCanBeEditedBy(scenarioRole);
    const taxImpactButton = (_jsx(IconButton, { "aria-label": 'tax impact', icon: _jsx(TaxIcon, { color: 'white' }), onClick: () => {
            taxImpactDisclosure.onOpen();
        }, variant: variants.blueBtn, w: '60px' }));
    const editButton = (_jsx(IconButton, { "aria-label": 'edit scenario', icon: _jsx(MdModeEditOutline, {}), variant: variants.blueOutlineBtn, onClick: () => {
            dispatch(setEditScenario(true));
            scenarioDisclosure.onOpen();
        }, w: '60px' }));
    const lockScenarioButton = (_jsx(IconButton, { "aria-label": 'lock scenarios', icon: !(selectedScenario === null || selectedScenario === void 0 ? void 0 : selectedScenario.isLocked) ? (_jsx(MdLock, {})) : (_jsx(UnlockIcon, { color: 'white' })), onClick: () => {
            if (selectedScenario === null || selectedScenario === void 0 ? void 0 : selectedScenario.isLocked) {
                unlockScenarioWarningDisclosure.onOpen();
            }
            else {
                toggleLockScenario();
            }
        }, variant: variants.blueBtn, w: '60px' }));
    const renderButton = (label) => {
        let icon = _jsx(_Fragment, {});
        if (label === 'Edit') {
            icon = editButton;
        }
        else if (label === 'Tax Impact') {
            icon = taxImpactButton;
        }
        else if (label === 'Lock' || label === 'Unlock') {
            icon = lockScenarioButton;
        }
        return (_jsx(Tooltip, { label: label, icon: icon, props: {
                children: null,
                placement: 'bottom-end',
                hasArrow: true,
                bg: 'white',
                color: '#2E3532',
                boxShadow: '0px 0px 4px 0px #00000040',
                border: '1px solid #D6D7DB',
                arrowShadowColor: '#D6D7DB',
                arrowSize: 20,
            } }));
    };
    return (_jsx(_Fragment, { children: _jsxs(Box, Object.assign({ p: 1 }, { children: [_jsxs(HStack, { children: [_jsxs(FormControl, Object.assign({ m: '0px!' }, { children: [_jsx(FormLabel, Object.assign({ fontSize: '10px' }, { children: "Select a scenario" })), _jsxs(Select, Object.assign({ color: appColors.PRIM_BLUE, w: '239px', onChange: onChangeScenario, value: (selectedScenario === null || selectedScenario === void 0 ? void 0 : selectedScenario.id) || '' }, { children: [_jsx("option", Object.assign({ disabled: true, value: '' }, { children: "Select" })), allScenarios &&
                                            allScenarios.map((scenario, i) => (_jsxs("option", Object.assign({ value: scenario.id }, { children: [scenario.name, " ", scenario.isLocked && _jsx("p", { children: "\uD83D\uDD12" })] }), `${scenario.name} ${i}`)))] }))] })), _jsxs(ButtonGroup, { children: [selectedScenario &&
                                    selectedScenario.isLocked &&
                                    ((!taxImpact && orgRole === OrganizationRole.LOCAL_ADMIN) ||
                                        taxImpact) &&
                                    renderButton('Tax Impact'), canEdit &&
                                    selectedScenario &&
                                    !selectedScenario.isLocked &&
                                    renderButton('Edit'), selectedScenario &&
                                    (orgRole === OrganizationRole.LOCAL_ADMIN ||
                                        orgRole === OrganizationRole.CLIENT_CONCIERGE) &&
                                    renderButton(!(selectedScenario === null || selectedScenario === void 0 ? void 0 : selectedScenario.isLocked) ? 'Lock' : 'Unlock')] })] }), _jsx(ScenarioModal, { isOpen: scenarioDisclosure.isOpen, onClose: onClose, fundingTypes: fundingTypes }), selectedScenario && (_jsx(TaxImpactModal, { isOpen: taxImpactDisclosure.isOpen, onClose: taxImpactDisclosure.onClose, scenario: selectedScenario, taxImpact: taxImpact, escalationFactor: escalationFactor })), _jsx(UnlockScenarioWarningModal, { isOpen: unlockScenarioWarningDisclosure.isOpen, onClose: unlockScenarioWarningDisclosure.onClose, scenario: selectedScenario, toggleLockScenario: toggleLockScenario })] })) }));
};
