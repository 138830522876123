var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Center, Spinner, useToast } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { PageLayout } from '../../components/layouts/PageLayout';
import { appColors } from '../../config/constants';
import { useProjectsContext } from '../../contexts/Projects/ProjectsContext';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { selectOrgApps, selectSelectedOrg } from '../../store/orgSlice';
import { fetchCreateProjectData } from '../../store/projectSlice';
import { selectAccess } from '../../store/userSlice';
import { AccessDenied } from '../AccessDenied';
import ProjectsTable from './ProjectsTable';
export const priorityPadding = '0px';
export const Projects = () => {
    const params = useParams();
    const dispatch = useAppDispatch();
    const { projectList, newProjectAdded, toggleNewProjectAdded } = useProjectsContext();
    const currOrg = useAppSelector(selectSelectedOrg);
    const orgApps = useAppSelector(selectOrgApps);
    const cpAccess = useAppSelector(selectAccess);
    const [loading, setLoading] = useState(false);
    const toast = useToast();
    useEffect(() => {
        const fetchData = (orgId) => __awaiter(void 0, void 0, void 0, function* () {
            try {
                setLoading(true);
                // fetches sites list for project creation
                // TODO: move from here to facility selector
                dispatch(fetchCreateProjectData(orgId));
            }
            catch (err) {
                console.error('Failed to fetch project data: ', err);
                toast({
                    title: 'Failed to fetch project data',
                    status: 'error',
                    isClosable: true,
                });
            }
            finally {
                setLoading(false);
            }
        });
        const orgId = params.oid || (currOrg && currOrg.id);
        if (orgId) {
            fetchData(orgId);
        }
    }, [params.oid, currOrg === null || currOrg === void 0 ? void 0 : currOrg.id]);
    return (_jsx(PageLayout, Object.assign({ title: 'myProjects' }, { children: _jsx(_Fragment, { children: loading || !currOrg || !orgApps ? (_jsx(Center, Object.assign({ h: '85vh', w: '100%' }, { children: _jsx(Spinner, { color: appColors.PRIM_BLUE }) }))) : !cpAccess ? (_jsx(AccessDenied, {})) : (_jsx(ProjectsTable, { projects: projectList, newProjectAdded: newProjectAdded, toggleNewProjectAdded: toggleNewProjectAdded })) }) })));
};
