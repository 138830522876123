import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { Checkbox, HStack, Text, VStack } from '@chakra-ui/react';
import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip, } from 'chart.js';
import { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { appColors, BudgetTypeEnum } from '../../../config/constants';
import { calculateBudget } from '../../../config/CPHelperFunctions';
import { variants } from '../../../config/theme';
import { parseCurrencyAsFloat } from '../../../services/api/scenarioAPI';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { selectSelectedYearFocus, setSelectedApplyEscFactor, } from '../../../store/scenarioSlice';
export const BudgetSummaryWidget = ({ scenarioCurrent, applyEscFactor, fundingTypes, escalationFactor, }) => {
    const [labels, setLabels] = useState([]);
    const [budgets, setBudgets] = useState([]);
    const [allocatedBudgets, setAllocatedBudgets] = useState([]);
    const [totalAllocated, setTotalAllocated] = useState(0);
    const yearFocus = useAppSelector(selectSelectedYearFocus);
    const dispatch = useAppDispatch();
    const getFundingTypeLabels = (fundingTypes) => {
        return fundingTypes
            ? fundingTypes.map((fundingType) => fundingType.name)
            : ['Org does not contain funding types'];
    };
    const getAllocatedBudgets = (scenarioCurrent, fundingTypes) => {
        var _a;
        const allocatedTemp = [];
        if (fundingTypes && fundingTypes.length > 0) {
            fundingTypes === null || fundingTypes === void 0 ? void 0 : fundingTypes.forEach((funding) => {
                allocatedTemp.push({
                    fundingTypeIdentity: funding.identity,
                    budget: 0,
                });
            });
        }
        (_a = scenarioCurrent.assignedProjects) === null || _a === void 0 ? void 0 : _a.forEach((scenProj) => {
            var _a;
            const fundingTypeIdentity = scenProj.fundingTypeIdentity
                ? scenProj.fundingTypeIdentity
                : (_a = scenProj.project) === null || _a === void 0 ? void 0 : _a.fundingType.identity;
            allocatedTemp.forEach((allocate, i) => {
                if (fundingTypeIdentity === allocate.fundingTypeIdentity &&
                    scenProj.project.estBudget) {
                    if (scenarioCurrent.budgetType === 'fundingTypeBudgetPerYear' &&
                        yearFocus) {
                        if (yearFocus === scenProj.year) {
                            if (applyEscFactor) {
                                allocatedTemp[i] = {
                                    // TODO confused why this was setting project id/identity to funding type id/identity
                                    fundingTypeIdentity: scenProj.project.projectIdentity,
                                    budget: +calculateBudget(scenProj, scenarioCurrent, escalationFactor).toFixed(2),
                                };
                            }
                            else {
                                allocatedTemp[i] = {
                                    // TODO confused why this was setting project id/identity to funding type id/identity
                                    fundingTypeIdentity: scenProj.project.projectIdentity,
                                    budget: scenProj.project.estBudget,
                                };
                            }
                        }
                    }
                    else {
                        if (applyEscFactor) {
                            allocatedTemp[i].budget =
                                allocatedTemp[i].budget +
                                    +calculateBudget(scenProj, scenarioCurrent, escalationFactor).toFixed(2);
                        }
                        else {
                            allocatedTemp[i].budget =
                                allocatedTemp[i].budget + scenProj.project.estBudget;
                        }
                    }
                }
            });
        });
        return allocatedTemp.map((allocated) => allocated.budget);
    };
    const setWidgetDataForFundingType = (scenarioCurrent, fundingTypes) => {
        setLabels(getFundingTypeLabels(fundingTypes));
        const budgetsTemp = [];
        if (fundingTypes &&
            fundingTypes.length > 0 &&
            scenarioCurrent.fundingTypeBudgets &&
            scenarioCurrent.fundingTypeBudgets.length > 0) {
            fundingTypes === null || fundingTypes === void 0 ? void 0 : fundingTypes.forEach((funding) => {
                var _a;
                (_a = scenarioCurrent.fundingTypeBudgets) === null || _a === void 0 ? void 0 : _a.forEach((budget) => {
                    if (budget.fundingTypeIdentity === funding.identity) {
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        budgetsTemp.push(budget.budget);
                    }
                });
            });
        }
        setBudgets(budgetsTemp);
        setAllocatedBudgets(getAllocatedBudgets(scenarioCurrent, fundingTypes));
    };
    const setWidgetDataForTotalBudget = (scenarioCurrent) => {
        setLabels(['Total Budget']);
        setBudgets([scenarioCurrent.totalBudget]);
        let totalAllocatedTemp = 0;
        let assignedProjectsTemp = [];
        if (scenarioCurrent.assignedProjects) {
            assignedProjectsTemp = scenarioCurrent.assignedProjects;
        }
        assignedProjectsTemp === null || assignedProjectsTemp === void 0 ? void 0 : assignedProjectsTemp.forEach((scenProj) => {
            var _a, _b;
            if ((_a = scenProj === null || scenProj === void 0 ? void 0 : scenProj.project) === null || _a === void 0 ? void 0 : _a.estBudget) {
                if (applyEscFactor) {
                    totalAllocatedTemp =
                        totalAllocatedTemp +
                            +calculateBudget(scenProj, scenarioCurrent, escalationFactor).toFixed(2);
                }
                else {
                    totalAllocatedTemp =
                        totalAllocatedTemp + ((_b = scenProj === null || scenProj === void 0 ? void 0 : scenProj.project) === null || _b === void 0 ? void 0 : _b.estBudget);
                }
            }
        });
        setAllocatedBudgets([totalAllocatedTemp]);
    };
    const setWidgetDataForFundingTypePerYear = (scenarioCurrent, fundingTypes) => {
        setLabels(getFundingTypeLabels(fundingTypes));
        const budgetsTemp = [];
        if (fundingTypes && scenarioCurrent.fundingTypeBudgets) {
            fundingTypes === null || fundingTypes === void 0 ? void 0 : fundingTypes.forEach((funding, index) => {
                var _a;
                let budgetTemp = 0;
                budgetsTemp.push(0);
                (_a = scenarioCurrent.fundingTypeBudgets) === null || _a === void 0 ? void 0 : _a.forEach((budget) => {
                    if (budget.fundingTypeIdentity === funding.identity &&
                        budget.budget) {
                        if (yearFocus) {
                            if (budget.year === yearFocus) {
                                budgetsTemp[index] = budget.budget;
                            }
                        }
                        else {
                            budgetTemp += parseCurrencyAsFloat(budget.budget.toString());
                            budgetsTemp[index] = budgetTemp;
                        }
                    }
                });
            });
        }
        setBudgets(budgetsTemp);
        setAllocatedBudgets(getAllocatedBudgets(scenarioCurrent, fundingTypes));
    };
    const setDefaultWidgetData = (scenarioCurrent, fundingTypes) => {
        setLabels(getFundingTypeLabels(fundingTypes));
        setBudgets([]);
        setAllocatedBudgets(getAllocatedBudgets(scenarioCurrent, fundingTypes));
    };
    useEffect(() => {
        switch (scenarioCurrent.budgetType) {
            case BudgetTypeEnum.FUNDING_TYPE:
                setWidgetDataForFundingType(scenarioCurrent, fundingTypes);
                break;
            case BudgetTypeEnum.TOTAL_BUDGET:
                setWidgetDataForTotalBudget(scenarioCurrent);
                break;
            case BudgetTypeEnum.FUNDING_TYPE_PER_YEAR:
                setWidgetDataForFundingTypePerYear(scenarioCurrent, fundingTypes);
                break;
            default:
                setDefaultWidgetData(scenarioCurrent, fundingTypes);
        }
    }, [scenarioCurrent, yearFocus, applyEscFactor]);
    useEffect(() => {
        let totalAllocatedBudget = 0;
        allocatedBudgets.forEach((b) => (totalAllocatedBudget += b));
        setTotalAllocated(totalAllocatedBudget);
    }, [allocatedBudgets]); // idk why this didn't work unless it was here
    ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);
    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'right',
            },
            title: {
                display: false,
                text: 'Budget Summary',
            },
        },
        scales: {
            x: {
                ticks: {
                    color: (c) => {
                        if (budgets[c['tick']['value']] < allocatedBudgets[c['tick']['value']]) {
                            return 'red';
                        }
                        else {
                            return 'black';
                        }
                    },
                },
            },
        },
    };
    const hasBudget = budgets.length > 0;
    const createData = () => {
        const datasets = [
            {
                label: 'Allocated',
                data: allocatedBudgets,
                backgroundColor: appColors.SEC_ORANGE,
            },
        ];
        if (hasBudget) {
            datasets.unshift({
                label: 'Budget',
                data: budgets,
                backgroundColor: appColors.PRIM_BLUE,
            });
        }
        return {
            labels,
            datasets,
        };
    };
    const calculateTotalByYear = () => {
        var _a;
        let budgetTotal = 0;
        (_a = scenarioCurrent === null || scenarioCurrent === void 0 ? void 0 : scenarioCurrent.fundingTypeBudgets) === null || _a === void 0 ? void 0 : _a.forEach((fundingType) => {
            if (yearFocus && yearFocus === fundingType.year) {
                const budget = fundingType.budget;
                if (budget) {
                    budgetTotal += parseCurrencyAsFloat(budget.toString());
                }
            }
        });
        return budgetTotal;
    };
    return (_jsxs(VStack, Object.assign({ height: '100%' }, { children: [_jsxs(HStack, Object.assign({ width: '100%', justifyContent: 'space-between' }, { children: [_jsx(HStack, { children: _jsxs(Text, Object.assign({ variant: variants.title, mb: '10px' }, { children: ["Budget Summary", ' ', scenarioCurrent.budgetType ===
                                    BudgetTypeEnum.FUNDING_TYPE_PER_YEAR &&
                                    yearFocus !== undefined &&
                                    `for year: ${yearFocus}`] })) }), _jsxs(HStack, Object.assign({ right: '0' }, { children: [scenarioCurrent.escalationFactor && !scenarioCurrent.isLocked && (_jsx(Checkbox, Object.assign({ isChecked: applyEscFactor, onChange: () => dispatch(setSelectedApplyEscFactor(!applyEscFactor)), iconColor: appColors.SEC_ORANGE, fontSize: 14, color: appColors.PRIM_BLUE, fontWeight: 300 }, { children: "Apply escalation factor" }))), hasBudget && (_jsxs(Text, Object.assign({ bgColor: appColors.PRIM_BLUE, color: 'white', p: '5px', fontSize: '13px' }, { children: [yearFocus &&
                                        scenarioCurrent.budgetType ===
                                            BudgetTypeEnum.FUNDING_TYPE_PER_YEAR
                                        ? yearFocus
                                        : 'Scenario', ' ', "Budget $", scenarioCurrent.budgetType === 'fundingTypeBudgetPerYear' &&
                                        yearFocus
                                        ? `${new Intl.NumberFormat().format(calculateTotalByYear())}`
                                        : `${new Intl.NumberFormat().format(scenarioCurrent.totalBudget)}`] }))), _jsxs(Text, Object.assign({ bgColor: appColors.SEC_ORANGE, p: '5px', fontSize: '13px' }, { children: [yearFocus &&
                                        scenarioCurrent.budgetType === BudgetTypeEnum.FUNDING_TYPE_PER_YEAR
                                        ? yearFocus
                                        : 'Scenario', ' ', "Allocated $", `${new Intl.NumberFormat().format(totalAllocated)}`] }))] }))] })), _jsx(HStack, Object.assign({ height: '100%', width: '100%', justifyContent: 'center', pl: '15px' }, { children: _jsx(Bar, { options: options, data: createData(), height: '45%' }) }))] })));
};
