import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Flex } from '@chakra-ui/react';
import { useDependencies } from '@frontend/domain/contexts/Dependencies/DependenciesContext';
import { usePlannerSettingsContext } from '@frontend/domain/contexts/Settings/PlannerSettingsContext';
import { convertOrgSummaryToOrganizationSnapshot } from '@frontend/domain/models/Organization/OrganizationConversions';
import { useOrganizationService } from '@frontend/domain/services/OrganizationService';
import { useEffect, useState } from 'react';
import { useAppSelector } from '../../store/hooks';
import { selectUser } from '../../store/userSlice';
import { ExpandableSidebar } from '../navigation/ExpandableSidebar';
import { PageHeader } from '../navigation/PageHeader';
export const PageLayout = ({ title, children, }) => {
    const { platformApi } = useDependencies();
    const { organization } = useOrganizationService({ platformApi });
    const { selectPrioritizationCriteriaForProject, orgFundingTypes, orgProjectTypes, } = usePlannerSettingsContext();
    const userCurrent = useAppSelector(selectUser);
    const [userOrgs, setUserOrgs] = useState([]);
    const prioritizationCriteria = selectPrioritizationCriteriaForProject();
    useEffect(() => {
        organization.fetchList().then((response) => {
            if (response.length) {
                const orgs = convertOrgSummaryToOrganizationSnapshot(response).sort((a, b) => a.name.localeCompare(b.name));
                setUserOrgs(orgs);
            }
        });
    }, [userCurrent]);
    return (_jsx(ExpandableSidebar, { children: _jsxs(_Fragment, { children: [_jsx(PageHeader, { title: title, userOrgs: userOrgs, projectCriteria: prioritizationCriteria, orgFundingTypes: orgFundingTypes, orgProjectTypes: orgProjectTypes }), _jsx(Flex, Object.assign({ flexGrow: 1, flexDir: 'column', py: '20px' }, { children: children }))] }) }));
};
