var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
const baseUrl = '/projects';
export default class ProjectService {
    constructor(api) {
        this.api = api;
    }
    getProject(prid) {
        return __awaiter(this, void 0, void 0, function* () {
            const project = yield this.api.get(`${baseUrl}/${prid}`);
            if (project.assetGroups) {
                project.assetGroups.forEach((assetGroup) => {
                    assetGroup.assetGroupId = assetGroup.assetGroupIdentity;
                });
            }
            return project;
        });
    }
    getProjects(oid) {
        return __awaiter(this, void 0, void 0, function* () {
            const projects = yield this.api.get(`${baseUrl}/org/${oid}`);
            projects.forEach((project) => {
                if (project.assetGroups) {
                    project.assetGroups.forEach((assetGroup) => {
                        assetGroup.assetGroupId = assetGroup.assetGroupIdentity;
                    });
                }
            });
            return projects;
        });
    }
    createProject(project) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.api.post(`${baseUrl}`, project);
        });
    }
    updateProject(prid, project) {
        return __awaiter(this, void 0, void 0, function* () {
            const updatedProject = yield this.api.put(`${baseUrl}/${prid}`, project);
            if (updatedProject.assetGroups) {
                updatedProject.assetGroups.forEach((assetGroup) => {
                    assetGroup.assetGroupId = assetGroup.assetGroupIdentity;
                });
            }
            return updatedProject;
        });
    }
    getCreateProjectData(oid) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.api.get(`${baseUrl}/data/${oid}`);
        });
    }
    addProjectFiles(prIdentity, files) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.api.put(`${baseUrl}/${prIdentity}/files`, files);
        });
    }
    deleteProject(prid) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.api.delete(`${baseUrl}/${prid}`);
        });
    }
    getProjectPhotos(pid, amount) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.api.getWithQueryParams(`${baseUrl}/${pid}/photos`, {}, { amount });
        });
    }
}
