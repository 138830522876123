export var DefaultFilterType;
(function (DefaultFilterType) {
    DefaultFilterType["SINGLE_VALUE"] = "SINGLE_VALUE";
    DefaultFilterType["MULTI_VALUE"] = "MULTI_VALUE";
    DefaultFilterType["MULTI_VALUE_ON_LIST"] = "MULTI_VALUE_ON_LIST";
    DefaultFilterType["RANGE"] = "RANGE";
    DefaultFilterType["BOOLEAN"] = "BOOLEAN";
})(DefaultFilterType || (DefaultFilterType = {}));
const singleValueFilter = (filterObj, key) => {
    return (obj) => filterObj[key] === obj[key];
};
export const multiValueFilter = (filterObj, key) => {
    return (obj) => {
        if (filterObj[key]) {
            const filterItem = filterObj[key];
            if (filterItem.length > 0) {
                const valuesToFilterAgainst = filterObj[key].map((opt) => opt.value);
                return valuesToFilterAgainst.includes(obj[key]);
            }
            else {
                return true;
            }
        }
        return false;
    };
};
const multiValueFilterOnList = (filterObj, key) => {
    return (obj) => {
        if (filterObj[key]) {
            const filterItem = filterObj[key];
            if (filterItem.length > 0) {
                const valuesToFilterAgainst = filterItem.map((opt) => opt.value.toString());
                return valuesToFilterAgainst.some((filterValue) => obj[key].includes(filterValue));
            }
            else {
                return true;
            }
        }
        return false;
    };
};
const booleanFilter = (_filterObj, // not used for boolean filtering
key) => {
    return (obj) => obj[key];
};
const rangeFilter = (filterObj, key) => {
    return (obj) => {
        if (filterObj[key]) {
            const range = filterObj[key];
            return ((range.min === undefined ||
                obj[key] >= range.min) &&
                (range.max === undefined || obj[key] <= range.max));
        }
        else {
            return false;
        }
    };
};
class FilterMap {
    constructor(filterConfig) {
        this.defaultFilterConfig = {
            [DefaultFilterType.SINGLE_VALUE]: singleValueFilter,
            [DefaultFilterType.MULTI_VALUE]: multiValueFilter,
            [DefaultFilterType.MULTI_VALUE_ON_LIST]: multiValueFilterOnList,
            [DefaultFilterType.BOOLEAN]: booleanFilter,
            [DefaultFilterType.RANGE]: rangeFilter,
        };
        this.filterMap = new Map();
        this.filterTypeConfig = Object.assign(Object.assign({}, this.defaultFilterConfig), filterConfig);
    }
    set(key, type) {
        this.filterMap.set(key, (filterObj) => this.filterTypeConfig[type](filterObj, key));
    }
    setCustom(key, filter) {
        this.filterMap.set(key, (filterObj) => filter(filterObj, key));
    }
    get(key) {
        return this.filterMap.get(key);
    }
}
export default FilterMap;
