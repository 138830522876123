import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Input, Select, Text } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useController } from 'react-hook-form';
import { CurrencyInput } from '../../../components/forms/CurrencyInput';
import { appColors } from '../../../config/constants';
import { getCurrencyValue } from '../../../config/CPHelperFunctions';
const ANNUAL_HOME_VALUE_KEY = 'annualHomeValue';
const ANNUAL_TAX_IMPACT_KEY = 'annualTaxImpact';
const MONTHLY_HOME_VALUE_KEY = 'monthlyHomeValue';
const MONTHLY_TAX_IMPACT_KEY = 'monthlyTaxImpact';
const ACRE_VALUE_KEY = 'acreValue';
const ANNUAL_ACRE_VALUE_TAX_IMPACT_KEY = 'annualAcreValueTaxImpact';
export const TaxImpactInputs = ({ clickSave, register, watch, control, taxImpact, annualValidation, setAnnualValidation, monthlyValidation, setMonthlyValidation, acreValidation, setAcreValidation, }) => {
    useEffect(() => {
        if (!watch('annualHomeValue') || !watch('annualTaxImpact')) {
            setAnnualValidation(true);
        }
        else {
            setAnnualValidation(false);
        }
    }, [watch('annualHomeValue'), watch('annualTaxImpact')]);
    useEffect(() => {
        if (!watch('monthlyHomeValue') || !watch('monthlyTaxImpact')) {
            setMonthlyValidation(true);
        }
        else {
            setMonthlyValidation(false);
        }
    }, [watch('monthlyHomeValue'), watch('monthlyTaxImpact')]);
    useEffect(() => {
        if (!watch('acreValue') || !watch('annualAcreValueTaxImpact')) {
            setAcreValidation(true);
        }
        else {
            setAcreValidation(false);
        }
    }, [watch('acreValue'), watch('annualAcreValueTaxImpact')]);
    const annualHomeValueRegister = register(ANNUAL_HOME_VALUE_KEY, {
        required: true,
    });
    const monthlyHomeValueRegister = register(MONTHLY_HOME_VALUE_KEY, {
        required: true,
    });
    const acreValueRegister = register(ACRE_VALUE_KEY, { required: true });
    const annualAcreValueController = useController({
        name: ANNUAL_ACRE_VALUE_TAX_IMPACT_KEY,
        control,
        rules: { required: true },
    });
    const annualTaxImpactController = useController({
        name: ANNUAL_TAX_IMPACT_KEY,
        control,
        rules: { required: true },
    });
    const monthlyTaxImpactController = useController({
        name: MONTHLY_TAX_IMPACT_KEY,
        control,
        rules: { required: true },
    });
    const homeValueOptions = [];
    for (let i = 100; i <= 400; i += 25) {
        homeValueOptions.push(_jsxs("option", Object.assign({ value: i }, { children: [i, "K"] }), i));
    }
    const renderAnnualTaxImpact = () => {
        if (taxImpact) {
            return (_jsxs(Box, Object.assign({ mb: '15px' }, { children: [_jsxs(Box, Object.assign({ display: 'flex', alignItems: 'center', textColor: appColors.PRIM_BLUE, fontWeight: '400', fontSize: '14px', pl: '18px' }, { children: ["Annual tax impact on ", taxImpact.annualHomeValue, "K home"] })), _jsx(Box, Object.assign({ pl: '15px' }, { children: getCurrencyValue(taxImpact.annualTaxImpact) }))] })));
        }
        else {
            return (_jsxs(Box, Object.assign({ mb: '15px' }, { children: [_jsxs(Box, Object.assign({ display: 'flex', alignItems: 'center', textColor: appColors.PRIM_BLUE, fontWeight: '400', fontSize: '14px', pl: '18px' }, { children: [_jsx(Box, { children: "Annual tax impact on" }), _jsx(Box, Object.assign({ ml: '5px', mr: '5px' }, { children: _jsx(Select, Object.assign({ h: '25px', w: '100px', placeholder: 'Select' }, annualHomeValueRegister, { children: homeValueOptions })) })), _jsxs(Box, Object.assign({ display: 'flex' }, { children: ["home ", _jsx("p", Object.assign({ className: 'required-label' }, { children: '*' }))] }))] })), _jsx(CurrencyInput, { label: '', controller: annualTaxImpactController, placeholder: 'Type here...' }), clickSave && annualValidation && (_jsx(Text, Object.assign({ color: 'red', fontSize: '13px' }, { children: "Please enter Tax Impact" })))] })));
        }
    };
    const renderMonthlyTaxImpact = () => {
        if (taxImpact) {
            return (_jsxs(Box, Object.assign({ mb: '15px' }, { children: [_jsxs(Box, Object.assign({ display: 'flex', alignItems: 'center', textColor: appColors.PRIM_BLUE, fontWeight: '400', fontSize: '14px', pl: '18px' }, { children: ["Monthly tax impact on ", taxImpact.monthlyHomeValue, "K home"] })), _jsx(Box, Object.assign({ pl: '15px' }, { children: getCurrencyValue(taxImpact.monthlyTaxImpact) }))] })));
        }
        else {
            return (_jsxs(Box, Object.assign({ mb: '15px' }, { children: [_jsxs(Box, Object.assign({ display: 'flex', alignItems: 'center', textColor: appColors.PRIM_BLUE, fontWeight: '400', fontSize: '14px', pl: '18px' }, { children: ["Monthly tax impact on", _jsx(Box, Object.assign({ ml: '5px', mr: '5px' }, { children: _jsx(Select, Object.assign({ h: '25px', w: '100px', placeholder: 'Select' }, monthlyHomeValueRegister, { children: homeValueOptions })) })), "home ", _jsx("p", Object.assign({ className: 'required-label' }, { children: '*' }))] })), _jsx(CurrencyInput, { label: '', controller: monthlyTaxImpactController, placeholder: 'Type here...' }), clickSave && monthlyValidation && (_jsx(Text, Object.assign({ color: 'red', fontSize: '13px' }, { children: "Please enter Tax Impact" })))] })));
        }
    };
    const renderAcreValue = () => {
        if (taxImpact) {
            return (_jsxs(Box, Object.assign({ alignItems: 'center', textColor: appColors.PRIM_BLUE, fontWeight: '400', fontSize: '14px', pl: '18px' }, { children: [_jsxs(Box, { children: ["Annual tax impact on ag non-homestead acre valued at", ' ', taxImpact.acreValue, " $/acre"] }), _jsx(Box, { children: getCurrencyValue(taxImpact.annualAcreValueTaxImpact) })] })));
        }
        else {
            return (_jsxs(_Fragment, { children: [_jsxs(Box, Object.assign({ alignItems: 'center', textColor: appColors.PRIM_BLUE, fontWeight: '400', fontSize: '14px', pl: '18px' }, { children: [_jsx(Box, { children: "Annual tax impact on ag non-homestead" }), _jsxs(Box, Object.assign({ display: 'flex' }, { children: [_jsx(Box, { children: "acre valued at" }), _jsx(Box, Object.assign({ ml: '5px', mr: '5px' }, { children: _jsx(Input, Object.assign({ h: '25px', w: '100px', defaultValue: '', type: 'number', placeholder: '', maxLength: 30 }, acreValueRegister)) })), "$/acre ", _jsx("p", Object.assign({ className: 'required-label' }, { children: '*' }))] }))] })), _jsx(CurrencyInput, { label: '', controller: annualAcreValueController, placeholder: 'Type here...' }), clickSave && acreValidation && (_jsx(Text, Object.assign({ color: 'red', fontSize: '13px' }, { children: "Please enter Tax Impact" })))] }));
        }
    };
    return (_jsxs(Box, { children: [renderAnnualTaxImpact(), renderMonthlyTaxImpact(), renderAcreValue()] }));
};
