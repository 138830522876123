import { Apps } from '../../objects/CPBaseInterfaces';
import { OrganizationRole, SystemRole } from '../../objects/UserData';
import { FeatureFlags, useFeatureFlag } from '../../store/featureFlag';
import { useAppSelector } from '../../store/hooks';
import { selectOrgApps, selectSelectedOrg } from '../../store/orgSlice';
import { selectUser } from '../../store/userSlice';
export const useAppAccess = (user, orgSnapshot, orgApps) => {
    const piqEnabled = useFeatureFlag(FeatureFlags.FF_PIQ_ACCESS_CONTROL);
    const hasAccess = (maybe) => {
        var _a, _b, _c, _d;
        if (!user) {
            return false;
        }
        if (!orgApps) {
            return false;
        }
        if (orgApps === null || orgApps === void 0 ? void 0 : orgApps.some((app) => app.app === maybe)) {
            if (user.systemRole === SystemRole.SUPER_ADMIN) {
                return true;
            }
            if (user.systemRole === SystemRole.ORG_APPROVER) {
                return true;
            }
            if ((_a = user.organizationRoles) === null || _a === void 0 ? void 0 : _a.some((role) => role.organizationIdentity === (orgSnapshot === null || orgSnapshot === void 0 ? void 0 : orgSnapshot.identity) &&
                role.role === OrganizationRole.LOCAL_ADMIN)) {
                return true;
            }
            if ((_b = user.organizationRoles) === null || _b === void 0 ? void 0 : _b.some((role) => role.organizationIdentity === (orgSnapshot === null || orgSnapshot === void 0 ? void 0 : orgSnapshot.identity) &&
                role.role === OrganizationRole.CLIENT_CONCIERGE)) {
                return true;
            }
            return ((_d = (_c = user.apps) === null || _c === void 0 ? void 0 : _c.some((app) => app.app === maybe &&
                app.organizationIdentity === (orgSnapshot === null || orgSnapshot === void 0 ? void 0 : orgSnapshot.identity))) !== null && _d !== void 0 ? _d : false);
        }
        return false;
    };
    return {
        [Apps.REPORTS]: hasAccess(Apps.REPORTS),
        [Apps.FCA]: hasAccess(Apps.FCA),
        [Apps.PLANNER]: hasAccess(Apps.PLANNER),
        [Apps.PIQ]: piqEnabled && hasAccess(Apps.PIQ),
    };
};
export const useCurrentUserAppAccess = () => {
    const user = useAppSelector(selectUser);
    const orgSnapshot = useAppSelector(selectSelectedOrg);
    const orgApps = useAppSelector(selectOrgApps);
    return useAppAccess(user, orgSnapshot, orgApps);
};
