import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, HStack, Text } from '@chakra-ui/react';
import { IoMdDownload } from 'react-icons/io';
import { calculateBudget } from '../../../config/CPHelperFunctions';
import { toCsvString } from '../../../utils/fileUtils';
import { canExportProjectCsv } from '../../../utils/userRoleUtils';
export const DownloadTaxImpactCsv = ({ user, orgIdentity, orgName, scenario, escalationFactor, }) => {
    const canViewExport = canExportProjectCsv(user, orgIdentity);
    const downloadScenarioCsvFile = (scen, orgName, escalationFactor) => {
        const csv = createScenarioCsv(scen, orgName, escalationFactor);
        const hiddenElement = document.createElement('a');
        hiddenElement.href =
            'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
        hiddenElement.target = '_blank';
        hiddenElement.download = `${orgName}_${scenario.name}.csv`;
        hiddenElement.click();
    };
    if (canViewExport) {
        return (_jsxs(HStack, { children: [_jsx(Text, { children: "For more reference, you can download a CSV with all the projects\u2019 information." }), _jsx(Button, Object.assign({ variant: 'outline', leftIcon: _jsx(IoMdDownload, {}), onClick: () => downloadScenarioCsvFile(scenario, orgName, escalationFactor) }, { children: "Download CSV" }))] }));
    }
    else {
        return _jsx(_Fragment, {});
    }
};
export const createScenarioCsv = (scen, orgName, escalationFactor) => {
    let csv = 'Organization,Project,Project Year,Project Type,Budget,Escalated Budget,Budget Date,Funding Type,Facility,Scope,Ideas\n';
    const csvFileData = [];
    scen.assignedProjects &&
        scen.assignedProjects.forEach((scenProj) => {
            const proj = scenProj.project;
            csvFileData.push([
                toCsvString(orgName),
                toCsvString(proj.name),
                scenProj.year,
                proj.projType ? toCsvString(proj.projType.name) : '',
                proj.estBudget,
                escalationFactor
                    ? proj.estBudget
                        ? Math.round(calculateBudget(scenProj, scen, escalationFactor))
                        : ''
                    : proj.estBudget,
                proj.quoteDate,
                proj.fundingType ? toCsvString(proj.fundingType.name) : '',
                proj.facility ? toCsvString(proj.facility.name) : '',
                toCsvString(proj.projScope),
                toCsvString(proj.ideasOpportunities),
            ]);
        });
    csvFileData.forEach(function (row) {
        csv += row.join(',');
        csv += '\n';
    });
    return csv;
};
