import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Flex, HStack, Text } from '@chakra-ui/react';
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js';
import { useEffect, useState } from 'react';
import { Pie } from 'react-chartjs-2';
import { getNumByAmount } from '../../../config/CPHelperFunctions';
import { variants } from '../../../config/theme';
import { ProjectFieldEnum } from '../../../objects/CPBaseInterfaces';
import { calcTotals } from '../../../services/api/scenarioAPI';
export const BudgetPerFacilityWidget = ({ scenarioCurrent, applyEscFactor, escalationFactor, }) => {
    const [facilityBudgets, setFacilityBudgets] = useState([]);
    useEffect(() => {
        if (scenarioCurrent.assignedProjects) {
            let budgetsByFacility = [];
            let assignedProjectsTemp = [];
            assignedProjectsTemp = scenarioCurrent.assignedProjects;
            if (applyEscFactor) {
                budgetsByFacility = calcTotals(assignedProjectsTemp, ProjectFieldEnum.facility, 'facilityIdentity', {
                    escalationFactor: escalationFactor,
                    scenarioCurrent: scenarioCurrent,
                });
            }
            else {
                budgetsByFacility = calcTotals(assignedProjectsTemp, ProjectFieldEnum.facility, 'facilityIdentity');
            }
            let budgetTemp = 0;
            budgetsByFacility.map((facility) => (budgetTemp = budgetTemp + facility.budget));
            budgetsByFacility = budgetsByFacility.map((budgetByFacility) => {
                return {
                    id: budgetByFacility.id,
                    name: budgetByFacility.name,
                    budget: budgetByFacility.budget,
                    percentage: +((budgetByFacility.budget / budgetTemp) * 100).toFixed(2),
                    squareFootage: budgetByFacility.squareFootage,
                };
            });
            setFacilityBudgets(budgetsByFacility);
        }
    }, [scenarioCurrent, scenarioCurrent.assignedProjects, applyEscFactor]);
    const getOrCreateLegendList = (chart, id) => {
        const legendContainer = document.getElementById(id);
        let listContainer = legendContainer === null || legendContainer === void 0 ? void 0 : legendContainer.querySelector('ul');
        if (!listContainer) {
            listContainer = document.createElement('ul');
            listContainer.style.height = 'inherit';
            listContainer.style.display = 'flex';
            listContainer.style.flexDirection = 'column';
            listContainer.style.flexWrap = 'wrap';
            listContainer.style.overflow = 'auto';
            listContainer.style.marginLeft = '50px';
            listContainer.style.padding = '0';
            legendContainer === null || legendContainer === void 0 ? void 0 : legendContainer.appendChild(listContainer);
        }
        return listContainer;
    };
    const htmlLegendPlugin = {
        id: 'htmlLegend',
        afterUpdate(chart, args, options) {
            const ul = getOrCreateLegendList(chart, options.containerID);
            // Remove old legend items
            while (ul.firstChild) {
                ul.firstChild.remove();
            }
            // Reuse the built-in legendItems generator
            const items = chart.options.plugins.legend.labels.generateLabels(chart);
            items.forEach((item) => {
                const li = document.createElement('li');
                li.style.cursor = 'pointer';
                li.style.display = 'flex';
                li.style.flexDirection = 'row';
                li.style.marginLeft = '10px';
                li.onclick = () => {
                    const { type } = chart.config;
                    if (type === 'pie' || type === 'doughnut') {
                        // Pie and doughnut charts only have a single dataset and visibility is per item
                        chart.toggleDataVisibility(item.index);
                    }
                    else {
                        chart.setDatasetVisibility(item.datasetIndex, !chart.isDatasetVisible(item.datasetIndex));
                    }
                    chart.update();
                };
                // Color box
                const boxSpan = document.createElement('span');
                boxSpan.style.background = item.fillStyle;
                boxSpan.style.borderColor = item.strokeStyle;
                boxSpan.style.borderWidth = item.lineWidth + 'px';
                boxSpan.style.display = 'inline-block';
                boxSpan.style.height = '20px';
                boxSpan.style.marginRight = '10px';
                boxSpan.style.width = '20px';
                // Text
                const textContainer = document.createElement('p');
                textContainer.style.color = item.fontColor;
                textContainer.style.margin = '0';
                textContainer.style.padding = '0';
                textContainer.style.width = '10vw';
                textContainer.style.whiteSpace = 'wrap';
                textContainer.style.textDecoration = item.hidden ? 'line-through' : '';
                const text = document.createTextNode(item.text);
                textContainer.appendChild(text);
                li.appendChild(boxSpan);
                li.appendChild(textContainer);
                ul.appendChild(li);
            });
        },
    };
    ChartJS.register(ArcElement, Tooltip, Legend);
    const getOrCreateTooltip = (chart) => {
        let tooltipEl = chart.canvas.parentNode.querySelector('div');
        if (!tooltipEl) {
            tooltipEl = document.createElement('div');
            tooltipEl.style.background = 'rgba(0, 0, 0, 0.7)';
            tooltipEl.style.borderRadius = '3px';
            tooltipEl.style.color = 'white';
            tooltipEl.style.opacity = 1;
            tooltipEl.style.pointerEvents = 'none';
            tooltipEl.style.position = 'absolute';
            tooltipEl.style.transition = 'all .1s ease';
            const table = document.createElement('table');
            table.style.margin = '0px';
            tooltipEl.appendChild(table);
            chart.canvas.parentNode.appendChild(tooltipEl);
        }
        return tooltipEl;
    };
    const addBudgetPerSF = (title) => {
        let budgetPerSF = 0;
        facilityBudgets.forEach((facility) => {
            if (facility.name === title) {
                if (facility.squareFootage) {
                    budgetPerSF = +(facility.budget / facility.squareFootage).toFixed();
                }
            }
        });
        return `Budget per sq/ft: $${budgetPerSF}`;
    };
    const addBudget = (title) => {
        let budget = 0;
        facilityBudgets.forEach((facility) => {
            if (facility.name === title) {
                budget = facility.budget;
            }
        });
        return `Budget: ${getNumByAmount(budget)}`;
    };
    const externalTooltipHandler = (context) => {
        // Tooltip Element
        const { chart, tooltip } = context;
        const tooltipEl = getOrCreateTooltip(chart);
        // Hide if no tooltip
        if (tooltip.opacity === 0) {
            tooltipEl.style.opacity = 0;
            return;
        }
        // Set Text
        if (tooltip.body) {
            const titleLines = tooltip.title || [];
            const bodyLines = tooltip.body.map((b) => b.lines);
            bodyLines[0].push(addBudget(titleLines[0]), addBudgetPerSF(titleLines[0]));
            const tableHead = document.createElement('thead');
            titleLines.forEach((title, i) => {
                const colors = tooltip.labelColors[i];
                const span = document.createElement('span');
                span.style.background = colors.backgroundColor;
                span.style.borderColor = colors.borderColor;
                span.style.borderWidth = '1px';
                span.style.marginRight = '10px';
                span.style.height = '15px';
                span.style.width = '15px';
                span.style.display = 'inline-block';
                const tr = document.createElement('tr');
                tr.style.borderWidth = '0';
                const th = document.createElement('th');
                th.style.borderWidth = '0';
                const text = document.createTextNode(title);
                th.appendChild(span);
                th.appendChild(text);
                tr.appendChild(th);
                tableHead.appendChild(tr);
            });
            const tableBody = document.createElement('tbody');
            bodyLines.forEach((body) => {
                const tr = document.createElement('tr');
                tr.style.backgroundColor = 'inherit';
                tr.style.borderWidth = '0';
                const td = document.createElement('td');
                td.style.borderWidth = '0';
                const text = document.createTextNode(body[0]);
                td.appendChild(text);
                tr.appendChild(td);
                tableBody.appendChild(tr);
                const tr2 = document.createElement('tr');
                tr2.style.backgroundColor = 'inherit';
                tr2.style.borderWidth = '0';
                const td2 = document.createElement('td');
                td2.style.borderWidth = '0';
                const text2 = document.createTextNode(body[1]);
                td2.appendChild(text2);
                tr2.appendChild(td2);
                tableBody.appendChild(tr2);
                const tr3 = document.createElement('tr');
                tr3.style.backgroundColor = 'inherit';
                tr3.style.borderWidth = '0';
                const td3 = document.createElement('td');
                td3.style.borderWidth = '0';
                const text3 = document.createTextNode(body[2]);
                td3.appendChild(text3);
                tr3.appendChild(td3);
                tableBody.appendChild(tr3);
            });
            const tableRoot = tooltipEl.querySelector('table');
            // Remove old children
            while (tableRoot.firstChild) {
                tableRoot.firstChild.remove();
            }
            // Add new children
            tableRoot.appendChild(tableHead);
            tableRoot.appendChild(tableBody);
        }
        // Display, position, and set styles for font
        tooltipEl.style.opacity = 1;
        tooltipEl.style.left = 0;
        tooltipEl.style.top = '50%';
        tooltipEl.style.font = tooltip.options.bodyFont.string;
        tooltipEl.style.padding =
            tooltip.options.padding + 'px ' + tooltip.options.padding + 'px';
    };
    const options = {
        responsive: true,
        plugins: {
            htmlLegend: {
                // ID of the container to put the legend in
                containerID: 'legend-container',
            },
            legend: {
                display: false,
            },
            tooltip: {
                enabled: false,
                external: externalTooltipHandler,
            },
        },
    };
    const data = {
        labels: facilityBudgets.length === 0
            ? ['Drag and drop projects into a year']
            : facilityBudgets.map((facility) => `${facility.name}`),
        datasets: [
            {
                label: 'Percentage %',
                data: facilityBudgets.length === 0
                    ? [100]
                    : facilityBudgets.map((facility) => facility.percentage),
                backgroundColor: facilityBudgets.length === 0
                    ? ['white']
                    : [
                        '#003B5D',
                        '#F6862A',
                        '#FEDB5E',
                        '#2E3532',
                        '#D9D9D9',
                        '#EF4444',
                        '#22C55E',
                        '#FBDABD',
                        '#3B82F6',
                        '#FEF4CE',
                        '#5B21B6',
                        '#84CC16',
                    ],
                borderColor: facilityBudgets.length === 0 ? 'black' : 'white',
                borderWidth: 1,
            },
        ],
    };
    return (_jsxs(Box, Object.assign({ h: '35vh' }, { children: [_jsx(HStack, Object.assign({ width: '100%', height: '20%' }, { children: _jsx(Text, Object.assign({ variant: variants.title, mb: '10px' }, { children: "Total budget per facility" })) })), _jsxs(Flex, Object.assign({ alignItems: 'center', justifyContent: 'center', width: '100%', height: '80%' }, { children: [_jsx(Box, Object.assign({ height: '100%' }, { children: _jsx(Pie, { options: options, data: data, plugins: [htmlLegendPlugin] }) })), _jsx(Box, { id: 'legend-container', height: '100%' })] }))] })));
};
