import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Flex, Grid, HStack, Text, useToken } from '@chakra-ui/react';
import { LifeRemainingCircle } from '../../../components/dataDisplay/LifeRemainingCircle';
import { PriorityCircle } from '../../../components/dataDisplay/PriorityCircle';
import { getPriorityInfo } from '../../../config/CPHelperFunctions';
import AssetImages from './AssetImages';
export const ProjectAssetCard = ({ asset, showAgeAdjustedCondition, loadImages, canSelect = false, isSelected = false, onSelect, }) => {
    const [orange] = useToken('colors', ['orange.500']);
    const condition = showAgeAdjustedCondition
        ? asset.ageAdjustedCondition
        : asset.condition;
    const renderCard = () => {
        return (_jsxs(Grid, Object.assign({ boxShadow: `0px 0px 2px 0px ${isSelected ? orange : '#00000040'}`, templateColumns: '2fr 1fr 1fr', bg: isSelected ? `${orange}10` : 'white', gap: 4, p: 4, borderRadius: 'md', w: '98%', alignSelf: 'center', _hover: { cursor: canSelect ? 'pointer' : 'not-allowed' }, onClick: canSelect ? () => onSelect === null || onSelect === void 0 ? void 0 : onSelect(asset) : undefined, "data-testid": `asset-item-${asset.identity}` }, { children: [_jsxs(HStack, Object.assign({ overflow: 'hidden' }, { children: [_jsx(Box, Object.assign({ _hover: { cursor: 'default' }, onClick: (e) => {
                                e.stopPropagation();
                            } }, { children: _jsx(AssetImages, { loadImages: loadImages, asset: asset }) })), _jsx(Text, Object.assign({ fontSize: 'xs', isTruncated: true, fontWeight: 'bold', textAlign: 'left' }, { children: asset.name }))] })), _jsx(Flex, Object.assign({ justifyContent: 'center' }, { children: _jsx(LifeRemainingCircle, { years: asset.projectedLifeRemaining }) })), _jsx(Flex, Object.assign({ justifyContent: 'center' }, { children: condition ? (_jsx(PriorityCircle, { borderColor: getPriorityInfo(condition).color, value: condition.toString() })) : (_jsx(Text, { children: "Unknown" })) }))] })));
    };
    return _jsx(_Fragment, { children: renderCard() });
};
