var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { CommentIcon, FavoriteIcon } from '../../config/icons';
import { getPhoto } from '../../services/api/photoAPI';
import { BLOB_STORE_CONTAINER_URL } from '../../utils/fileUtils';
import { PhotoThumbnail } from './PhotoThumbnail';
import { PhotoWidgetsOverlay } from './PhotoWidgetsOverlay';
// TODO: integrate with component in design system once cp is moved
export const ThumbnailItem = ({ photo, maxH, borderRadius, }) => {
    const [photoComments, setPhotoComments] = useState(photo.comments);
    const photoUrl = toBlobStoreContainerUrl(photo.name);
    useEffect(() => {
        // TODO: remove when backfill classification endpoint
        //       photo prop should already have this info if using the proper endpoint
        const tempPhoto = () => __awaiter(void 0, void 0, void 0, function* () {
            const temp = yield getPhoto(photo.id);
            setPhotoComments(temp.comments);
        });
        tempPhoto();
    }, [photo]);
    const rightOverlayItems = (_jsxs(_Fragment, { children: [_jsx(FavoriteIcon, { filled: photo.favorite, _hover: { cursor: 'pointer' } }), (photoComments === null || photoComments === void 0 ? void 0 : photoComments.length) > 0 && (_jsx(CommentIcon, { boxSize: '30px', _hover: { cursor: 'pointer' }, commentsnum: photoComments.length }))] }));
    return (_jsx(PhotoWidgetsOverlay, Object.assign({ rightOverlayItems: rightOverlayItems }, { children: _jsx(PhotoThumbnail, { imageUrl: photoUrl, altText: photo.name, maxH: maxH, borderRadius: borderRadius }) })));
};
export const toBlobStoreContainerUrl = (fileName, orgIdentity) => {
    const resultUrl = `${BLOB_STORE_CONTAINER_URL}/${orgIdentity ? orgIdentity + '/' : ''}${fileName}`;
    return resultUrl;
};
