export const convertPlanAssetDetailResponseToPlanAssetDetail = (response) => {
    return {
        assetCount: response.assetCount,
        agedAssetCount: response.agedAssetCount,
        alarmAlertAssetCount: response.alarmAlertAssetCount,
        alarmAssetsNoProjectsCount: response.alarmAssetsNoProjectsCount,
        alertAssetsNoProjectsCount: response.alertAssetsNoProjectsCount,
        assets: convertPlanAssetResponseToIPlanAsset(response.assets),
    };
};
export const convertPlanAssetResponseToIPlanAsset = (response) => {
    return response.map((asset) => {
        var _a, _b;
        return {
            assetIdentity: asset.assetId,
            name: asset.name,
            assetCategory: asset.assetCategory,
            assetClass: asset.assetClass,
            assetType: asset.assetType,
            facilityIdentity: asset.facilityId,
            facilityName: asset.facilityName,
            currentCondition: (_a = asset.currentCondition) !== null && _a !== void 0 ? _a : undefined,
            projectedLifeRemaining: (_b = asset.projectedLifeRemaining) !== null && _b !== void 0 ? _b : undefined,
            spaceIdentity: asset.spaceId,
            spaceName: asset.spaceName,
            projects: convertAssetProjectResponseToIPlanProject(asset.projects),
            quantity: asset.quantity,
        };
    });
};
export const convertAssetProjectResponseToIPlanProject = (projectResponse) => {
    return projectResponse.map((project) => {
        return {
            projectIdentity: project.projectId,
            projectName: project.projectName,
            projectTypeName: project.projectTypeName,
            projectScope: project.projectScope,
        };
    });
};
