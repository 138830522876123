export const convertProjectCreateFormToIConfigureProject = (projectForm) => {
    return {
        name: projectForm.name,
        scope: projectForm.scope,
        projectTypeIdentity: parseInt(projectForm.projectTypeIdentity),
        fundingTypeIdentity: parseInt(projectForm.fundingTypeIdentity),
        facilityIdentity: parseInt(projectForm.facilityIdentity),
        estimatedBudget: projectForm.estimatedBudget
            ? parseInt(projectForm.estimatedBudget.replace(/,/g, ''))
            : undefined,
        quoteDate: projectForm.quoteDate,
        ideas: projectForm.currentIssues,
    };
};
export const convertProjectUpdateFormToIConfigureProject = (projectIdentity, facilityIdentity, projectForm) => {
    return {
        identity: projectIdentity,
        name: projectForm.name,
        scope: projectForm.scope,
        projectTypeIdentity: parseInt(projectForm.projectTypeIdentity),
        fundingTypeIdentity: parseInt(projectForm.fundingTypeIdentity),
        facilityIdentity: facilityIdentity,
        estimatedBudget: projectForm.estimatedBudget
            ? parseInt(projectForm.estimatedBudget.replace(/,/g, ''))
            : undefined,
        quoteDate: projectForm.quoteDate,
        ideas: projectForm.currentIssues,
        assetIdentities: projectForm.assets,
        criteriaScores: projectForm.priorities
            ? convertCriteriaScoreUpdateFormToICriteriaScores(projectForm.priorities)
            : undefined,
        supportingFiles: projectForm.files
            ? convertProjectFilesToIConfigureProjectFiles(projectForm.files)
            : undefined,
        cO2eSavings: convertIEnvoromentalSavingsToIConfigureEnviromentalSavings(projectForm.cO2eSavings),
        energySavings: convertIEnvoromentalSavingsToIConfigureEnviromentalSavings(projectForm.energySavings),
        thermsSavings: convertIEnvoromentalSavingsToIConfigureEnviromentalSavings(projectForm.thermsSavings),
        waterSavings: convertIEnvoromentalSavingsToIConfigureEnviromentalSavings(projectForm.waterSavings),
        monetarySavings: projectForm.dollarSavings
            ? convertDollarSavingsToMonetarySavings(projectForm.dollarSavings)
            : [],
        rebates: projectForm.rebates
            ? convertRebatesUpdateFormToIConfigureProjectRebate(projectForm.rebates)
            : undefined,
    };
};
export const convertIProjectDetailsToProjectUpdateForm = (project) => {
    return {
        name: project.name,
        facilityIdentity: project.facilityIdentity.toString(),
        projectTypeIdentity: project.projectType.identity.toString(),
        fundingTypeIdentity: project.fundingType.identity.toString(),
        estimatedBudget: project.estimatedBudget !== undefined
            ? project.estimatedBudget.toString()
            : '',
        quoteDate: project.quoteDate,
        scope: project.scope,
        currentIssues: project.ideas,
        assets: project.assetIdentities,
        priorities: convertICriteriaScoresToUpdatePriority(project.criteriaScores),
        rebates: convertIProjectRebatesToRebateUseForm(project.rebates),
        files: project.supportingFiles,
        cO2eSavings: project.cO2eSavings,
        energySavings: project.energySavings,
        thermsSavings: project.thermsSavings,
        waterSavings: project.waterSavings,
        dollarSavings: project.dollarSavings,
    };
};
export const getDateString = (date) => {
    return date.toISOString().split('T')[0];
};
const convertIProjectRebatesToRebateUseForm = (rebates) => {
    return rebates.map((rebate) => {
        return {
            identity: rebate.identity,
            amount: rebate.amount.toString(),
            program: rebate.rebateProgram,
            expirationDate: getDateString(rebate.expirationDate),
            notes: rebate.notes,
        };
    });
};
const convertICriteriaScoresToUpdatePriority = (criteriaScores) => {
    return criteriaScores.map((criteriaScore) => {
        var _a;
        return ({
            identity: criteriaScore.identity.toString(),
            score: (_a = criteriaScore.score) === null || _a === void 0 ? void 0 : _a.toString(),
        });
    });
};
const convertCriteriaScoreUpdateFormToICriteriaScores = (scores) => {
    const configureCriteriaScores = [];
    for (const criteriaScore of scores) {
        if (criteriaScore.score !== '') {
            configureCriteriaScores.push({
                identity: Number(criteriaScore.identity),
                score: Number(criteriaScore.score),
            });
        }
    }
    return configureCriteriaScores;
};
const convertProjectFilesToIConfigureProjectFiles = (files) => {
    return files.map((file) => ({
        name: file.name,
        file: file.file,
    }));
};
export const convertIProjectDetailsToIProjectSummary = (projectDetails) => {
    return {
        identity: projectDetails.identity,
        name: projectDetails.name,
        facilityIdentity: projectDetails.facilityIdentity,
        facilityName: projectDetails.facilityName,
        fundingTypeName: projectDetails.fundingType.name,
        projectTypeName: projectDetails.projectType.name,
        scope: projectDetails.scope,
        createdOn: projectDetails.createdOn,
        estimatedBudget: projectDetails.estimatedBudget,
        isLocked: projectDetails.isLocked,
        priorityScore: projectDetails.priorityScore,
        priorityRating: projectDetails.priorityRating,
        plannedFundingTypes: getPlannedFundingTypesFromScenarioList(projectDetails.scenarios),
        criteriaScores: getCriteriaScoreSummaryFromProject(projectDetails.criteriaScores),
    };
};
const getPlannedFundingTypesFromScenarioList = (scenarios) => {
    return scenarios.map((scenario) => scenario.scenarioFundingType);
};
const getCriteriaScoreSummaryFromProject = (criteriaScores) => {
    return criteriaScores.map((criteriaScore) => {
        var _a;
        return ({
            name: criteriaScore.name,
            score: (_a = criteriaScore.score) === null || _a === void 0 ? void 0 : _a.toString(),
        });
    });
};
const convertIEnvoromentalSavingsToIConfigureEnviromentalSavings = (enviromentalSavings) => {
    return {
        amount: (enviromentalSavings === null || enviromentalSavings === void 0 ? void 0 : enviromentalSavings.amount) === undefined ||
            (enviromentalSavings === null || enviromentalSavings === void 0 ? void 0 : enviromentalSavings.amount) === ''
            ? undefined
            : parseInt(enviromentalSavings === null || enviromentalSavings === void 0 ? void 0 : enviromentalSavings.amount.replace(/,/g, '')),
        numberOfYears: (enviromentalSavings === null || enviromentalSavings === void 0 ? void 0 : enviromentalSavings.numberOfYears) === undefined ||
            (enviromentalSavings === null || enviromentalSavings === void 0 ? void 0 : enviromentalSavings.numberOfYears) === ''
            ? undefined
            : parseInt(enviromentalSavings === null || enviromentalSavings === void 0 ? void 0 : enviromentalSavings.numberOfYears.replace(/,/g, '')),
    };
};
const convertDollarSavingsToMonetarySavings = (dollarSavings) => {
    return dollarSavings.map((dollarSaving) => ({
        savingsId: dollarSaving.id,
        savingsType: dollarSaving.type ? dollarSaving.type : '',
        savingsAmount: dollarSaving.saving
            ? parseInt(dollarSaving.saving.replace(/,/g, ''))
            : 0,
        numberOfYears: dollarSaving.duration
            ? parseInt(dollarSaving.duration.replace(/,/g, ''))
            : 0,
        notes: dollarSaving.notes,
    }));
};
const convertRebatesUpdateFormToIConfigureProjectRebate = (rebates) => {
    return rebates.map((rebate) => ({
        identity: rebate.identity,
        amount: parseInt(rebate.amount.replace(/,/g, '')),
        rebateProgram: rebate.program,
        expirationDate: new Date(rebate.expirationDate),
        notes: rebate.notes,
    }));
};
