import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FilterInput, FilterInputType, } from '@frontend/design-system/components/Filter/FilterInput';
import { Form } from '@frontend/design-system/components/FormModal/FormModal';
import { validateRangeValue, } from '@frontend/design-system/components/Inputs/RangeInput';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { uniqueFieldValuesFromObjectList } from '../projects/ProjectFilterModal';
// TODO: this should be placed somewhere reusable (taken from FCA’s utility/ConditionArray)
export const conditions = [
    {
        identity: 1,
        name: '1 (Excellent)',
    },
    {
        identity: 2,
        name: '2 (Acceptable)',
    },
    {
        identity: 3,
        name: '3 (Caution)',
    },
    {
        identity: 4,
        name: '4 (Alert)',
    },
    {
        identity: 5,
        name: '5 (Alarm)',
    },
];
export const PlanAssetFilterModal = ({ allAssets, disclosure, filterContext, }) => {
    const { filter, applyFilter, initialFilter: emptyFilter } = filterContext;
    const { handleSubmit, control, reset, formState: { isValid }, } = useForm({
        values: filter,
        mode: 'onChange',
    });
    // resets modal fields to current filter
    useEffect(() => {
        if (disclosure.isOpen) {
            reset(filter);
        }
    }, [disclosure.isOpen]);
    return (_jsxs(Form.Modal, Object.assign({ disclosure: disclosure, onApply: handleSubmit(applyFilter), onClear: () => {
            reset(emptyFilter);
        }, labels: {
            title: 'Filters',
            cancel: 'Clear All',
            apply: 'Apply Filters',
        }, applyDisabled: !isValid }, { children: [_jsxs(Form.Column, Object.assign({ stackProps: { flexBasis: 1 } }, { children: [_jsx(FilterInput, { inputConfig: {
                            label: 'Facility',
                            type: FilterInputType.MULTI_SELECT,
                            formField: 'facilityName',
                            options: uniqueFieldValuesFromObjectList(allAssets, (asset) => asset.facilityName).map((item) => ({ label: item, value: item })),
                        }, control: control }), _jsx(FilterInput, { inputConfig: {
                            label: 'Asset Type',
                            type: FilterInputType.MULTI_SELECT,
                            formField: 'assetType',
                            options: uniqueFieldValuesFromObjectList(allAssets, (asset) => asset.assetType).map((item) => ({ label: item, value: item })),
                        }, control: control }), _jsx(FilterInput, { inputConfig: {
                            label: 'Condition',
                            type: FilterInputType.MULTI_SELECT,
                            formField: 'currentCondition',
                            options: [
                                { label: 'Unknown', value: 'None' },
                                ...conditions.map((item) => ({
                                    label: item.name,
                                    value: item.identity,
                                })),
                            ],
                        }, control: control }), _jsx(FilterInput, { inputConfig: {
                            label: 'Projected Life Remaining',
                            type: FilterInputType.RANGE,
                            formField: 'projectedLifeRemaining',
                            inputControl: {
                                type: 'number',
                            },
                            validate: (value) => {
                                if (value !== null) {
                                    const range = value;
                                    return validateRangeValue(range);
                                }
                            },
                        }, control: control })] })), _jsxs(Form.Column, Object.assign({ stackProps: { flexBasis: 1 } }, { children: [_jsx(FilterInput, { inputConfig: {
                            label: 'Asset Category',
                            type: FilterInputType.MULTI_SELECT,
                            formField: 'assetCategory',
                            options: uniqueFieldValuesFromObjectList(allAssets, (asset) => asset.assetCategory).map((item) => ({ label: item, value: item })),
                        }, control: control }), _jsx(FilterInput, { inputConfig: {
                            label: 'Space',
                            type: FilterInputType.MULTI_SELECT,
                            formField: 'spaceName',
                            options: uniqueFieldValuesFromObjectList(allAssets, (asset) => asset.spaceName).map((item) => ({ label: item, value: item })),
                        }, control: control }), _jsx(FilterInput, { inputConfig: {
                            label: 'Not Assigned to Project',
                            type: FilterInputType.CHECKBOX,
                            formField: 'projects',
                        }, control: control })] }))] })));
};
