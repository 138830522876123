import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { LockIcon } from '@chakra-ui/icons';
import { Box, HStack, Slider, SliderMark, SliderThumb, SliderTrack, Text, VStack, } from '@chakra-ui/react';
import { CriteriaType } from '@frontend/domain/models/Projects/ProjectsInterfaces';
import React from 'react';
import { appColors, FriendlyPriorityCode } from '../../config/constants';
import { getPriorityInfo } from '../../config/CPHelperFunctions';
import { NeedsAction } from '../alerts/NeedsAction';
import { PriorityCircle } from '../dataDisplay/PriorityCircle';
export const SliderInput = ({ label, type, controller, isInEditMode, isLocked, }) => {
    const { field: { onChange, onBlur, value }, } = controller;
    const isNeedsAction = value === '' || value === undefined;
    const showSliderInput = (type === CriteriaType.Condition && !isLocked && isInEditMode) ||
        (type !== CriteriaType.Condition && isInEditMode);
    const getCleanScore = (value) => {
        if (value === undefined || value === '') {
            return '';
        }
        return value.toString();
    };
    const renderLabel = () => {
        if (type !== CriteriaType.Condition || !isLocked) {
            return (_jsx(Text, Object.assign({ fontSize: 14, color: appColors.PRIM_BLUE }, { children: label })));
        }
        else {
            return (_jsxs(HStack, { children: [_jsx(Text, Object.assign({ fontSize: 14, color: appColors.PRIM_BLUE }, { children: label })), _jsx(LockIcon, { "data-testid": 'priority-score-lock-icon', ml: '20px', color: appColors.PRIM_BLUE })] }));
        }
    };
    return (_jsxs(HStack, Object.assign({ w: '100%', justifyContent: 'space-between', alignItems: 'center', height: showSliderInput ? '40px' : 'unset' }, { children: [_jsx(Box, Object.assign({ width: 'fit-content' }, { children: renderLabel() })), _jsxs(Box, { children: [_jsx(Box, Object.assign({ display: !showSliderInput ? 'flex' : 'none', width: '26px', height: '26px', "data-testid": `priority_score_container_${label}` }, { children: !getCleanScore(value) ? (_jsx(NeedsAction, {})) : (_jsx(PriorityCircle, { borderColor: getPriorityInfo(Math.floor(value)).color, value: getCleanScore(value), label: label })) })), _jsxs(VStack, Object.assign({ spacing: 0, align: 'center' }, { children: [showSliderInput && (_jsx(Text, Object.assign({ height: '20px', fontSize: '10px', fontWeight: '700' }, { children: isNeedsAction && FriendlyPriorityCode.NEEDS_ACTION }))), _jsxs(HStack, Object.assign({ spacing: 0, mt: '-10px', display: showSliderInput ? 'flex' : 'none', "data-testid": `slider_container_${label}` }, { children: [_jsx(Box, { children: _jsx(PriorityCircle, { borderColor: appColors.PRIM_BLUE, value: '0' }) }), _jsxs(Slider, Object.assign({ "aria-label": label, value: value, max: 5, onChange: onChange, onBlur: onBlur, w: '120px', step: 0.5 }, { children: [_jsx(SliderMark, Object.assign({ value: value, textAlign: 'center', color: appColors.PRIM_BLUE, fontSize: 10, mt: '-7', ml: '-6', w: '12', fontWeight: '700' }, { children: getCleanScore(value) })), _jsx(SliderTrack, { bgColor: appColors.PRIM_BLUE, h: '2px' }), _jsx(SliderThumb, { h: '20px', w: '8px', bgColor: appColors.SEC_ORANGE, borderRadius: 0 })] })), _jsx(Box, { children: _jsx(PriorityCircle, { borderColor: appColors.PRIM_BLUE, value: '5' }) })] }))] }))] })] })));
};
