import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Image, Text, VStack } from '@chakra-ui/react';
import { appColors } from '../../config/constants';
import starterImage from '../../images/ScenarioPlanner_baseImage.png';
export const ScenarioInstructions = ({ canAddProject, }) => {
    const instructions = canAddProject
        ? 'Select scenario or create new scenario'
        : 'To be able to add projects/scenarios, go to Organization Profile --> myPlanner and enter in funding types, project types, & Prioritization weights.';
    return (_jsxs(VStack, Object.assign({ justifyContent: 'center', h: '60vh' }, { children: [_jsx(Image, { "aria-label": instructions, h: '40%', sx: { objectFit: 'cover' }, src: starterImage }), _jsx(Text, Object.assign({ mt: '0px!', fontWeight: 500, color: appColors.SEC_DARK_GRAY, maxW: '50em', textAlign: 'center' }, { children: instructions }))] })));
};
