import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { ChakraProvider } from '@chakra-ui/react';
import { DependenciesProvider } from '@frontend/domain/contexts/Dependencies/DependenciesProvider';
import { ToastContainer } from '@frontend/domain/ToastContainer';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { Auth0ProviderWithNavigate } from './components/auth/AuthProviderWithNavigate';
import { environment } from './config/environment';
import theme from './config/theme';
import './index.css';
import reportWebVitals from './reportWebVitals';
import store from './store/store';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(_jsx(Provider, Object.assign({ store: store }, { children: _jsx(BrowserRouter, Object.assign({ basename: environment.appBasename }, { children: _jsx(Auth0ProviderWithNavigate, { children: _jsx(ChakraProvider, Object.assign({ theme: theme }, { children: _jsx(DependenciesProvider, Object.assign({ mysiteApi: {
                        baseUrl: environment.apis.mysiteIQAPI,
                        token: '',
                    }, platformApi: {
                        baseUrl: environment.apis.platformAPI,
                        token: '',
                    } }, { children: _jsx(DndProvider, Object.assign({ backend: HTML5Backend }, { children: _jsxs(_Fragment, { children: [_jsx(App, {}), _jsx(ToastContainer, {})] }) })) })) })) }) })) })));
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
