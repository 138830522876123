import { combineReducers, configureStore, } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query/react';
import { connectRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import featureFlagReducer from './featureFlagSlice';
import organizationReducer from './orgSlice';
import projectReducer from './projectSlice';
import scenarioReducer from './scenarioSlice';
import userReducer from './userSlice';
export const history = createBrowserHistory();
const rootReducer = combineReducers({
    featureFlag: featureFlagReducer,
    organization: organizationReducer,
    project: projectReducer,
    scenario: scenarioReducer,
    user: userReducer,
    router: connectRouter(history),
});
export const store = setupStore();
export function setupStore(preloadedState) {
    return configureStore({
        reducer: rootReducer,
        preloadedState,
    });
}
setupListeners(store.dispatch);
export default store;
