import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, Flex, Spacer, VStack } from '@chakra-ui/react';
import { FilterInput, FilterInputType, } from '@frontend/design-system/components/Filter/FilterInput';
import { validateRangeValue, } from '@frontend/design-system/components/Inputs/RangeInput';
import { useEffect } from 'react';
import { uniqueFieldValuesFromObjectList } from '../../../components/projects/ProjectFilterModal';
import { variants } from '../../../config/theme';
// TODO: this should be placed somewhere reusable (taken from FCA's utlity/ConditionArray)
export const conditions = [
    {
        identity: 1,
        name: '1 (Excellent)',
    },
    {
        identity: 2,
        name: '2 (Acceptable)',
    },
    {
        identity: 3,
        name: '3 (Caution)',
    },
    {
        identity: 4,
        name: '4 (Alert)',
    },
    {
        identity: 5,
        name: '5 (Alarm)',
    },
];
export const getUniqueNumberListSorted = (assets, numberField) => {
    const set = new Set();
    assets.forEach((asset) => {
        const value = numberField(asset);
        value !== undefined && set.add(value);
    });
    const array = Array.from(set);
    array.sort();
    return Array.from(array);
};
export const getUniqueStringListSorted = (assets, stringField) => {
    const set = new Set();
    assets.forEach((asset) => {
        const value = stringField(asset);
        value !== undefined && value !== '' && set.add(value);
    });
    const array = Array.from(set);
    array.sort();
    return Array.from(array);
};
export const AssetFilterContainer = ({ assets, filterContext, formContext, }) => {
    const { initialFilter, applyFilter } = filterContext;
    const { control, reset, getValues, formState: { isDirty }, trigger, } = formContext;
    useEffect(() => {
        // ensure filter is validated and updated on any input change
        trigger();
        if (isDirty) {
            const data = getValues();
            applyFilter(data);
        }
    }, [isDirty]);
    return (_jsxs(_Fragment, { children: [_jsxs(VStack, Object.assign({ width: '98%', spacing: '10px' }, { children: [_jsx(FilterInput, { inputConfig: {
                            label: 'Space Construction Year',
                            type: FilterInputType.MULTI_SELECT,
                            formField: 'spaceConstructionYear',
                            options: getUniqueNumberListSorted(assets, (asset) => asset.spaceConstructionYear).map((item) => ({
                                label: item.toString(),
                                value: item,
                            })),
                        }, control: control }), _jsx(FilterInput, { inputConfig: {
                            label: 'Space Name',
                            type: FilterInputType.MULTI_SELECT,
                            formField: 'spaceName',
                            options: uniqueFieldValuesFromObjectList(assets, (asset) => asset.spaceName).map((item) => ({
                                label: item || '',
                                value: item || '',
                            })),
                        }, control: control }), _jsx(FilterInput, { inputConfig: {
                            label: 'Asset Category',
                            type: FilterInputType.MULTI_SELECT,
                            formField: 'category',
                            options: uniqueFieldValuesFromObjectList(assets, (asset) => asset.category).map((item) => ({
                                label: item,
                                value: item,
                            })),
                        }, control: control }), _jsx(FilterInput, { inputConfig: {
                            label: 'Asset Class',
                            type: FilterInputType.MULTI_SELECT,
                            formField: 'assetClass',
                            options: uniqueFieldValuesFromObjectList(assets, (asset) => asset.assetClass).map((item) => ({
                                label: item,
                                value: item,
                            })),
                        }, control: control }), _jsx(FilterInput, { inputConfig: {
                            label: 'Asset Type',
                            type: FilterInputType.MULTI_SELECT,
                            formField: 'type',
                            options: uniqueFieldValuesFromObjectList(assets, (asset) => asset.type).map((item) => ({
                                label: item,
                                value: item,
                            })),
                        }, control: control }), _jsx(FilterInput, { inputConfig: {
                            label: 'Asset Install Year',
                            type: FilterInputType.MULTI_SELECT,
                            formField: 'installedYear',
                            options: getUniqueNumberListSorted(assets, (asset) => asset.installedYear).map((item) => ({
                                label: item.toString(),
                                value: item,
                            })),
                        }, control: control }), _jsx(FilterInput, { inputConfig: {
                            label: 'Condition',
                            type: FilterInputType.MULTI_SELECT,
                            formField: 'condition',
                            options: [
                                { label: 'Unknown', value: 'None' },
                                ...conditions.map((item) => ({
                                    label: item.name,
                                    value: item.identity,
                                })),
                            ],
                        }, control: control }), _jsx(FilterInput, { inputConfig: {
                            label: 'Projected Life Remaining',
                            type: FilterInputType.RANGE,
                            formField: 'projectedLifeRemaining',
                            inputControl: {
                                type: 'number',
                            },
                            validate: (value) => {
                                if (value !== null) {
                                    const range = value;
                                    return validateRangeValue(range);
                                }
                            },
                        }, control: control })] })), _jsx(Spacer, { height: '20px' }), _jsx(Flex, Object.assign({ position: 'sticky', bottom: '0', w: '100%', minH: '46px', bg: 'white', justify: 'left', align: 'center' }, { children: _jsx(Button, Object.assign({ maxH: '36px', fontSize: '14px', justifySelf: 'left', variant: variants.blueOutlineBtn, onClick: () => {
                        filterContext.clearFilter();
                        reset(initialFilter);
                    } }, { children: "Clear All" })) }))] }));
};
