var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getAppsForOrg } from '../services/api/organizationAPI';
export const fetchOrgApps = createAsyncThunk('organizations/id/apps/fetch', (oid) => __awaiter(void 0, void 0, void 0, function* () {
    return yield getAppsForOrg(oid);
}));
export const SESSION_ORG_ID_KEY = 'oid';
export const initialOrgState = {
    selectedOrg: undefined,
    orgApps: undefined,
};
export const orgSlice = createSlice({
    name: 'organization',
    initialState: initialOrgState,
    reducers: {
        setSelectedOrg: (state, { payload: selectedOrg }) => {
            state.selectedOrg = selectedOrg;
        },
        setOrgApps: (state, { payload: orgApps }) => {
            state.orgApps = orgApps;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchOrgApps.fulfilled, (state, action) => {
            state.orgApps = action.payload;
        });
    },
});
export const selectSelectedOrg = (state) => {
    return state.organization.selectedOrg;
};
export const selectOrgApps = (state) => {
    return state.organization.orgApps;
};
export const { setSelectedOrg, setOrgApps } = orgSlice.actions;
export default orgSlice.reducer;
