import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Button, FormLabel, SimpleGrid, Spacer, Text, VStack, } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useFieldArray } from 'react-hook-form';
import { VscAdd } from 'react-icons/vsc';
import { variants } from '../../../../config/theme';
import { RebateCard } from './RebateCard';
import { RebateInput } from './RebateInput';
export const REBATES_LABEL = 'Available Rebates/Incentives';
export const NO_REBATES_INSTRUCTION = 
// eslint-disable-next-line quotes
"Add rebates that are eligible for this project. Review your local utility provider's website to view available rebates for this project.";
const emptyRebateUpdateForm = {
    amount: '',
    expirationDate: '',
    program: '',
    notes: '',
};
export const Rebates = ({ project, editMode, projectUseForm, }) => {
    const { fields, append, remove } = useFieldArray({
        control: projectUseForm.control,
        name: 'rebates',
    });
    const showRebatesInstruction = project.rebates.length === 0 && !editMode;
    useEffect(() => {
        if (fields === undefined || fields.length === 0) {
            append(emptyRebateUpdateForm);
        }
    }, [editMode]);
    return (_jsxs(Box, Object.assign({ px: 4 }, { children: [_jsx(FormLabel, Object.assign({ color: 'brand.primBlue', fontWeight: 'semibold', pl: 0 }, { children: REBATES_LABEL })), _jsx(Spacer, { h: 4 }), showRebatesInstruction && (_jsx(Text, Object.assign({ variant: variants.subText }, { children: NO_REBATES_INSTRUCTION }))), !editMode && (_jsx(SimpleGrid, Object.assign({ columns: 3, spacing: 4 }, { children: project.rebates.map((rebate, index) => (_jsx(RebateCard, { rebate: rebate }, `${index}_${project.identity}_rebate_card`))) }))), editMode && (_jsxs(VStack, Object.assign({ spacing: 4, align: 'flex-start' }, { children: [fields.map((field, index) => (_jsx(RebateInput, { index: index, removeRebate: () => remove(index), showDelete: fields.length > 1, formContext: projectUseForm }, field.id))), _jsx(Button, Object.assign({ onClick: () => append(emptyRebateUpdateForm), variant: 'link', leftIcon: _jsx(VscAdd, {}), fontWeight: 500, size: '14px', lineHeight: '22px', isDisabled: fields.length === 5 }, { children: "Add new" }))] })))] })));
};
