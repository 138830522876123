var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getOrgScenarios, getScenario } from '../services/api/scenarioAPI';
const initialScenario = undefined;
const initialScenarios = [];
const initialComparisons = [];
const initialYearFocus = undefined;
export const initialScenarioState = {
    compare: false,
    comparisons: initialComparisons,
    compScenarios: initialScenarios,
    editScenario: false,
    loadingSpinner: false,
    orgScenarios: initialScenarios,
    selectedScenario: initialScenario,
    selectedYearFocus: initialYearFocus,
    selectedApplyEscFactor: false,
};
export const fetchScenario = createAsyncThunk('scenarios/id/fetch', (id) => __awaiter(void 0, void 0, void 0, function* () {
    return yield getScenario(id);
}));
export const fetchOrgScenarios = createAsyncThunk('scenarios/byorg/oid/fetch', (orgIdentity) => __awaiter(void 0, void 0, void 0, function* () {
    return yield getOrgScenarios(orgIdentity);
}));
export const ScenarioSlice = createSlice({
    name: 'Scenario',
    initialState: initialScenarioState,
    reducers: {
        setCompare: (state, { payload: compare }) => {
            state.compare = compare;
        },
        setComparisons: (state, { payload: comparisons }) => {
            state.comparisons = comparisons;
        },
        setCompScenarios: (state, { payload: compScenarios }) => {
            state.compScenarios = compScenarios;
        },
        setEditScenario: (state, { payload: editScenario }) => {
            state.editScenario = editScenario;
        },
        setLoadingSpinner: (state, { payload: loading }) => {
            state.loadingSpinner = loading;
        },
        setSelectedScenario: (state, { payload: selectedScenario }) => {
            state.selectedScenario = selectedScenario;
        },
        setSelectedYearFocus: (state, { payload: selectedYearFocus }) => {
            state.selectedYearFocus = selectedYearFocus;
        },
        setSelectedApplyEscFactor: (state, { payload: selectedApplyEscFactor }) => {
            state.selectedApplyEscFactor = selectedApplyEscFactor;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchScenario.fulfilled, (state, action) => {
            state.selectedScenario = action.payload;
        });
        builder.addCase(fetchOrgScenarios.fulfilled, (state, action) => {
            state.orgScenarios = action.payload;
        });
    },
});
export const selectCompare = (state) => state.scenario.compare;
export const selectComparisons = (state) => state.scenario.comparisons;
export const selectCompScenarios = (state) => state.scenario.compScenarios;
export const selectEditScenario = (state) => state.scenario.editScenario;
export const selectLoadingSpinner = (state) => state.scenario.loadingSpinner;
export const selectOrgScenarios = (state) => state.scenario.orgScenarios;
export const selectScenariosSize = (state) => state.scenario.orgScenarios.length;
export const selectSelectedScenario = (state) => state.scenario.selectedScenario;
export const selectSelectedYearFocus = (state) => state.scenario.selectedYearFocus;
export const selectSelectedApplyEscFactor = (state) => state.scenario.selectedApplyEscFactor;
export const { setCompare, setComparisons, setCompScenarios, setEditScenario, setLoadingSpinner, setSelectedScenario, setSelectedYearFocus, setSelectedApplyEscFactor, } = ScenarioSlice.actions;
export default ScenarioSlice.reducer;
