import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Input } from '@chakra-ui/react';
import { useController } from 'react-hook-form';
import { CurrencyInput } from '../../../components/forms/CurrencyInput';
import { appColors } from '../../../config/constants';
import { getCurrencyValue } from '../../../config/CPHelperFunctions';
const AG2SCHOOL_STATE_AID_KEY = 'ag2schoolStateAid';
const LTFM_STATE_AID_KEY = 'ltfmStateAid';
const STATE_AID_DESCRIPTION_KEY = 'stateAidDescription';
const STATE_AID_KEY = 'stateAid';
export const StateAidAssociated = ({ register, control, taxImpact, }) => {
    const stateAidDescriptionRegister = register(STATE_AID_DESCRIPTION_KEY);
    const ag2schoolController = useController({
        name: AG2SCHOOL_STATE_AID_KEY,
        control,
    });
    const ltfmController = useController({
        name: LTFM_STATE_AID_KEY,
        control,
    });
    const stateAidController = useController({
        name: STATE_AID_KEY,
        control,
    });
    const renderAg2schoolStateAid = () => {
        if (taxImpact) {
            return (_jsx(_Fragment, { children: taxImpact.ag2schoolStateAid && (_jsxs(_Fragment, { children: [_jsx(Box, Object.assign({ display: 'flex', alignItems: 'center', textColor: appColors.PRIM_BLUE, fontWeight: '400', fontSize: '14px', pl: '18px' }, { children: "Total Ag2School state aid" })), _jsx(Box, Object.assign({ pl: '15px' }, { children: taxImpact.ag2schoolStateAid &&
                                getCurrencyValue(taxImpact.ag2schoolStateAid) }))] })) }));
        }
        else {
            return (_jsx(CurrencyInput, { label: 'Total Ag2School state aid', controller: ag2schoolController, placeholder: 'Type here...' }));
        }
    };
    const renderLtfmStateAid = () => {
        if (taxImpact) {
            return (_jsx(_Fragment, { children: taxImpact.ltfmStateAid && (_jsxs(_Fragment, { children: [_jsx(Box, Object.assign({ display: 'flex', alignItems: 'center', textColor: appColors.PRIM_BLUE, fontWeight: '400', fontSize: '14px', pl: '18px' }, { children: "Total LTFM equalization state aid" })), _jsx(Box, Object.assign({ pl: '15px' }, { children: taxImpact.ltfmStateAid &&
                                getCurrencyValue(taxImpact.ltfmStateAid) }))] })) }));
        }
        else {
            return (_jsx(CurrencyInput, { label: 'Total LTFM equalization state aid', controller: ltfmController, placeholder: 'Type here...' }));
        }
    };
    const renderStateAidDescription = () => {
        if (taxImpact) {
            return (_jsx(_Fragment, { children: (taxImpact.stateAidDescription || taxImpact.stateAid) && (_jsxs(_Fragment, { children: [_jsxs(Box, Object.assign({ display: 'flex', alignItems: 'center', textColor: appColors.PRIM_BLUE, fontWeight: '400', fontSize: '14px', pl: '18px' }, { children: ["Total ", taxImpact.stateAidDescription, " state aid"] })), _jsx(Box, Object.assign({ pl: '15px' }, { children: taxImpact.stateAid && getCurrencyValue(taxImpact.stateAid) }))] })) }));
        }
        else {
            return (_jsxs(_Fragment, { children: [_jsxs(Box, Object.assign({ display: 'flex', alignItems: 'center', textColor: appColors.PRIM_BLUE, fontWeight: '400', fontSize: '14px', pl: '18px' }, { children: ["Total", _jsx(Box, Object.assign({ ml: '5px', mr: '5px' }, { children: _jsx(Input, Object.assign({ h: '25px', w: '125px', defaultValue: '', type: 'text', placeholder: '', maxLength: 30 }, stateAidDescriptionRegister)) })), "state aid"] })), _jsx(CurrencyInput, { label: '', controller: stateAidController, placeholder: 'Type here...' })] }));
        }
    };
    return (_jsxs(Box, { children: [_jsx(Box, Object.assign({ mb: '15px' }, { children: renderAg2schoolStateAid() })), _jsx(Box, Object.assign({ mb: '15px' }, { children: renderLtfmStateAid() })), renderStateAidDescription()] }));
};
