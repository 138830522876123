var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useToast } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useForm, } from 'react-hook-form';
import { FormModal } from '../../components/overlay/FormModal';
import { createScenario, createScenarioPayload, updateScenario, updateScenarioPayload, } from '../../services/api/scenarioAPI';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { selectSelectedOrg } from '../../store/orgSlice';
import { fetchOrgScenarios, fetchScenario, selectEditScenario, selectOrgScenarios, selectScenariosSize, selectSelectedScenario, setSelectedYearFocus, } from '../../store/scenarioSlice';
import { BudgetPage } from './modalPages/BudgetPage';
import { ImportantFactorsPage } from './modalPages/ImportantFactorsPage';
import { PlanningByPage } from './modalPages/PlanningByPage';
import { ScenarioDetailsPage } from './modalPages/ScenarioDetailsPage';
import { SESSION_SCENARIO_ID_KEY } from './ScenarioPlanner';
export const ScenarioModal = ({ isOpen, onClose, fundingTypes, }) => {
    const dispatch = useAppDispatch();
    const orgScenarios = useAppSelector(selectOrgScenarios);
    const numberOfScenarios = useAppSelector(selectScenariosSize);
    const currOrg = useAppSelector(selectSelectedOrg);
    const edit = useAppSelector(selectEditScenario);
    const scenarioCurrent = useAppSelector(selectSelectedScenario);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [hasErrors, setHasErrors] = useState(false);
    const [planType, setPlanType] = useState('');
    const [firstYear, setFirstYear] = useState(undefined);
    const [amountOfYears, setAmountOfYears] = useState(undefined);
    const [nextStepOverride, setNextStepOverride] = useState();
    const [prevStepOverride, setPrevStepOverride] = useState();
    const [applyEscalation, setApplyEscalation] = useState(false);
    const stepOverride = {
        nextStep: nextStepOverride,
        setNextStep: setNextStepOverride,
        prevStep: prevStepOverride,
        setPrevStep: setPrevStepOverride,
    };
    const { handleSubmit, reset, register, watch, setValue, control } = useForm();
    const toast = useToast();
    useEffect(() => {
        if (!edit) {
            setApplyEscalation(false);
        }
        else {
            if (scenarioCurrent)
                setApplyEscalation(!!scenarioCurrent.escalationFactor);
        }
    }, [scenarioCurrent, edit]);
    const renderPages = () => {
        return [
            _jsx(ScenarioDetailsPage, { register: register, setHasErrors: setHasErrors, setValue: setValue, watch: watch, stepOverride: stepOverride, scenarios: orgScenarios, scenarioCurrent: scenarioCurrent, setFirstYear: setFirstYear, setAmountOfYears: setAmountOfYears, applyEscalation: applyEscalation, setApplyEscalation: setApplyEscalation }, 'page1'),
            _jsx(PlanningByPage, { register: register, setHasErrors: setHasErrors, setValue: setValue, watch: watch, setPlanType: setPlanType, scenarioCurrent: scenarioCurrent }, 'page2'),
            _jsx(BudgetPage, { register: register, setHasErrors: setHasErrors, watch: watch, planType: planType, fundingTypes: fundingTypes, control: control, scenarioCurrent: scenarioCurrent, firstYear: firstYear, amountOfYears: amountOfYears }, 'page3'),
            _jsx(ImportantFactorsPage, { register: register, setHasErrors: setHasErrors, watch: watch, control: control, setValue: setValue, scenarioCurrent: scenarioCurrent }, 'page4'),
        ];
    };
    const submitCreate = (data) => __awaiter(void 0, void 0, void 0, function* () {
        if (currOrg) {
            data.escalationFactor = applyEscalation;
            const payload = createScenarioPayload(data, currOrg.identity, numberOfScenarios + 1, applyEscalation);
            yield createScenario(payload)
                .then((response) => {
                toast({
                    title: `Scenario ${response.name} created`,
                    status: 'success',
                    isClosable: true,
                });
                setApplyEscalation(false);
                dispatch(fetchScenario(response.id));
                sessionStorage.setItem(SESSION_SCENARIO_ID_KEY, response.id);
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                dispatch(fetchOrgScenarios(currOrg.identity));
                dispatch(setSelectedYearFocus(undefined));
            })
                .catch(() => toast({
                title: 'Error creating scenario',
                description: 'Please check that all fields are entered and try again.',
                status: 'error',
                isClosable: true,
            }));
        }
    });
    const submitUpdate = (data) => __awaiter(void 0, void 0, void 0, function* () {
        if (scenarioCurrent) {
            const payload = updateScenarioPayload(data, scenarioCurrent.id, scenarioCurrent.scenarioIdentity, scenarioCurrent.name, applyEscalation);
            yield updateScenario(payload)
                .then((response) => {
                toast({
                    title: `Scenario ${response.name} updated`,
                    status: 'success',
                    isClosable: true,
                });
                setApplyEscalation(false);
                dispatch(fetchScenario(response.id));
                sessionStorage.setItem(SESSION_SCENARIO_ID_KEY, response.id);
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                dispatch(fetchOrgScenarios(currOrg.identity));
            })
                .catch(() => toast({
                title: 'Error updating scenario',
                description: 'Please check that all fields are entered and try again.',
                status: 'error',
                isClosable: true,
            }));
        }
    });
    const onSubmit = (data) => __awaiter(void 0, void 0, void 0, function* () {
        setIsSubmitting(true);
        if (edit) {
            yield submitUpdate(data);
        }
        else {
            yield submitCreate(data);
        }
        onClose();
        setIsSubmitting(false);
        reset();
    });
    return (_jsx(FormModal, { title: edit ? `Edit ${scenarioCurrent === null || scenarioCurrent === void 0 ? void 0 : scenarioCurrent.name}` : 'Create New Scenario', isOpen: isOpen, onClose: onClose, onSubmit: onSubmit, handleSubmit: handleSubmit, errors: hasErrors, onCancel: reset, pages: renderPages(), submitDisabled: isSubmitting, stepOverride: stepOverride }));
};
