import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Grid, Skeleton, VStack } from '@chakra-ui/react';
import { Tabs } from '@frontend/design-system/components/Tabs/Tabs';
import { usePlannerSettingsContext } from '@frontend/domain/contexts/Settings/PlannerSettingsContext';
import { useEffect } from 'react';
import { useAsset } from '../../../contexts/Assets/AssetHook';
import { useProject } from '../../../contexts/Projects/ProjectHook';
import { useProjectsContext } from '../../../contexts/Projects/ProjectsContext';
import { useAppSelector } from '../../../store/hooks';
import { projectCanBeEditedBy, selectOrgRole } from '../../../store/userSlice';
import { ProjectDetails } from './ProjectDetails';
const ProjectDetailsWrapper = ({ orgIdentity, projectIdentity, isProjectPage, index, }) => {
    const { updateProjectInList, deleteProject } = useProjectsContext();
    const { orgFundingTypes, orgAgeAdjustedCondition } = usePlannerSettingsContext();
    const { assets: hookAssets, assetLocations } = useAsset();
    const { project, updateProject, isProjectLoading } = useProject(projectIdentity, updateProjectInList);
    const orgRole = useAppSelector(selectOrgRole);
    const canEdit = projectCanBeEditedBy(orgRole);
    useEffect(() => {
        if (project === null || project === void 0 ? void 0 : project.facilityIdentity) {
            hookAssets.fetchAssetsWithLocation(project === null || project === void 0 ? void 0 : project.facilityIdentity);
        }
    }, [project === null || project === void 0 ? void 0 : project.facilityIdentity]);
    return (_jsx(_Fragment, { children: project ? (_jsx(ProjectDetails, { orgIdentity: orgIdentity, canEdit: canEdit, project: project, updateProject: updateProject, isLoading: isProjectLoading, assets: assetLocations, fundingTypes: orgFundingTypes, showAgeAdjustedCondition: orgAgeAdjustedCondition, isProjectPage: isProjectPage, deleteProject: deleteProject, index: index && index })) : (_jsx(SkeletonTabs, {})) }));
};
// Would like this to be refactored once we get a design system and use the
// TextOrSkeleton component refactored to accept a prop for font size
const SkeletonTabs = () => {
    const tabData = [
        {
            column: 'General Information',
            nestedComponent: _jsx(SkeletonGrid, {}),
        },
        {
            column: 'Environmental',
            nestedComponent: _jsx(SkeletonGrid, {}),
        },
        {
            column: 'Savings',
            nestedComponent: _jsx(SkeletonGrid, {}),
        },
        {
            column: 'Associated Assets',
            nestedComponent: _jsx(SkeletonGrid, {}),
        },
        {
            column: 'Attachments',
            nestedComponent: _jsx(SkeletonGrid, {}),
        },
    ];
    return _jsx(Tabs, { tabs: tabData });
};
const SkeletonGrid = () => {
    return (_jsx(Grid, Object.assign({ templateColumns: 'repeat(3, 1fr)', templateRows: 'repeat(3, 1fr)', gap: 6 }, { children: Array.from({ length: 9 }).map((_, index) => (_jsxs(VStack, Object.assign({ spacing: 2, align: 'stretch', height: 'full' }, { children: [_jsx(Skeleton, { height: '8' }), _jsx(Skeleton, { height: '4' })] }), index))) })));
};
export default ProjectDetailsWrapper;
