import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Flex, HStack, Popover, PopoverArrow, PopoverBody, PopoverContent, PopoverTrigger, Text, VStack, } from '@chakra-ui/react';
export const PopoverProjectsCard = ({ children, projects, facility, emptyText, }) => {
    const showProject = (project) => {
        return (_jsxs(VStack, Object.assign({ whiteSpace: 'normal', alignItems: 'flex-start', textAlign: 'left', paddingBottom: '20px', width: '100%' }, { children: [_jsx(Text, Object.assign({ fontWeight: 'bold' }, { children: project.projectName })), _jsxs(HStack, Object.assign({ fontSize: 'small' }, { children: [_jsx(Text, Object.assign({ fontWeight: 'bold' }, { children: "Facility:" })), _jsx(Text, { children: facility })] })), _jsx(HStack, Object.assign({ alignItems: 'start', width: '100%', fontSize: 'small' }, { children: _jsxs(Box, Object.assign({ display: '-webkit-box', "data-testid": 'name', overflow: 'hidden', textOverflow: 'ellipsis', style: {
                            WebkitLineClamp: 10,
                            WebkitBoxOrient: 'vertical',
                        }, width: '100%' }, { children: [_jsxs(Text, Object.assign({ fontWeight: 'bold', display: 'inline' }, { children: ["Scope:", ' '] })), _jsx(Text, Object.assign({ display: 'inline' }, { children: project.projectScope }))] })) }))] }), `${project.projectIdentity}_${Math.floor(Math.random() * 1000) + 1}`));
    };
    return (_jsx(Box, Object.assign({ m: 0 }, { children: projects.length > 0 ? (_jsxs(Popover, Object.assign({ trigger: 'click', placement: 'bottom-start', isLazy: true }, { children: [_jsx(PopoverTrigger, { children: _jsx(Box, Object.assign({ "data-testid": 'Asset_projects_popover' }, { children: children })) }), _jsxs(PopoverContent, { children: [_jsx(PopoverArrow, {}), _jsx(PopoverBody, { children: _jsx(Flex, Object.assign({ "data-testid": 'Asset_projects_popover', maxH: '500px', overflow: 'auto' }, { children: projects.length > 0 ? (_jsx(VStack, Object.assign({ alignItems: 'start', paddingLeft: '5px', paddingTop: '5px' }, { children: projects.map((project) => showProject(project)) }))) : (_jsx(Text, Object.assign({ w: '100%', whiteSpace: 'normal' }, { children: emptyText }))) })) })] })] }))) : (_jsx(Text, Object.assign({ _hover: { cursor: 'default' } }, { children: "Unassigned" }))) })));
};
