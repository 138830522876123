import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Heading, List, Stack } from '@chakra-ui/react';
import { useDrop } from 'react-dnd';
import { appColors } from '../../../config/constants';
import { variants } from '../../../config/theme';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { selectSelectedYearFocus, setSelectedYearFocus, } from '../../../store/scenarioSlice';
import { ProjectLaneItem } from './ProjectLaneItem';
export const YearLane = ({ year, scenProjData, onProjectDropped, fundingTypes, escalationFactor, }) => {
    const yearFocus = useAppSelector(selectSelectedYearFocus);
    const dispatch = useAppDispatch();
    const [, drop] = useDrop(() => {
        return {
            accept: 'projectListItem',
            //item can be of types IProjectSummary or ScenarioProject, item can be drop from the project list into a year, or from a year into the project list or another year
            drop: (item) => {
                var _a;
                const projectIdentity = (_a = item.project.identity) !== null && _a !== void 0 ? _a : item.project.projectIdentity;
                onProjectDropped(projectIdentity, year);
            },
        };
    });
    return (_jsxs(Stack, Object.assign({ position: 'initial', left: '0', width: '100%', id: year.toString(), ref: (node) => {
            return drop(node);
        }, h: '100%', display: 'block', p: '5px', minW: '17em' }, { children: [yearFocus === undefined && (_jsx(Heading, Object.assign({ w: 'fit-content', pl: '5px', pr: '5px', cursor: 'pointer', as: 'h3', variant: 'h3-v2', onClick: () => {
                    if (process.env.REACT_APP_WIDGET_YEAR_FOCUS === 'true') {
                        if (yearFocus && yearFocus === year) {
                            dispatch(setSelectedYearFocus(undefined));
                        }
                        else {
                            dispatch(setSelectedYearFocus(year));
                        }
                    }
                } }, { children: year }))), _jsx(List, Object.assign({ display: yearFocus ? 'grid' : '', gridTemplateColumns: yearFocus ? 'repeat(3, 1fr)' : '', flexWrap: 'wrap', ref: (node) => drop(node), variant: variants.widgetsPage, borderWidth: '1px', borderColor: yearFocus === undefined ? appColors.SEC_LIGHT_GRAY : '', bg: yearFocus ? '#E6E7E9' : '#FBFBFB', boxSizing: 'border-box', p: '2px', borderRadius: '2px', h: '89%', overflowY: 'auto' }, { children: scenProjData
                    .filter((sp) => sp.year === year)
                    .map((scenProjDatum) => (_jsx(ProjectLaneItem, { project: scenProjDatum.project, year: year, updatedFundingType: scenProjDatum.fundingTypeIdentity, fundingTypes: fundingTypes, escalationFactor: escalationFactor }, scenProjDatum.project.id))) }))] })));
};
