var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, HStack, IconButton, PopoverTrigger, Select, Text, useDisclosure, useToast, } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useDrag } from 'react-dnd';
import { MdArrowDropDown, MdRemoveRedEye } from 'react-icons/md';
import { PopoverPhotos } from '../../../components/overlay/PopoverPhotos';
import { opacityHex } from '../../../config/constants';
import { getPriorityInfo } from '../../../config/CPHelperFunctions';
import { ImgIcon } from '../../../config/icons';
import { useProjectsContext } from '../../../contexts/Projects/ProjectsContext';
import { getProjectPhotos, updateProject, } from '../../../services/api/projectAPI';
import { updateScenarioProjectFundingType } from '../../../services/api/scenarioAPI';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { fetchScenario, selectSelectedScenario, selectSelectedYearFocus, } from '../../../store/scenarioSlice';
import { scenarioCanBeEditedBy, selectScenarioRole, } from '../../../store/userSlice';
import { EstimatedBudget } from './EstimatedBudget';
import { ProjectDetailsModal } from './ProjectDetailsModal';
export const ProjectLaneItem = ({ project, year, updatedFundingType, fundingTypes, escalationFactor, }) => {
    const { projectList } = useProjectsContext();
    const scenarioCurrent = useAppSelector(selectSelectedScenario);
    const yearFocus = useAppSelector(selectSelectedYearFocus);
    const scenarioRole = useAppSelector(selectScenarioRole);
    const [scenarioProject, setScenarioProject] = useState(undefined);
    const [fundingName, setFundingName] = useState('');
    const [photos, setPhotos] = useState([]);
    const toast = useToast();
    const dispatch = useAppDispatch();
    const { isOpen: isProjectDetailsModalOpen, onOpen: onOpenProjectDetailsModal, onClose: onCloseProjectDetailsModal, } = useDisclosure();
    const canEdit = scenarioCanBeEditedBy(scenarioRole) &&
        scenarioCurrent &&
        !scenarioCurrent.isLocked;
    const [, drag] = useDrag(() => {
        return {
            type: 'projectListItem',
            item: { project },
        };
    });
    useEffect(() => {
        const funding = fundingTypes === null || fundingTypes === void 0 ? void 0 : fundingTypes.filter((ft) => ft.identity === updatedFundingType);
        if (funding && (funding === null || funding === void 0 ? void 0 : funding.length) > 0) {
            setFundingName(funding[0].name);
        }
    }, [updatedFundingType]);
    useEffect(() => {
        const projectInScenario = projectList.find((projectListItem) => projectListItem.identity === project.projectIdentity);
        setScenarioProject({
            project: Object.assign(Object.assign({}, project), { 
                // to display updated items from project details modal (refactor during scenario migration)
                name: (projectInScenario === null || projectInScenario === void 0 ? void 0 : projectInScenario.name) || '', estBudget: projectInScenario === null || projectInScenario === void 0 ? void 0 : projectInScenario.estimatedBudget, prioritizationScore: projectInScenario === null || projectInScenario === void 0 ? void 0 : projectInScenario.priorityScore }),
            scenarioID: scenarioCurrent === null || scenarioCurrent === void 0 ? void 0 : scenarioCurrent.id,
            fundingTypeIdentity: project.fundingType.identity,
            year: year,
        });
    }, [projectList]);
    const getPhotos = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const photos = yield getProjectPhotos(project.projectIdentity, 10);
            if (photos && photos.length > 0) {
                setPhotos(photos);
            }
        }
        catch (error) {
            console.error('Error fetching project photos:', error);
        }
    });
    const handleUpdateFunding = (fundingIdentity) => __awaiter(void 0, void 0, void 0, function* () {
        const projectToUpdate = {
            fundingTypeIdentity: fundingIdentity,
        };
        try {
            {
                scenarioCurrent
                    ? yield updateScenarioProjectFundingType(scenarioCurrent.id, project.projectIdentity, projectToUpdate)
                    : yield updateProject(project.id, projectToUpdate);
            }
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            dispatch(fetchScenario(scenarioCurrent.id));
            toast({
                title: `Project ${project.name} updated`,
                status: 'success',
                isClosable: true,
            });
        }
        catch (_a) {
            toast({
                title: 'update failed',
                description: 'Update project failed.',
                status: 'error',
                isClosable: true,
            });
        }
    });
    return (_jsx(PopoverPhotos, Object.assign({ photos: photos, emptyText: 'To see photos of this project, add asset photos in the Organize application.', hasOwnTrigger: true }, { children: _jsxs(HStack, Object.assign({ w: yearFocus ? '90%' : '', h: 'fit-content', mr: yearFocus ? '20px' : '0px', bg: '#FFFFFF', borderLeft: project &&
                getPriorityInfo(scenarioProject === null || scenarioProject === void 0 ? void 0 : scenarioProject.project.prioritizationScore)
                ? `8px solid ${getPriorityInfo(scenarioProject === null || scenarioProject === void 0 ? void 0 : scenarioProject.project.prioritizationScore)
                    .color}${opacityHex.thirty}`
                : '', boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.25)', borderRadius: '2px', p: '10px 20px 10px 5px', flexDirection: 'column', alignItems: 'baseline', mb: '20px !important', marginInlineStart: '0px !important', position: 'relative', ref: (node) => (canEdit ? drag(node) : node) }, { children: [_jsxs(HStack, Object.assign({ justifyContent: 'space-between', w: '100%' }, { children: [_jsx("h4", { children: scenarioProject === null || scenarioProject === void 0 ? void 0 : scenarioProject.project.name }), _jsx(PopoverTrigger, { children: _jsx(Box, Object.assign({ _hover: { cursor: 'pointer' } }, { children: _jsx(ImgIcon, { onMouseOver: () => getPhotos(), onClick: () => getPhotos() }) })) })] })), scenarioCurrent && scenarioCurrent.isLocked && (_jsx(Text, Object.assign({ pb: '5px', fontSize: '12px' }, { children: updatedFundingType ? fundingName : project.fundingType.name }))), canEdit && (_jsx(Select, Object.assign({ variant: 'flushed', defaultValue: updatedFundingType
                        ? updatedFundingType
                        : project.fundingType.identity, size: 'xs', icon: _jsx(MdArrowDropDown, {}), border: 'none', pl: '0px', onChange: (e) => {
                        handleUpdateFunding(parseInt(e.target.value));
                    }, "data-testid": updatedFundingType
                        ? updatedFundingType
                        : project.fundingType.identity }, { children: fundingTypes &&
                        (fundingTypes === null || fundingTypes === void 0 ? void 0 : fundingTypes.map((funding) => {
                            return (_jsx("option", Object.assign({ value: funding.identity }, { children: funding.name }), funding.identity));
                        })) }))), _jsx(EstimatedBudget, { scenarioProj: scenarioProject, escalationFactor: escalationFactor }), _jsxs(HStack, Object.assign({ position: 'absolute', bottom: '10px', right: '10px', fontSize: '20px' }, { children: [_jsx(IconButton, { "aria-label": `view ${project.name}`, icon: _jsx(MdRemoveRedEye, {}), onClick: onOpenProjectDetailsModal, p: 0, size: 'sm', variant: 'ghost' }), _jsx(ProjectDetailsModal, { isOpen: isProjectDetailsModalOpen, onClose: onCloseProjectDetailsModal, projectIdentity: project.projectIdentity })] }))] })) })));
};
