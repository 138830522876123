var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useDependencies } from '@frontend/domain/contexts/Dependencies/DependenciesContext';
import { useProjectsService } from '@frontend/domain/services/ProjectService';
import { useEffect, useState } from 'react';
import { convertProjectUpdateFormToIConfigureProject } from './ProjectsConversions';
export const useProject = (projectIdentity, onProjectUpdate) => {
    const { platformApi } = useDependencies();
    const { project: projectApi } = useProjectsService({ platformApi });
    const [project, setProject] = useState();
    const [isProjectLoading, setIsProjectLoading] = useState(false);
    useEffect(() => {
        fetchProject(projectIdentity);
    }, []);
    const fetchProject = (projectIdentity) => __awaiter(void 0, void 0, void 0, function* () {
        setIsProjectLoading(true);
        projectApi
            .fetchDetails(projectIdentity)
            .then((projectDetails) => {
            setProject(projectDetails);
        })
            .finally(() => setIsProjectLoading(false));
    });
    const updateProject = (facilityIdentity, projectForm) => __awaiter(void 0, void 0, void 0, function* () {
        setIsProjectLoading(true);
        const convertedProject = convertProjectUpdateFormToIConfigureProject(projectIdentity, facilityIdentity, projectForm);
        return projectApi
            .upsert(convertedProject)
            .then((projectDetails) => {
            if (projectDetails) {
                setProject(projectDetails);
                onProjectUpdate(projectDetails);
                return Promise.resolve();
            }
            else {
                return Promise.reject();
            }
        })
            .catch(() => Promise.reject())
            .finally(() => setIsProjectLoading(false));
    });
    return {
        project,
        isProjectLoading,
        updateProject,
    };
};
