var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useToast } from '@chakra-ui/react';
import { toggleScenarioLockStatus } from '../services/api/scenarioAPI';
import { useAppDispatch, useAppSelector } from './hooks';
import { selectSelectedOrg } from './orgSlice';
import { fetchOrgScenarios, fetchScenario, selectCompare, selectCompScenarios, setCompScenarios, setLoadingSpinner, } from './scenarioSlice';
export function useScenarioLock() {
    const currOrg = useAppSelector(selectSelectedOrg);
    const compare = useAppSelector(selectCompare);
    const scenarios = useAppSelector(selectCompScenarios);
    const dispatch = useAppDispatch();
    const toast = useToast();
    const handleScenarioLock = (scenario) => __awaiter(this, void 0, void 0, function* () {
        dispatch(setLoadingSpinner(true));
        try {
            yield toggleScenarioLockStatus(scenario);
            dispatch(fetchScenario(scenario.id));
            if (currOrg) {
                dispatch(fetchOrgScenarios(currOrg.identity));
            }
            if (compare) {
                const index = scenarios.findIndex((sc) => sc.name === scenario.name);
                if (index !== -1) {
                    const compareScenarios = [...scenarios];
                    compareScenarios[index] = Object.assign(Object.assign({}, scenario), { isLocked: !scenario.isLocked });
                    dispatch(setCompScenarios(compareScenarios));
                }
            }
            toast({
                title: scenario.isLocked
                    ? `Scenario ${scenario.name} was unlocked`
                    : `Scenario ${scenario.name} was locked`,
                status: 'success',
                isClosable: true,
            });
        }
        catch (_a) {
            toast({
                title: scenario.isLocked
                    ? 'Unlock scenario failed'
                    : 'Lock scenario failed',
                description: 'Unlock scenario failed.',
                status: 'error',
                isClosable: true,
            });
        }
        dispatch(setLoadingSpinner(false));
    });
    return handleScenarioLock;
}
