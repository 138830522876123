import { jsx as _jsx } from "react/jsx-runtime";
import { RemovableBulb } from '@frontend/design-system/components/FilterRow/RemovableBulb';
import { instanceOfRangeValue, } from '@frontend/design-system/components/Inputs/RangeInput';
export const PlanAssetFilterBulbDisplay = ({ filterKey, filterValue, clearFilter, }) => {
    const getRangeValueLabel = (rangeValue) => {
        const { min, max } = rangeValue;
        if (rangeValue.max !== undefined && rangeValue.min !== undefined) {
            return `${min} to ${max}`;
        }
        else if (rangeValue.max !== undefined) {
            return `Under ${max}`;
        }
        else if (rangeValue.min !== undefined) {
            return `Above ${min}`;
        }
        else {
            return 'Range Error';
        }
    };
    if (filterKey === 'projects') {
        return (_jsx(RemovableBulb, { label: 'No projects assigned', removeBulb: clearFilter }, `${filterKey}_${filterValue}`));
    }
    else if (typeof filterValue === 'string') {
        const value = filterValue.toString();
        return (_jsx(RemovableBulb, { label: value, removeBulb: clearFilter }, `${filterKey}_${filterValue}`));
    }
    else if (instanceOfRangeValue(filterValue)) {
        const value = filterValue;
        const label = getRangeValueLabel(value);
        return (_jsx(RemovableBulb, { label: label, removeBulb: clearFilter }, `${filterKey}_${filterValue}`));
    }
    else {
        return (_jsx(RemovableBulb, { label: filterKey, removeBulb: clearFilter }, `${filterKey}_${filterValue}`));
    }
};
