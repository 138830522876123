var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, FormControl, FormLabel, Input, Select } from '@chakra-ui/react';
const SelectInputTable = (_a) => {
    var { options, label, value: initialValue, onChange, type } = _a, props = __rest(_a, ["options", "label", "value", "onChange", "type"]);
    return (_jsx(Box, Object.assign({ mb: 3 }, { children: _jsxs(FormControl, { children: [_jsx(FormLabel, { children: label }), type === 'number' ? (_jsx(Input, Object.assign({}, props, { size: 'sm', value: initialValue, onChange: (e) => onChange(e.target.value) }))) : (_jsx(Select, Object.assign({ value: initialValue, onChange: (e) => onChange(e.target.value), placeholder: `Select ${label}` }, { children: options === null || options === void 0 ? void 0 : options.map((opt) => (_jsx("option", Object.assign({ value: opt.value }, { children: opt.title }), `${label}-option-${opt.value}`))) })))] }) })));
};
export default SelectInputTable;
