var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useState } from 'react';
import { Box, PopoverTrigger } from '@chakra-ui/react';
import { PopoverPhotos } from '../../../components/overlay/PopoverPhotos';
import { ImgIcon } from '../../../config/icons';
const AssetImages = ({ asset, loadImages }) => {
    const [photos, setPhotos] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [hasLoaded, setHasLoaded] = useState(false);
    const getPhotos = () => __awaiter(void 0, void 0, void 0, function* () {
        if (hasLoaded || !asset)
            return;
        setIsLoading(true);
        const images = yield loadImages(asset === null || asset === void 0 ? void 0 : asset.identity);
        if (images) {
            setIsLoading(false);
            setHasLoaded(true);
            setPhotos(images);
        }
    });
    return (_jsx(PopoverPhotos, Object.assign({ photos: photos, asset: asset, isLoading: isLoading, emptyText: 'To see photos of these assets, add asset photos in the Organize application.', hasOwnTrigger: true }, { children: _jsx(Box, Object.assign({ _hover: { cursor: 'pointer' } }, { children: _jsx(PopoverTrigger, { children: _jsx(ImgIcon, { onMouseOver: () => getPhotos(), onClick: (e) => {
                        getPhotos();
                        e.stopPropagation();
                    } }) }) })) })));
};
export default AssetImages;
