import { jsx as _jsx } from "react/jsx-runtime";
import React, { createContext, useContext, useEffect } from 'react';
export const TableAccordionContext = createContext({});
export const useTableAccordion = () => useContext(TableAccordionContext);
export function TableAccordionProvider({ rowsLength, allowMultiple = false, openFirstRow, children, }) {
    const [panelIndexesOpen, setPanelIndexesOpen] = React.useState([]);
    useEffect(() => {
        if (openFirstRow && rowsLength && rowsLength > 0) {
            togglePanel(rowsLength);
        }
    }, [openFirstRow]);
    const togglePanel = (index) => {
        if (!allowMultiple) {
            if (panelIndexesOpen.includes(index)) {
                setPanelIndexesOpen([]);
            }
            else {
                setPanelIndexesOpen([index]);
            }
            return;
        }
        if (panelIndexesOpen.includes(index)) {
            setPanelIndexesOpen(panelIndexesOpen.filter((i) => i !== index));
        }
        else {
            setPanelIndexesOpen([...panelIndexesOpen, index]);
        }
    };
    const isOpen = (index) => panelIndexesOpen.includes(index);
    return (_jsx(TableAccordionContext.Provider, Object.assign({ value: {
            togglePanel: togglePanel,
            isOpen,
        } }, { children: children })));
}
