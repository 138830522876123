import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { HStack, Spacer, useToken, VStack } from '@chakra-ui/react';
import { FloorPlanCanvas, } from '@frontend/design-system/components/FloorPlan/FloorPlanCanvas';
import { MeasureSquare } from '@frontend/design-system/components/MeasureSquare/MeasureSquare';
import { AssetClassType, } from '@frontend/domain/models/Assets/AssetsInterfaces';
import { uniqueObjects } from '../../../utils/sortingAndFiltering';
export const MapLegend = () => {
    const [yellow, secOrange] = useToken('colors', ['yellow', 'brand.secOrange']);
    return (_jsxs(HStack, Object.assign({ w: 'full' }, { children: [_jsx(Spacer, {}), _jsxs(VStack, Object.assign({ alignItems: 'flex-start', spacing: 0 }, { children: [_jsx(MeasureSquare, { color: `${secOrange}BF`, text: 'Selected', border: false, rounded: true }), _jsx(MeasureSquare, { color: `${yellow}BF`, text: 'Not Selected', border: false, rounded: true })] }))] })));
};
export const AssetMap = ({ containerWidth, assets, assignedAssets = [], planImg, }) => {
    const [yellow, secOrange] = useToken('colors', ['yellow', 'brand.secOrange']);
    const getViewOnlyStyleFromSpace = () => {
        return {
            stroke: {
                selected: {
                    width: 1.5,
                    color: 'black',
                },
                unselected: {
                    width: 1,
                    color: 'black',
                },
            },
            fill: {
                selected: `${secOrange}BF`,
                unselected: `${yellow}BF`,
            },
        };
    };
    const shapes = [
        ...uniqueObjects(assets.map((asset) => ({
            identity: asset.spaceIdentity,
            isSelected: assignedAssets.find((assignedAsset) => assignedAsset.spaceIdentity === asset.spaceIdentity &&
                assignedAsset.assetClassType === AssetClassType.STANDARD)
                ? true
                : false,
            shape: asset.spaceLocation.map((point) => ({
                x: point.x,
                y: point.y,
                pointIndex: point.pointIndex,
            })),
            style: getViewOnlyStyleFromSpace(),
        })), (space) => space.identity),
    ];
    const pins = assets
        .filter((asset) => asset.assetClassType === AssetClassType.PINNED ||
        asset.assetClassType === AssetClassType.FACILITY)
        .map((asset) => {
        var _a, _b, _c, _d;
        return ({
            identity: asset.identity,
            location: {
                x: (_b = (_a = asset.location) === null || _a === void 0 ? void 0 : _a.x) !== null && _b !== void 0 ? _b : 0,
                y: (_d = (_c = asset.location) === null || _c === void 0 ? void 0 : _c.y) !== null && _d !== void 0 ? _d : 0,
            },
            associatedShapeIdentity: asset.spaceIdentity,
            fill: assignedAssets.find((selectedAsset) => selectedAsset.identity === asset.identity)
                ? `${secOrange}BF`
                : `${yellow}BF`,
        });
    });
    return (_jsx(FloorPlanCanvas, { containerWidth: containerWidth, planImageUrl: planImg !== null && planImg !== void 0 ? planImg : '', pins: pins, splitShapes: [], shapes: shapes, isPinSelected: () => false }));
};
