import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FormControl, FormLabel, Input, InputGroup, InputLeftElement, Text, } from '@chakra-ui/react';
import MaskedInput from 'react-text-mask';
import { createNumberMask } from 'text-mask-addons';
export const CurrencyInput = ({ label, controller, placeholder, required, isBudgetPerYear, budgetTotalForFundingType, disable, isProjectBudget, }) => {
    const { field: { onChange, onBlur, value }, } = controller;
    const defaultMaskOptions = {
        prefix: '',
        suffix: '',
        includeThousandsSeparator: true,
        thousandsSeparatorSymbol: ',',
        allowDecimal: true,
        decimalSymbol: '.',
        decimalLimit: 2,
        integerLimit: 15,
        allowNegative: isProjectBudget ? true : false,
        allowLeadingZeroes: false,
    };
    const currencyMask = createNumberMask(Object.assign({}, defaultMaskOptions));
    return (_jsxs(FormControl, { children: [_jsxs(FormLabel, Object.assign({ display: 'flex', justifyContent: isBudgetPerYear ? 'space-between' : '' }, { children: [label, " ", required && _jsx("span", Object.assign({ className: 'required' }, { children: " *" })), isBudgetPerYear && (_jsxs(Text, { children: ["Total", ' ', _jsx("b", { children: budgetTotalForFundingType
                                    ? budgetTotalForFundingType.toLocaleString('en-US')
                                    : 0 }), ' ', "USD"] }))] })), _jsxs(InputGroup, { children: [_jsx(InputLeftElement, Object.assign({ pointerEvents: 'none' }, { children: "$" })), _jsx(Input, { as: MaskedInput, mask: currencyMask, placeholder: placeholder ? placeholder : label, onChange: onChange, onBlur: onBlur, value: value, isDisabled: disable })] })] }));
};
