import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { HStack, Stack } from '@chakra-ui/react';
import { useEffect } from 'react';
import { SelectInput } from '../../../components/forms/SelectInput';
import { BudgetTypeEnum } from '../../../config/constants';
import { useAppSelector } from '../../../store/hooks';
import { selectEditScenario } from '../../../store/scenarioSlice';
const PLAN_BUDGET_TYPE_KEY = 'budgetType';
export const PlanningByPage = ({ register, watch, setHasErrors, setPlanType, setValue, scenarioCurrent, }) => {
    const planTypeRegister = register(PLAN_BUDGET_TYPE_KEY, { required: true });
    const edit = useAppSelector(selectEditScenario);
    const planType = watch(planTypeRegister.name);
    useEffect(() => {
        if (!planType) {
            setHasErrors(true);
        }
        else {
            setPlanType(planType);
            setHasErrors(false);
        }
    });
    useEffect(() => {
        if (scenarioCurrent && edit) {
            setValue(PLAN_BUDGET_TYPE_KEY, planType ? planType : scenarioCurrent.budgetType);
        }
    }, [scenarioCurrent]);
    return (_jsx(Stack, { children: _jsx(HStack, { children: _jsx(SelectInput, Object.assign({ label: 'I am planning by...', register: planTypeRegister, defaultValue: '', required: true }, { children: _jsxs(_Fragment, { children: [_jsx("option", Object.assign({ value: BudgetTypeEnum.TOTAL_BUDGET }, { children: "Total Budget" })), _jsx("option", Object.assign({ value: BudgetTypeEnum.FUNDING_TYPE }, { children: "Budget broken down by funding type" })), process.env.REACT_APP_FUNDING_TYPE_PER_YEAR === 'true' && (_jsx("option", Object.assign({ value: BudgetTypeEnum.FUNDING_TYPE_PER_YEAR }, { children: "Budget broken down by funding type and year" })))] }) })) }) }));
};
