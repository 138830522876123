import { useFilter, } from '@frontend/design-system/components/Filter/Filter';
import FilterMap, { DefaultFilterType, } from '@frontend/design-system/components/Filter/FilterMap';
const blankFilter = {
    spaceConstructionYear: null,
    spaceName: null,
    category: null,
    assetClass: null,
    type: null,
    installedYear: null,
    condition: null,
    projectedLifeRemaining: null,
};
const assetCurrentConditionFilter = (filterObj, key) => {
    return (obj) => {
        var _a;
        if (filterObj.condition) {
            const filterItem = filterObj.condition;
            if (filterItem.length > 0) {
                const valuesToFilterAgainst = filterItem.map((opt) => opt.value.toString());
                const conditionValue = (_a = obj[key]) === null || _a === void 0 ? void 0 : _a.toString();
                if (conditionValue !== undefined) {
                    return valuesToFilterAgainst.includes(conditionValue);
                }
                else {
                    return valuesToFilterAgainst.includes('None');
                }
            }
        }
        return true;
    };
};
export const assetWithLocationFilter = (assetSummaryList, useAgeAdjustedCondition) => {
    const customConditionFilter = (filterObj) => {
        return useAgeAdjustedCondition
            ? assetCurrentConditionFilter(filterObj, 'ageAdjustedCondition')
            : assetCurrentConditionFilter(filterObj, 'condition');
    };
    const filterMap = new FilterMap();
    filterMap.set('spaceConstructionYear', DefaultFilterType.MULTI_VALUE);
    filterMap.set('spaceName', DefaultFilterType.MULTI_VALUE);
    filterMap.set('category', DefaultFilterType.MULTI_VALUE);
    filterMap.set('assetClass', DefaultFilterType.MULTI_VALUE);
    filterMap.set('type', DefaultFilterType.MULTI_VALUE);
    filterMap.setCustom('condition', customConditionFilter);
    filterMap.set('installedYear', DefaultFilterType.MULTI_VALUE);
    filterMap.set('projectedLifeRemaining', DefaultFilterType.RANGE);
    return useFilter({
        itemsToFilter: assetSummaryList,
        filterMap,
        initialFilter: blankFilter,
    });
};
