import { jsx as _jsx } from "react/jsx-runtime";
import { HorizontalInfoCard, } from '@frontend/design-system/components/DataDisplay/HorizontalInfoCard';
import { getNumByAmount } from '../../../../config/CPHelperFunctions';
export const RebateCard = ({ rebate }) => {
    const stringAmount = getNumByAmount(rebate.amount);
    const stringDate = new Date(rebate.expirationDate).toLocaleDateString('en-US', {
        timeZone: 'UTC',
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
    });
    const details = [
        { label: 'From', info: rebate.rebateProgram },
        { label: 'Expiration', info: stringDate },
        { label: 'Notes', info: rebate.notes },
    ];
    return _jsx(HorizontalInfoCard, { header: stringAmount, details: details });
};
