var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, HStack } from '@chakra-ui/react';
import { SelectInput } from '@frontend/design-system/components/DynamicInput/SelectInput';
import { TextAreaInput } from '@frontend/design-system/components/DynamicInput/TextAreaInput';
import { TextInput } from '@frontend/design-system/components/DynamicInput/TextInput';
import { Form } from '@frontend/design-system/components/FormModal/FormModal';
import { CurrencyInput } from '@frontend/design-system/components/Inputs/CurrencyInput';
import { DateInput, validateYearRange, } from '@frontend/design-system/components/Inputs/DateInput';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { projectLabels } from '../../../config/constants';
import { initialProjectCreateData, } from '../../../objects/CPCreateUpdateInterfaces';
import { FacilitySelector } from './FacilitySelector';
const NAME_KEY = 'name';
const PROJ_TYPE_KEY = 'projectTypeIdentity';
const FUNDING_TYPE_KEY = 'fundingTypeIdentity';
const ESTIMATED_BUDGET_KEY = 'estimatedBudget';
const QUOTE_DATE_KEY = 'quoteDate';
const PROJ_SCOPE_KEY = 'scope';
const CURRENT_ISSUES_KEY = 'currentIssues';
export const AddProjectModal = ({ disclosure, sites, projectTypes, fundingTypes, onCreate, }) => {
    const projectUseForm = useForm({
        values: initialProjectCreateData,
        mode: 'onBlur',
    });
    const { control, resetField, reset, handleSubmit, formState: { isValid }, } = projectUseForm;
    const [showQuoteDate, setShowQuoteDate] = useState(false);
    const submitNewProject = (data) => __awaiter(void 0, void 0, void 0, function* () {
        // TODO: move to use service layer once migrated to platformAPI
        yield onCreate(data);
    });
    const resetForm = () => {
        reset(initialProjectCreateData);
        setShowQuoteDate(false);
    };
    useEffect(() => {
        if (disclosure.isOpen) {
            // has to reset on open to handle properly handle submit form and close modal events
            resetForm();
        }
    }, [disclosure.isOpen]);
    return (_jsx(Form.Modal, Object.assign({ disclosure: disclosure, onApply: handleSubmit(submitNewProject), onClear: () => {
            disclosure.onClose();
        }, labels: {
            title: ' Create New Project',
            apply: 'Create',
            cancel: 'Cancel',
        }, applyDisabled: !isValid }, { children: _jsxs(Form.Column, Object.assign({ stackProps: { alignItems: 'flex-start', gap: '15px' } }, { children: [_jsx(FacilitySelector, { sites: sites, form: projectUseForm }), _jsx(Controller, { name: NAME_KEY, control: control, rules: { required: true }, render: ({ field, fieldState: { error } }) => (_jsx(TextInput, { label: projectLabels.projectName, editMode: true, inputType: 'text', data: field.value, errorMessage: error === null || error === void 0 ? void 0 : error.message, onInputChange: field.onChange, constraints: { maxLength: 80 }, required: true })) }), _jsxs(HStack, Object.assign({ width: '100%', justifyItems: 'space-between' }, { children: [_jsx(Controller, { name: PROJ_TYPE_KEY, control: control, rules: { required: true }, render: ({ field }) => (_jsx(SelectInput, { label: projectLabels.projType, editable: true, data: field.value, choices: projectTypes.map((projectType) => ({
                                    label: projectType.name,
                                    value: projectType.identity,
                                })), placeholder: 'Select', onInputChange: field.onChange, required: true })) }), _jsx(Controller, { name: FUNDING_TYPE_KEY, control: control, rules: { required: true }, render: ({ field }) => (_jsx(SelectInput, { label: projectLabels.fundingType, editable: true, data: field.value, choices: fundingTypes.map((fundingType) => ({
                                    label: fundingType.name,
                                    value: fundingType.identity,
                                })), placeholder: 'Select', onInputChange: field.onChange, required: true })) })] })), _jsxs(HStack, Object.assign({ width: '100%' }, { children: [_jsx(Box, Object.assign({ width: '50%' }, { children: _jsx(Controller, { name: ESTIMATED_BUDGET_KEY, control: control, render: ({ field }) => (_jsx(CurrencyInput, { label: projectLabels.estBudget, data: field.value, maskOptions: { allowDecimal: false }, onInputChange: (e) => {
                                        if (e && e.target.value) {
                                            setShowQuoteDate(true);
                                        }
                                        else {
                                            resetField(QUOTE_DATE_KEY);
                                            setShowQuoteDate(false);
                                        }
                                        field.onChange(e);
                                    }, placeholder: 'Type here...' })) }) })), _jsx(Box, Object.assign({ display: showQuoteDate ? 'block' : 'none', width: '50%' }, { children: _jsx(Controller, { name: QUOTE_DATE_KEY, control: control, rules: {
                                    validate: (value) => {
                                        const year = new Date(value).getUTCFullYear();
                                        const min = 1700;
                                        const max = new Date().getUTCFullYear() + 1;
                                        return validateYearRange(year, min, max);
                                    },
                                }, render: ({ field, fieldState: { error } }) => (_jsx(DateInput, { label: projectLabels.budgetDate, data: field.value, errorMessage: error === null || error === void 0 ? void 0 : error.message, onInputChange: field.onChange })) }) }))] })), _jsx(Controller, { name: PROJ_SCOPE_KEY, control: control, rules: { required: true }, render: ({ field, fieldState: { error } }) => (_jsx(TextAreaInput, { label: projectLabels.projScope, editMode: true, data: field.value, onInputChange: field.onChange, placeholder: projectLabels.projScope, errorMessage: error === null || error === void 0 ? void 0 : error.message, constraints: { maxLength: 350 }, required: true })) }), _jsx(Controller, { name: CURRENT_ISSUES_KEY, control: control, render: ({ field, fieldState: { error } }) => (_jsx(TextAreaInput, { label: projectLabels.currentIssues, editMode: true, data: field.value, onInputChange: field.onChange, placeholder: projectLabels.currentIssues, errorMessage: error === null || error === void 0 ? void 0 : error.message, constraints: { maxLength: 500 } })) })] })) })));
};
