import { getCoreRowModel, getFacetedRowModel, getFacetedUniqueValues, getFilteredRowModel, getSortedRowModel, useReactTable, } from '@tanstack/react-table';
export function sortByField(fieldGetter, desc) {
    return (a, b) => {
        if (desc) {
            return fieldGetter(b) - fieldGetter(a);
        }
        return fieldGetter(a) - fieldGetter(b);
    };
}
export function filterByFields(filters) {
    return (e) => {
        return filters.every((fieldFilter) => {
            return fieldFilter.targetValue === fieldFilter.fieldGetter(e);
        });
    };
}
export function uniqueObjects(objects, key) {
    const objectMap = new Map(objects.map((item) => [key(item), item]));
    const objectList = [];
    objectMap.forEach((item) => {
        objectList.push(item);
    });
    return objectList;
}
export function useDataTable(options) {
    var _a, _b, _c, _d, _e;
    return useReactTable(Object.assign(Object.assign({}, options), { getCoreRowModel: (_a = options.getCoreRowModel) !== null && _a !== void 0 ? _a : getCoreRowModel(), getSortedRowModel: (_b = options.getSortedRowModel) !== null && _b !== void 0 ? _b : getSortedRowModel(), getFacetedUniqueValues: (_c = options.getFacetedUniqueValues) !== null && _c !== void 0 ? _c : getFacetedUniqueValues(), getFacetedRowModel: (_d = options.getFacetedRowModel) !== null && _d !== void 0 ? _d : getFacetedRowModel(), getFilteredRowModel: (_e = options.getFilteredRowModel) !== null && _e !== void 0 ? _e : getFilteredRowModel() }));
}
