import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Center, Text } from '@chakra-ui/react';
import { useAppSelector } from '../store/hooks';
import { selectSelectedOrg } from '../store/orgSlice';
export const AccessDenied = () => {
    const currentOrg = useAppSelector(selectSelectedOrg);
    const concierge = currentOrg === null || currentOrg === void 0 ? void 0 : currentOrg.clientConcierge;
    return (_jsx(Center, Object.assign({ h: '85vh', w: '100%' }, { children: _jsxs(Box, Object.assign({ textAlign: 'center' }, { children: [_jsx(Text, Object.assign({ mb: '2' }, { children: "At this moment, you do not have access for this organization." })), _jsx(Text, { children: concierge
                        ? `Please contact your client concierge, ${concierge.firstName} ${concierge.lastName} at ${concierge.email}`
                        : 'Please contact your client concierge' })] })) })));
};
