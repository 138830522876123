import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FormControl, FormErrorMessage, FormLabel, Input, } from '@chakra-ui/react';
export const validateYearRange = (year, min, max) => {
    if (max < min) {
        throw Error('Max year cannot be less than Min year.');
    }
    if (typeof year == 'undefined' ||
        year === null ||
        year === 'mm/dd/yyyy' ||
        year === '' ||
        year == undefined ||
        Number.isNaN(year)) {
        return undefined;
    }
    if (min > year) {
        return `Year cannot be before ${min}`;
    }
    if (year > max) {
        return `Year cannot be after ${max}`;
    }
    return undefined;
};
export const validateDateRange = (stringDate, min, max) => {
    if (max < min) {
        throw Error('Max year cannot be less than Min year.');
    }
    if (stringDate === '' || stringDate == undefined) {
        return undefined;
    }
    const date = new Date(stringDate);
    if (min > date) {
        return `Date must be after ${getDateString(min)}`;
    }
    if (date > max) {
        return `Date must be before ${getDateString(max)}`;
    }
    return undefined;
};
export const getMinDate = (minYear) => {
    const minDate = new Date();
    minDate.setUTCFullYear(minYear, 0, 1);
    return getDateString(minDate);
};
export const getMaxDate = (maxYear) => {
    const maxDate = new Date();
    maxDate.setUTCFullYear(maxYear, 11, 31);
    return getDateString(maxDate);
};
export const getDateString = (date) => {
    return date.toLocaleDateString('en-US', {
        timeZone: 'UTC',
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
    });
};
export const DateInput = ({ label, data, onInputChange, onInputBlur, minDate, maxDate, required, isDisabled, errorMessage, }) => {
    return (_jsxs(FormControl, Object.assign({ isRequired: required, isInvalid: !!errorMessage }, { children: [_jsx(FormLabel, Object.assign({ display: !label ? 'none' : 'block' }, { children: label })), _jsxs("div", Object.assign({ style: { position: 'relative' } }, { children: [_jsx(Input, { value: data, placeholder: 'mm/dd/yyyy', type: 'date', onChange: (e) => onInputChange(e.target.value), onBlur: onInputBlur
                            ? (e) => onInputBlur(e.target.value)
                            : undefined, isDisabled: isDisabled, min: minDate, max: maxDate }), errorMessage && (_jsx(FormErrorMessage, Object.assign({ mt: 1, ml: 1, whiteSpace: 'normal', wordBreak: 'break-word' }, { children: errorMessage })))] }))] })));
};
