import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from 'react';
import { useController } from 'react-hook-form';
import { RadioGroupInput } from '../../../components/forms/RadioGroup';
import { PlanningFactors } from '../../../config/constants';
import { useAppSelector } from '../../../store/hooks';
import { selectEditScenario } from '../../../store/scenarioSlice';
const PLAN_FACTORS_KEY = 'planningFactors';
export const ImportantFactorsPage = ({ watch, setHasErrors, control, setValue, scenarioCurrent, }) => {
    const edit = useAppSelector(selectEditScenario);
    const controller = useController({
        name: PLAN_FACTORS_KEY,
        control,
        rules: { required: true },
    });
    const planFactors = watch(controller.field.name);
    useEffect(() => {
        if (!planFactors) {
            setHasErrors(true);
        }
        else {
            setHasErrors(false);
        }
    });
    useEffect(() => {
        if (edit && scenarioCurrent) {
            setValue(PLAN_FACTORS_KEY, planFactors ? planFactors : scenarioCurrent.planningFactors);
        }
    }, [scenarioCurrent]);
    const radios = [
        {
            label: 'Funding Type',
            value: PlanningFactors.FUNDING_TYPE,
        },
        {
            label: 'Facility',
            value: PlanningFactors.FACILITY,
        },
    ];
    return (_jsx(RadioGroupInput, { label: 'What factor is most important for you when deciding which projects to include in a scenario? - Select one:', radios: radios, controller: controller, required: true }));
};
