export const initialProjectCreateData = {
    name: '',
    scope: '',
    projectTypeIdentity: '',
    fundingTypeIdentity: '',
    facilityIdentity: '',
    estimatedBudget: '',
    quoteDate: '',
    currentIssues: '',
};
