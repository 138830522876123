import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { Grid, GridItem, IconButton } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { PlanningFactors } from '../../../config/constants';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { selectSelectedApplyEscFactor, setSelectedApplyEscFactor, } from '../../../store/scenarioSlice';
import { BudgetPerFacilityWidget } from './BudgetPerFacilityWidget';
import { BudgetSummaryWidget } from './BudgetSummaryWidget';
export const WidgetsContainer = ({ scenarioCurrent, fundingTypes, escalationFactor, }) => {
    const [currentWidget, setCurrentWidget] = useState(scenarioCurrent.planningFactors);
    const applyEscFactor = useAppSelector(selectSelectedApplyEscFactor);
    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(setSelectedApplyEscFactor(scenarioCurrent.escalationFactor));
    }, [scenarioCurrent.name]);
    const showNext = () => {
        if (currentWidget === PlanningFactors.FUNDING_TYPE) {
            setCurrentWidget(PlanningFactors.FACILITY);
        }
        else if (currentWidget === PlanningFactors.FACILITY) {
            setCurrentWidget(PlanningFactors.FUNDING_TYPE);
        }
    };
    const showPrevious = () => {
        if (currentWidget === PlanningFactors.FUNDING_TYPE) {
            setCurrentWidget(PlanningFactors.FACILITY);
        }
        else if (currentWidget === PlanningFactors.FACILITY) {
            setCurrentWidget(PlanningFactors.FUNDING_TYPE);
        }
    };
    return (_jsxs(Grid, Object.assign({ h: '35vh', w: '100%', templateColumns: '50px auto 50px' }, { children: [_jsx(GridItem, Object.assign({ rowSpan: 1, colSpan: 1, display: 'block', margin: 'auto', onClick: () => showPrevious() }, { children: _jsx(IconButton, Object.assign({ "aria-label": 'Left Arrow', variant: 'ghost' }, { children: _jsx(ChevronLeftIcon, {}) })) })), _jsx(GridItem, Object.assign({ rowSpan: 1, colSpan: 1, position: 'relative' }, { children: currentWidget === PlanningFactors.FUNDING_TYPE ? (_jsx(BudgetSummaryWidget, { scenarioCurrent: scenarioCurrent, applyEscFactor: applyEscFactor, fundingTypes: fundingTypes, escalationFactor: escalationFactor })) : currentWidget === PlanningFactors.FACILITY ? (_jsx(BudgetPerFacilityWidget, { scenarioCurrent: scenarioCurrent, applyEscFactor: applyEscFactor, escalationFactor: escalationFactor })) : (_jsx(_Fragment, { children: 'Planned vs available by priority' })) })), _jsx(GridItem, Object.assign({ rowSpan: 1, colSpan: 1, display: 'block', margin: 'auto', onClick: () => showNext() }, { children: _jsx(IconButton, Object.assign({ "aria-label": 'Right Arrow', variant: 'ghost' }, { children: _jsx(ChevronRightIcon, {}) })) }))] })));
};
