import { jsx as _jsx } from "react/jsx-runtime";
import { Stack } from '@chakra-ui/react';
import { SimpleModal } from '../../../components/overlay/SimpleModal';
import { useAppSelector } from '../../../store/hooks';
import { selectSelectedOrg } from '../../../store/orgSlice';
import ProjectDetailsWrapper from '../../projects/ProjectDetails/ProjectDetailsWrapper';
export const ProjectDetailsModal = ({ isOpen, onClose, projectIdentity, }) => {
    const currOrg = useAppSelector(selectSelectedOrg);
    return (_jsx(SimpleModal, Object.assign({ title: 'Project Details', isOpen: isOpen, onClose: onClose }, { children: _jsx(Stack, Object.assign({ w: '100%' }, { children: (currOrg === null || currOrg === void 0 ? void 0 : currOrg.identity) && (_jsx(ProjectDetailsWrapper, { orgIdentity: currOrg.identity, projectIdentity: projectIdentity, isProjectPage: false })) })) })));
};
