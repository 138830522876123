import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import { ThumbnailItem } from './ThumbnailItem';
const thumbnailHeight = '220px';
export const PhotoCarousel = ({ photos }) => {
    const [currentPhoto, setCurrentPhoto] = useState(photos ? photos[0] : undefined);
    useEffect(() => {
        setCurrentPhoto(photos[0]);
    }, [photos]);
    const previousPhoto = () => {
        if (currentPhoto) {
            if (photos.indexOf(currentPhoto) === 0) {
                setCurrentPhoto(photos[photos.length - 1]);
            }
            else {
                setCurrentPhoto(photos[photos.indexOf(currentPhoto) - 1]);
            }
        }
    };
    const nextPhoto = () => {
        if (currentPhoto) {
            if (photos.length - 1 === photos.indexOf(currentPhoto)) {
                setCurrentPhoto(photos[0]);
            }
            else {
                setCurrentPhoto(photos[photos.indexOf(currentPhoto) + 1]);
            }
        }
    };
    return (_jsx(_Fragment, { children: photos.length > 1 ? (_jsxs(Box, Object.assign({ h: thumbnailHeight, position: 'relative', width: 'full' }, { children: [_jsx(Box, Object.assign({ bg: 'linear-gradient(to right, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0))', paddingTop: '85px', w: '15%', minH: thumbnailHeight, onClick: previousPhoto, position: 'absolute', zIndex: 2, left: 0, _hover: { cursor: 'pointer' } }, { children: _jsx(IoIosArrowBack, { fontSize: '40px', color: 'white', "aria-label": 'previous photo' }) })), _jsx(Box, Object.assign({ bg: 'linear-gradient(to left, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0))', paddingTop: '85px', w: '15%', minH: thumbnailHeight, onClick: nextPhoto, position: 'absolute', zIndex: 2, right: 0, _hover: { cursor: 'pointer' } }, { children: _jsx(IoIosArrowForward, { fontSize: '40px', color: 'white', "aria-label": 'next photo' }) })), _jsx(Box, Object.assign({ w: '100%', h: thumbnailHeight }, { children: currentPhoto && (_jsx(ThumbnailItem, { photo: currentPhoto, maxH: thumbnailHeight })) }))] }))) : (_jsx(Box, Object.assign({ w: '100%', h: thumbnailHeight }, { children: currentPhoto && (_jsx(ThumbnailItem, { photo: currentPhoto, maxH: thumbnailHeight })) }))) }));
};
