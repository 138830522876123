import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { HStack, Tab, TabList, TabPanel, TabPanels, Tabs, } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { appColors } from '../../../config/constants';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { selectSelectedYearFocus, setSelectedYearFocus, } from '../../../store/scenarioSlice';
import { YearLane } from './YearLane';
export const ScenariosArea = ({ scenarioCurrent, onProjectDropped, fundingTypes, escalationFactor, }) => {
    const [years, setYears] = useState([]);
    const yearFocus = useAppSelector(selectSelectedYearFocus);
    const dispatch = useAppDispatch();
    useEffect(() => {
        const yearsTemp = [];
        for (let i = scenarioCurrent.startYear; i < scenarioCurrent.startYear + scenarioCurrent.planDurationYrs; i++) {
            yearsTemp.push(i);
        }
        setYears(yearsTemp);
        if (scenarioCurrent.planDurationYrs === 1) {
            dispatch(setSelectedYearFocus(scenarioCurrent.startYear));
        }
    }, [scenarioCurrent]);
    const renderYearTabs = () => {
        return (_jsxs(Tabs, Object.assign({ w: '100%', variant: 'enclosed', h: '100%', 
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            defaultIndex: years.indexOf(yearFocus), overflowY: 'hidden', display: 'flex', flexDirection: 'column' }, { children: [_jsx(TabList, Object.assign({ w: 'fit-content' }, { children: years.map((year) => (_jsx(Tab, Object.assign({ _selected: {
                            bg: '#E6E7E9',
                            borderColor: appColors.SEC_LIGHT_GRAY,
                        }, onClick: () => {
                            if (process.env.REACT_APP_WIDGET_YEAR_FOCUS === 'true') {
                                if (yearFocus && yearFocus === year) {
                                    dispatch(setSelectedYearFocus(undefined));
                                }
                                else {
                                    dispatch(setSelectedYearFocus(year));
                                }
                            }
                        } }, { children: year }), year))) })), _jsx(TabPanels, Object.assign({ h: '100%', bg: '#E6E7E9', w: '100%', position: 'sticky', left: '0' }, { children: years.map((year) => {
                        var _a;
                        return (_jsx(TabPanel, Object.assign({ h: '100%', w: '100%', borderColor: appColors.SEC_LIGHT_GRAY, overflowY: 'scroll' }, { children: _jsx(YearLane, { year: year, scenProjData: (_a = scenarioCurrent.assignedProjects) !== null && _a !== void 0 ? _a : [], onProjectDropped: onProjectDropped, fundingTypes: fundingTypes, escalationFactor: escalationFactor }, year) }), year));
                    }) }))] })));
    };
    return (_jsx(_Fragment, { children: _jsx(HStack, Object.assign({ h: '100%', overflowX: 'auto', display: yearFocus ? 'block' : 'flex' }, { children: yearFocus
                ? renderYearTabs()
                : years.map((year) => {
                    var _a;
                    return (_jsx(YearLane, { year: year, scenProjData: (_a = scenarioCurrent.assignedProjects) !== null && _a !== void 0 ? _a : [], onProjectDropped: onProjectDropped, fundingTypes: fundingTypes, escalationFactor: escalationFactor }, year));
                }) })) }));
};
