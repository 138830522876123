var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
const baseUrl = '/scenarios';
export default class ScenarioService {
    constructor(api) {
        this.api = api;
    }
    getScenario(scid) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.api.get(`${baseUrl}/${scid}`);
        });
    }
    getOrgScenarios(orgIdentity) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.api.get(`${baseUrl}/org/${orgIdentity}`);
        });
    }
    createScenario(data) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.api.post(`${baseUrl}`, data);
        });
    }
    updateScenario(data) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.api.put(`${baseUrl}`, data);
        });
    }
    toggleScenarioLockStatus(scenarioIdentity, lockStatus) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.api.put(`${baseUrl}/${scenarioIdentity}/toggleLockStatus`, { lockStatus: lockStatus });
        });
    }
    addProjectToScenario(scid, prIdentity, data) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.api.put(`${baseUrl}/${scid}/projects/${prIdentity}`, data);
        });
    }
    updateScenarioProjectFundingType(scid, prIdentity, projectToUpdate) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.api.put(`${baseUrl}/${scid}/projects/${prIdentity}/fundingType`, projectToUpdate);
        });
    }
    removeProjectFromScenario(scid, prIdentity) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.api.delete(`${baseUrl}/${scid}/projects/${prIdentity}`);
        });
    }
    addTaxImpact(data, scIdentity) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.api.put(`${baseUrl}/${scIdentity}/taxImpact`, data);
        });
    }
}
