import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Center, Stack, Text } from '@chakra-ui/react';
import { measureColors } from '../../config/constants';
export const ConditionCircle = ({ condition, borderWidth, size, }) => {
    const generateColor = (condition) => {
        switch (condition) {
            case 1:
                return measureColors.MEASURE_EXCELLENT;
            case 2:
                return measureColors.MEASURE_ACCEPTABLE;
            case 3:
                return measureColors.MEASURE_CAUTION;
            case 4:
                return measureColors.MEASURE_ALERT;
            case 5:
                return measureColors.MEASURE_ALARM;
            default:
                return 'white';
        }
    };
    const generateDescription = (condition) => {
        switch (condition) {
            case 1:
                return 'Excellent';
            case 2:
                return 'Acceptable';
            case 3:
                return 'Caution';
            case 4:
                return 'Alert';
            case 5:
                return 'Alarm';
            default:
                return 'Unknown';
        }
    };
    return (_jsxs(Stack, Object.assign({ direction: 'row', align: 'center' }, { children: [condition && (_jsx(Center, Object.assign({ boxSize: size === 'sm' ? '15px' : '26px', borderColor: generateColor(condition), borderWidth: borderWidth ? borderWidth : '2px', borderRadius: '50%' }, { children: _jsx(Text, Object.assign({ "data-testid": 'condition_value', variant: 'slider', fontSize: size === 'sm' ? '10px' : '12px' }, { children: condition })) }))), _jsx(Text, Object.assign({ ml: '5px' }, { children: generateDescription(condition) }))] })));
};
