import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { IconButton, Td, Text, Tooltip, Tr, useDisclosure, } from '@chakra-ui/react';
import React, { useState } from 'react';
import { useDrag } from 'react-dnd';
import { MdLock, MdModeEditOutline } from 'react-icons/md';
import { appColors, opacityHex } from '../../../config/constants';
import { slgqColorSchemes } from '../../../config/theme';
import { useAppSelector } from '../../../store/hooks';
import { selectSelectedScenario } from '../../../store/scenarioSlice';
import { scenarioCanBeEditedBy, selectScenarioRole, } from '../../../store/userSlice';
import { ProjectDetailsModal } from './ProjectDetailsModal';
export const ProjectTr = ({ project }) => {
    const scenarioCurrent = useAppSelector(selectSelectedScenario);
    const scenarioRole = useAppSelector(selectScenarioRole);
    const { isOpen: isProjectDetailsModalOpen, onOpen: onOpenProjectDetailsModal, onClose: onCloseProjectDetailsModal, } = useDisclosure();
    const [, drag] = useDrag(() => {
        return {
            type: 'projectListItem',
            item: { project },
        };
    });
    const [hover, setHover] = useState(false);
    const canEdit = scenarioCanBeEditedBy(scenarioRole) &&
        scenarioCurrent &&
        !scenarioCurrent.isLocked;
    return (_jsx(Tr, Object.assign({ ref: (node) => (canEdit ? drag(node) : node), _hover: { bg: `${appColors.SEC_ORANGE}${opacityHex.thirty}` }, onMouseOver: () => setHover(true), onMouseLeave: () => setHover(false), boxSizing: 'border-box', height: '52px', borderRadius: '2px', fontSize: 12, fontWeight: 500, color: appColors.TEXT_SEC_DARK_GRAY }, { children: _jsx(Tooltip, Object.assign({ hasArrow: true, label: project.name, colorScheme: slgqColorSchemes.primBlue, openDelay: 300 }, { children: _jsxs(Td, Object.assign({ p: '10px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', whiteSpace: 'normal', w: '300px' }, { children: [_jsx(Text, Object.assign({ noOfLines: 1 }, { children: project.name.length > 35
                            ? project.name.substring(0, 35).concat('...')
                            : project.name })), canEdit ? (_jsx(IconButton, { "aria-label": `edit view ${project.name}`, icon: _jsx(MdModeEditOutline, {}), 
                        //This will be updated as part of the fetch project details migration work
                        onClick: onOpenProjectDetailsModal, p: 0, size: 'sm', variant: 'ghost', visibility: hover ? 'visible' : 'hidden' })) : (_jsx(MdLock, {})), _jsx(ProjectDetailsModal, { isOpen: isProjectDetailsModalOpen, onClose: onCloseProjectDetailsModal, projectIdentity: project.identity })] })) })) })));
};
