import { AssetClassType } from './AssetsInterfaces';
export const convertAssetSummaryResponseToIAssetSummary = (asset) => {
    var _a, _b, _c, _d, _e, _f;
    const isValidEnum = Object.values(AssetClassType).includes(asset.assetClassType);
    if (!isValidEnum)
        console.warn('Invalid AssetClassType:', asset.assetName);
    const conditionResponse = (_a = asset.currentCondition) !== null && _a !== void 0 ? _a : undefined;
    const aacResponse = (_b = asset.currentAgeAdjustedCondition) !== null && _b !== void 0 ? _b : undefined;
    // TODO: not all fields are being mapped, add as needed
    return isValidEnum
        ? {
            name: asset.assetName,
            identity: asset.assetId,
            category: asset.assetCategory,
            type: asset.assetType,
            projectedLifeRemaining: (_c = asset.projectedLifeRemaining) !== null && _c !== void 0 ? _c : undefined,
            assetClassType: asset.assetClassType,
            assetClass: asset.assetClass,
            condition: conditionResponse,
            installedYear: (_d = asset.installedYear) !== null && _d !== void 0 ? _d : undefined,
            ageAdjustedCondition: aacResponse !== undefined ? aacResponse : conditionResponse,
            location: asset.pin
                ? { x: (_e = asset.pin) === null || _e === void 0 ? void 0 : _e.xPosition, y: (_f = asset.pin) === null || _f === void 0 ? void 0 : _f.yPosition }
                : undefined,
            quantity: asset.quantity,
        }
        : undefined;
};
