import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CircularProgress, CircularProgressLabel, HStack, SkeletonText, VStack, } from '@chakra-ui/react';
import React from 'react';
const Row = ({ children, stackProps }) => {
    return (_jsx(HStack, Object.assign({ w: '100%', h: '100%', spacing: '20px', mb: 8, justify: 'space-between' }, stackProps, { children: children })));
};
const NumericMetricLabel = ({ isLoading, value }) => {
    return (_jsx(SkeletonText, Object.assign({ color: 'brand.secOrange', fontSize: 48, isLoaded: !isLoading }, { children: value })));
};
const PercentageMetricLabel = ({ isLoading, value }) => {
    return (_jsx(CircularProgress, Object.assign({ value: value, color: 'brand.secOrange', size: '70px', thickness: 6, isIndeterminate: isLoading }, { children: _jsxs(CircularProgressLabel, Object.assign({ color: 'brand.secOrange', fontSize: 22, fontWeight: 700 }, { children: [value, "%"] })) })));
};
const Metric = ({ header, value, isPercentage, isLoading, minWidth = '150px', minHeight = '160px', }) => {
    return (_jsxs(VStack, Object.assign({ borderRadius: 8, boxShadow: '0px 0px 4px 0px #00000026', w: '100%', h: '100%', justify: 'space-between', textAlign: 'center', p: 4, minWidth: minWidth, minHeight: minHeight, "data-testid": header }, { children: [_jsx(SkeletonText, Object.assign({ fontWeight: 700, fontSize: '16px', color: 'brand.primBlue', isLoaded: !isLoading }, { children: header })), isPercentage ? (_jsx(PercentageMetricLabel, { value: value, isLoading: isLoading })) : (_jsx(NumericMetricLabel, { value: value, isLoading: isLoading }))] })));
};
export const Dashboard = {
    Row,
    Metric,
};
