import { defineStyleConfig, extendTheme } from '@chakra-ui/react';
import { StepsTheme as Steps } from 'chakra-ui-steps';
import { appColors, opacityHex } from './constants';
export const variants = {
    title: 'title',
    widgetsPage: 'widgets-page',
    comparisonTitle: 'comparisonTitle',
    boldText: 'bold-text',
    regText: 'reg-text',
    boldHeading: 'bold-heading',
    boldGrayHeading: 'bold-gray-heading',
    blueBtn: 'blue-btn',
    blueOutlineBtn: 'blue-outline-btn',
    redOutlineBtn: 'red-outline-btn',
    sidebarBtn: 'sidebar-btn',
    grayBtn: 'gray-btn',
    orangeTransparentBtn: 'orange-transparent-btn',
    blueTransparentBtn: 'trans-blue-btn',
    subText: 'sub-text',
};
export const layerStyles = {
    widgetsPageContainer: 'widgets-page-container',
};
export const slgqColorSchemes = {
    primBlue: 'primBlue',
    secDarkGray: 'secDarkGray',
    textColor: 'textColor',
};
const CustomSteps = Object.assign(Object.assign({}, Steps), { baseStyle: (props) => {
        return Object.assign(Object.assign({}, Steps.baseStyle(props)), { icon: Object.assign(Object.assign({}, Steps.baseStyle(props).icon), { strokeWidth: '1px' }), steps: Object.assign(Object.assign({}, Steps.baseStyle(props).steps), { sizes: {
                    lg: {
                        label: {
                            fontSize: '10.2rem',
                        },
                    },
                } }) });
    } });
const InputStyling = defineStyleConfig({
    baseStyle: {
        field: {
            _placeholder: {
                color: appColors.SEC_DARK_GRAY,
                opacity: 1,
                wordBreak: 'break-word',
                whiteSpace: 'normal',
                wordWrap: 'break-word',
            },
            borderRadius: '8px',
            borderWidth: '1px',
            boxSizing: 'border-box',
            fontSize: '14px',
            h: '40px',
            px: '18px',
        },
    },
    variants: {
        outline: {
            field: {
                borderColor: appColors.PRIM_LIGHT_GREY,
                backgroundColor: 'white',
            },
        },
    },
});
const ListStyling = defineStyleConfig({
    baseStyle: { bg: 'red' },
    variants: {
        'widgets-page': {
            borderWidth: '1px',
            borderColor: appColors.SEC_LIGHT_GRAY,
            // bg: '#FBFBFB',
            boxSizing: 'border-box',
            p: '2px',
            borderRadius: '2px',
        },
    },
});
const theme = extendTheme({
    colors: {
        brand: {
            primBlue: appColors.PRIM_BLUE,
            primOrange: {
                30: '#FBDABD',
            },
            secOrange: appColors.SEC_ORANGE,
        },
        orange: {
            '500': appColors.SEC_ORANGE,
        },
        yellow: '#FEDB5E',
        primBlue: {
            '50': '#dfefff',
            '500': appColors.PRIM_BLUE,
            '600': '#000f1e',
        },
        secDarkGray: {
            '500': appColors.SEC_DARK_GRAY,
            '600': '#717574',
        },
        secondaryLightGray: appColors.SEC_LIGHT_GRAY,
        secondaryDarkGray: {
            300: '#C0C2C1',
            800: '#2E3532',
        },
        slgq: {
            white: '#FFFFFF',
        },
    },
    fonts: {
        nav: 'montserrat',
        heading: 'montserrat',
        body: 'montserrat',
        header: 'montserrat',
        section: 'montserrat',
        footer: 'montserrat',
    },
    fontSizes: {
        heading: '32px',
        subheading: '24px',
        body: '16px',
        // Add other sizes as needed
    },
    fontWeights: {
        normal: 400,
        bold: 700,
        semibold: 600,
        // Add other weights as needed
    },
    textStyles: {
        heading: {
            fontSize: 'heading',
            fontWeight: 'bold',
        },
        subheading: {
            fontSize: 'subheading',
            fontWeight: 'semibold',
        },
        body: {
            fontSize: 'body',
            fontWeight: 'normal',
        },
        // Add other styles as needed
    },
    layerStyles: {
        'widgets-page-container': {
            borderRadius: '2px',
            borderWidth: '1px',
            borderColor: `${appColors.SEC_DARK_GRAY}${opacityHex.thirty}`,
            display: 'block',
            p: '10px',
        },
    },
    borderStyles: {
        border: {
            primary: `1px solid ${appColors.SEC_LIGHT_GRAY}`,
        },
    },
    components: {
        Switch: {
            baseStyle: {
                track: {
                    _checked: {
                        bg: '#003B5D',
                    },
                },
            },
        },
        Button: {
            baseStyle: {
                borderRadius: '40px',
                h: '40px',
                fontSize: 14,
                fontWeight: 500,
            },
            defaultProps: {
                colorScheme: 'primBlue',
            },
            variants: {
                'blue-btn': {
                    bg: appColors.PRIM_BLUE,
                    color: 'white',
                    borderRadius: '40px',
                    boxSizing: 'border-box',
                    fontWeight: 500,
                    fontSize: '14px',
                    height: '36px',
                },
                'blue-outline-btn': {
                    bg: 'white',
                    color: appColors.PRIM_BLUE,
                    border: `1px solid ${appColors.PRIM_BLUE}`,
                    borderRadius: '40px',
                    boxSizing: 'border-box',
                    fontWeight: 500,
                    fontSize: '14px',
                    height: '36px',
                },
                'trans-blue-btn': {
                    bg: 'transparent',
                    color: 'brand.primBlue',
                    borderWidth: '1px',
                    borderColor: 'brand.primBlue',
                    borderRadius: '18px',
                    boxSizing: 'border-box',
                    py: '8px',
                    px: '20px',
                    h: '36px',
                },
                'red-outline-btn': {
                    bg: 'white',
                    color: appColors.DELETE_ERROR,
                    border: `1px solid ${appColors.DELETE_ERROR}`,
                    borderRadius: '40px',
                    boxSizing: 'border-box',
                    fontWeight: 500,
                    fontSize: '14px',
                    height: '36px',
                    width: '106px',
                },
                'orange-transparent-btn': {
                    bg: 'transparent',
                    color: 'brand.secOrange',
                    borderWidth: '1px',
                    borderColor: 'brand.secOrange',
                    borderRadius: '18px',
                    boxSizing: 'border-box',
                    py: '8px',
                    px: '20px',
                    h: '36px',
                },
                'sidebar-btn': {
                    background: '#EDF2F7',
                    dropShadow: 'rgba(0, 0, 0, 0.25)',
                    borderShadow: '50px 0px 0px 50px',
                    opacity: 0.8,
                    borderRadius: '50px 0px 0px 50px',
                    p: 0,
                    m: 0,
                },
                'gray-btn': {
                    bg: appColors.SEC_DARK_GRAY,
                    color: 'white',
                    borderRadius: '40px',
                    boxSizing: 'border-box',
                    fontWeight: 500,
                    fontSize: '14px',
                    height: '36px',
                    width: '106px',
                },
            },
        },
        Checkbox: {
            baseStyle: {
                size: 'md',
            },
            defaultProps: {
                color: appColors.SEC_ORANGE,
                colorScheme: '#FFFFFF',
            },
        },
        FormLabel: {
            baseStyle: {
                boxSizing: 'border-box',
                color: appColors.PRIM_BLUE,
                fontWeight: 'semibold',
                fontSize: 14,
                mb: '5px',
                pl: '18px',
            },
        },
        Heading: {
            baseStyle: {
                color: appColors.TEXT_SEC_DARK_GRAY,
                fontWeight: 400,
                fontSize: 32,
            },
            variants: {
                h3: {
                    fontSize: 14,
                    fontWeight: 600,
                },
                'h3-v2': {
                    fontWeight: 600,
                    fontSize: '20px',
                },
                'bold-heading': {
                    fontWeight: 500,
                    fontSize: '10px',
                    color: appColors.PRIM_BLUE,
                },
                'bold-gray-heading': {
                    fontWeight: 500,
                    fontSize: '10px',
                    color: appColors.TEXT_SEC_DARK_GRAY,
                },
                'clickable-name': {
                    cursor: 'pointer',
                    _hover: {
                        textDecoration: 'underline',
                    },
                },
            },
        },
        Input: InputStyling,
        List: ListStyling,
        NumberInput: InputStyling,
        Modal: {
            baseStyle: {
                header: {
                    color: appColors.TEXT_SEC_DARK_GRAY,
                    fontSize: 32,
                    fontWeight: 400,
                },
                dialog: {
                    fontFamily: 'montserrat',
                },
            },
        },
        Select: {
            baseStyle: {
                field: {
                    borderRadius: '8px',
                    fontSize: '14px',
                    backgroundColor: 'white',
                },
            },
            variants: {
                outline: {
                    field: {
                        borderColor: appColors.PRIM_LIGHT_GREY,
                    },
                },
            },
        },
        Text: {
            baseStyle: {
                color: appColors.TEXT_SEC_DARK_GRAY,
            },
            variants: {
                title: {
                    fontWeight: 600,
                    fontSize: '20px',
                },
                error: {
                    color: 'crimson',
                },
                'form-instructions': {
                    fontSize: 18,
                },
                slider: {
                    fontSize: 12,
                    color: appColors.PRIM_BLUE,
                },
                'bold-text': {
                    fontSize: '10px',
                    color: appColors.PRIM_BLUE,
                    fontWeight: 500,
                },
                'reg-text': {
                    fontSize: '10px',
                    color: appColors.TEXT_SEC_DARK_GRAY,
                    fontWeight: 400,
                },
                'sub-text': {
                    fontWeight: 400,
                    size: '14px',
                    color: appColors.SEC_DARK_GRAY,
                    lineHeight: '22px',
                },
            },
        },
        Textarea: {
            baseStyle: {
                borderRadius: 'full',
                borderRightRadius: '8px',
                borderLeftRadius: '8px',
            },
            variants: {
                outline: {
                    borderColor: appColors.PRIM_LIGHT_GREY,
                    backgroundColor: 'white',
                },
            },
        },
        Table: {
            parts: ['table', 'th', 'td', 'tr'],
            baseStyle: {
                table: {
                    borderCollapse: 'separate',
                    borderSpacing: '0 10px',
                    border: 'none',
                    fontVariantNumeric: 'none',
                },
                th: {
                    fontWeight: 700,
                    textTransform: 'capitalize',
                    fontSize: 14,
                    letterSpacing: 'normal',
                    border: 'none',
                },
                td: {
                    borderTop: '1px solid',
                    borderBottom: '1px solid',
                },
                tr: {
                    borderRadius: '8px',
                    h: '65px',
                },
            },
            variants: {
                'border-none': {
                    table: {
                        borderCollapse: 'separate',
                    },
                    th: {
                        fontWeight: 500,
                        textTransform: 'capitalize',
                        px: '2px',
                    },
                    thead: {
                        fontWeight: 500,
                    },
                    td: {
                        border: 'none',
                    },
                    tr: {
                        border: 'none',
                    },
                },
            },
        },
        Tabs: {
            variants: {
                unstyled: {
                    parts: ['tab'],
                    tab: {
                        color: 'brand.primBlue',
                        fontSize: 24,
                        fontWeight: 700,
                        _selected: {
                            borderBottom: '4px solid #F6862A',
                        },
                    },
                },
                styled: {
                    parts: ['tab', 'tabpanel'],
                    tab: {
                        backgroundColor: '#6C899E',
                        color: 'white',
                        _selected: {
                            backgroundColor: '#F2F3F4',
                            color: 'secondaryDarkGray.800',
                            borderRadius: '4px 4px 0px 0px',
                            boxShadow: '0px 0px 4px 0px',
                        },
                        fontSize: '12px',
                        boxShadow: '0px 0px 4px 0px',
                        borderRadius: '4px 4px 0px 0px',
                    },
                    tabpanel: {
                        boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.25)',
                    },
                },
            },
            defaultProps: {
                variant: 'unstyled',
            },
        },
        Steps: CustomSteps,
    },
});
export default theme;
