import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Input, InputGroup, InputLeftElement, Select, Text, } from '@chakra-ui/react';
import { useController } from 'react-hook-form';
import { CurrencyInput } from '../../../components/forms/CurrencyInput';
import { appColors } from '../../../config/constants';
import { getCurrencyValue } from '../../../config/CPHelperFunctions';
const CONTRIBUTION_YEAR_KEY = 'contributionYear';
const CONTRIBUTION_KEY = 'contribution';
const REVENUE_BONDED_KEY = 'revenueBonded';
const REVENUE_BONDED_PERCENT_YEAR_KEY = 'revenueBondedPercentYear';
const REVENUE_BONDED_PERCENT_KEY = 'revenueBondedPercent';
export const FoundContribution = ({ register, control, taxImpact, percentualValidation, setPercentualValidation, }) => {
    const contributionYearRegister = register(CONTRIBUTION_YEAR_KEY);
    const revenueBondedPercentYearRegister = register(REVENUE_BONDED_PERCENT_YEAR_KEY);
    const revenueBondedRegister = register(REVENUE_BONDED_KEY);
    const validatePercentage = (event) => {
        const value = event.target.value;
        if (value >= 0 && value <= 100) {
            setPercentualValidation(false);
        }
        else {
            setPercentualValidation(true);
        }
    };
    const revenueBondedPercentRegister = register(REVENUE_BONDED_PERCENT_KEY, {
        onChange: validatePercentage,
    });
    const contributionController = useController({
        name: CONTRIBUTION_KEY,
        control,
    });
    const yearsOptions = [];
    for (let i = 5; i <= 20; i++) {
        yearsOptions.push(_jsxs("option", Object.assign({ value: i }, { children: [i, " years"] }), i));
    }
    const renderContribution = () => {
        if (taxImpact) {
            return (_jsx(_Fragment, { children: (taxImpact.contributionYear || taxImpact.contribution) && (_jsx(_Fragment, { children: _jsxs(Box, Object.assign({ mb: '15px' }, { children: [_jsxs(Box, Object.assign({ textColor: appColors.PRIM_BLUE, fontWeight: '400', fontSize: '14px', pl: '18px' }, { children: ["Annual district capital contribution (for", ' ', taxImpact.contributionYear, " years)"] })), _jsx(Box, Object.assign({ pl: '15px' }, { children: taxImpact.contribution &&
                                    getCurrencyValue(taxImpact.contribution) }))] })) })) }));
        }
        else {
            return (_jsxs(Box, Object.assign({ mb: '15px' }, { children: [_jsx(Box, Object.assign({ textColor: appColors.PRIM_BLUE, fontWeight: '400', fontSize: '14px', pl: '18px' }, { children: "Annual district capital contribution for" })), _jsx(Select, Object.assign({ h: '25px', w: '125px', placeholder: 'Select' }, contributionYearRegister, { children: yearsOptions })), _jsx(CurrencyInput, { label: '', controller: contributionController, placeholder: 'Type here...' })] })));
        }
    };
    const renderRevenueBonded = () => {
        if (taxImpact) {
            return (_jsx(_Fragment, { children: (taxImpact.revenueBonded ||
                    taxImpact.revenueBondedPercentYear ||
                    taxImpact.revenueBondedPercent) && (_jsxs(_Fragment, { children: [_jsxs(Box, Object.assign({ display: 'flex', alignItems: 'center', textColor: appColors.PRIM_BLUE, fontWeight: '400', fontSize: '14px', pl: '18px' }, { children: ["Percent of ", taxImpact.revenueBonded, " revenue bonded (for", ' ', taxImpact.revenueBondedPercentYear, " years)"] })), _jsxs(Box, Object.assign({ pl: '15px' }, { children: [taxImpact.revenueBondedPercent, " %"] }))] })) }));
        }
        else {
            return (_jsxs(_Fragment, { children: [_jsxs(Box, Object.assign({ display: 'flex', alignItems: 'center', textColor: appColors.PRIM_BLUE, fontWeight: '400', fontSize: '14px', pl: '18px' }, { children: ["Percent of", _jsx(Box, Object.assign({ w: '75px', ml: '5px', mr: '5px' }, { children: _jsx(Input, Object.assign({ h: '25px', defaultValue: '', type: 'text', placeholder: '', maxLength: 30 }, revenueBondedRegister)) })), "revenue bonded for", ' '] })), _jsx(Select, Object.assign({ h: '25px', w: '125px', placeholder: 'Select' }, revenueBondedPercentYearRegister, { children: yearsOptions })), _jsxs(InputGroup, Object.assign({ mt: '5px' }, { children: [_jsx(InputLeftElement, Object.assign({ pointerEvents: 'none' }, { children: "%" })), _jsx(Input, Object.assign({ type: 'number', min: 0, max: 100, placeholder: 'Type here...', focusBorderColor: percentualValidation ? 'red' : undefined }, revenueBondedPercentRegister))] })), percentualValidation && (_jsx(Text, Object.assign({ color: 'red', fontSize: '13px' }, { children: "Value can only be between 0 and 100." })))] }));
        }
    };
    return (_jsxs(Box, { children: [renderContribution(), renderRevenueBonded()] }));
};
