import { convertAssetSummaryResponseToIAssetSummary } from '../Assets/AssetConversions';
import { ISpaceCategoryEnum } from './SpaceInterfaces';
export const convertSpaceSummaryResponseToISpaceSummary = (space) => {
    const isValidEnum = Object.values(ISpaceCategoryEnum).includes(space.spaceCategory);
    return {
        name: space.spaceName,
        identity: space.spaceId,
        location: space.points.map((point) => ({
            x: point.xPosition,
            y: point.yPosition,
            pointIndex: point.sequence,
        })),
        isAssessed: space.isAssessed,
        category: isValidEnum
            ? space.spaceCategory
            : undefined,
        assets: space.assets
            .map((asset) => convertAssetSummaryResponseToIAssetSummary(asset))
            .filter((asset) => asset !== undefined),
        floorplanIdentity: space.floorPlanId,
        floorplanName: space.floorPlanName,
        constructionYear: space.constructionYear
            ? space.constructionYear
            : undefined,
    };
};
