var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import services from '..';
export function getProjects(oid) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield services.projectService.getProjects(oid);
    });
}
export function getProject(prid) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield services.projectService.getProject(prid);
    });
}
export function createProject(project) {
    return __awaiter(this, void 0, void 0, function* () {
        return services.projectService.createProject(project);
    });
}
export function getCreateProjectData(oid) {
    return __awaiter(this, void 0, void 0, function* () {
        return services.projectService.getCreateProjectData(oid);
    });
}
export function updateProject(prid, project) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield services.projectService.updateProject(prid, project);
    });
}
export function addProjectFiles(prIdentity, files) {
    return __awaiter(this, void 0, void 0, function* () {
        return services.projectService.addProjectFiles(prIdentity, files);
    });
}
export function deleteProject(prid) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield services.projectService.deleteProject(prid);
    });
}
export function getProjectPhotos(pid, amount) {
    return __awaiter(this, void 0, void 0, function* () {
        return services.projectService.getProjectPhotos(pid, amount);
    });
}
