import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { TriangleDownIcon, TriangleUpIcon } from '@chakra-ui/icons';
import { Flex, HStack, Text, Th, Thead, Tr } from '@chakra-ui/react';
import { flexRender } from '@tanstack/react-table';
import React from 'react';
export function TableHeaders({ table, hasSeeMoreColumn, }) {
    return (_jsx(Thead, Object.assign({ position: 'sticky', top: '0px', background: 'white', zIndex: '2' }, { children: table.getHeaderGroups().map((headerGroup) => (_jsxs(Tr, { children: [headerGroup.headers.map((header) => {
                    return (_jsx(Th, Object.assign({ color: 'secondaryDarkGray.800', width: 'header.getSize() && `${header.getSize()}px`', _hover: {
                            cursor: header.column.getCanSort()
                                ? 'pointer'
                                : 'default',
                        }, onClick: header.column.getToggleSortingHandler() }, { children: _jsxs(HStack, { children: [_jsx(Text, Object.assign({ whiteSpace: 'normal' }, { children: header.isPlaceholder ? (_jsx(_Fragment, {})) : (flexRender(header.column.columnDef.header, header.getContext())) })), _jsx(Flex, { children: header.column.getCanSort() ? (header.column.getIsSorted() ===
                                        'desc' ? (_jsx(TriangleDownIcon, { "aria-label": 'sorted descending' })) : header.column.getIsSorted() ===
                                        'asc' ? (_jsx(TriangleUpIcon, { "aria-label": 'sorted ascending' })) : (_jsx(TriangleDownIcon, { "aria-label": 'sorted descending', color: '#C0C2C1' }))) : (_jsx(_Fragment, {})) })] }) }), header.id));
                }), hasSeeMoreColumn && _jsx(Th, {})] }, headerGroup.id))) })));
}
