import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { FiltersSorters } from '../../config/constants';
import { variants } from '../../config/theme';
import SelectInputTable from '../scenarios/scenarioPage/SelectInputTable';
const ProjectFilteringModalTable = ({ table, columnFilters, isOpen, onClose, facilities, projectTypes, }) => {
    var _a, _b, _c, _d, _e, _f;
    const [inputValue, setInputValue] = useState({});
    useEffect(() => {
        if (columnFilters.length === 0) {
            setInputValue({});
        }
    }, [columnFilters]);
    return (_jsx(Modal, Object.assign({ isOpen: isOpen, onClose: onClose }, { children: _jsxs(ModalContent, Object.assign({ borderRadius: '15px', boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.25)' }, { children: [_jsx(ModalHeader, { children: "Filter" }), _jsx(ModalCloseButton, {}), _jsxs(ModalBody, { children: [_jsx(SelectInputTable, { type: 'text', label: FiltersSorters.FACILITY_NAME, inputValue: inputValue, options: facilities.map((facility) => ({
                                value: facility.identity,
                                title: facility.name,
                            })), value: (_a = inputValue[FiltersSorters.FACILITY_NAME]) !== null && _a !== void 0 ? _a : '', onChange: (value) => {
                                setInputValue((prevFilterValues) => (Object.assign(Object.assign({}, prevFilterValues), { [FiltersSorters.FACILITY_NAME]: Number(value) })));
                            } }), _jsxs(Flex, Object.assign({ alignItems: 'flex-end', justifyContent: 'space-between' }, { children: [_jsx(SelectInputTable, { type: 'number', label: FiltersSorters.ESTIMATE_BUDGET, value: (_c = (_b = inputValue[FiltersSorters.ESTIMATE_BUDGET]) === null || _b === void 0 ? void 0 : _b[0]) !== null && _c !== void 0 ? _c : '', onChange: (value) => {
                                        setInputValue((prevFilterValues) => {
                                            var _a;
                                            return (Object.assign(Object.assign({}, prevFilterValues), { [FiltersSorters.ESTIMATE_BUDGET]: [
                                                    Number(value),
                                                    (_a = prevFilterValues[FiltersSorters.ESTIMATE_BUDGET]) === null || _a === void 0 ? void 0 : _a[1],
                                                ] }));
                                        });
                                    }, placeholder: 'Min' }), _jsx(SelectInputTable, { type: 'number', value: (_e = (_d = inputValue[FiltersSorters.ESTIMATE_BUDGET]) === null || _d === void 0 ? void 0 : _d[1]) !== null && _e !== void 0 ? _e : '', onChange: (value) => {
                                        setInputValue((prevFilterValues) => {
                                            var _a;
                                            return (Object.assign(Object.assign({}, prevFilterValues), { [FiltersSorters.ESTIMATE_BUDGET]: [
                                                    (_a = prevFilterValues[FiltersSorters.ESTIMATE_BUDGET]) === null || _a === void 0 ? void 0 : _a[0],
                                                    Number(value),
                                                ] }));
                                        });
                                    }, placeholder: 'Max' })] })), _jsx(SelectInputTable, { type: 'text', label: FiltersSorters.PROJECT_TYPE, inputValue: inputValue, options: projectTypes.map((projectType) => ({
                                value: projectType,
                                title: projectType,
                            })), value: (_f = inputValue[FiltersSorters.PROJECT_TYPE]) !== null && _f !== void 0 ? _f : '', onChange: (value) => {
                                setInputValue((prevFilterValues) => (Object.assign(Object.assign({}, prevFilterValues), { [FiltersSorters.PROJECT_TYPE]: Number(value) })));
                            } })] }), _jsxs(ModalFooter, { children: [_jsx(Button, Object.assign({ variant: variants.grayBtn, fontSize: '14px', fontWeight: '700', mr: 3, onClick: () => {
                                Object.entries(inputValue).forEach((array) => {
                                    table.getAllColumns().forEach((column) => {
                                        if (inputValue !== undefined) {
                                            if (column.id === array[0]) {
                                                column.setFilterValue(array[1]);
                                            }
                                        }
                                    });
                                });
                                onClose();
                            } }, { children: "Apply filter" })), _jsx(Button, Object.assign({ colorScheme: 'red', variant: 'outline', onClick: onClose }, { children: "Cancel" }))] })] })) })));
};
export default ProjectFilteringModalTable;
